import React, { useReducer, useRef, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { maintainerForm as styles } from './styles';
import { validateEmailCharacters } from '../../libraries/utils';
import { getValidations } from '../../libraries/validators';
import Service from '../../services/user-service';
import { REQUIRED_FIELD, MAX_STRING_LENGTH } from '../../libraries/texts';

const formReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};
const initialState = {
  username: '',
  password: ''
};
const ERROR_LOGIN = {
  'Login Error': 'Email y/o Contraseña incorrectos'
};

const Login = props => {
  const { classes } = props;
  const formRef = useRef(null);
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { username, password } = state;

  const validatorListener = async () => {
    const result = await formRef.current.isFormValid();
    setDisabled(result);
  };

  useEffect(() => {
    getValidations();
  }, []);

  const redirectListPage = () => '/';

  return (
    <div>
      <div className={classes.imgLogin}>
        <img src="/img/icons/genera.png" className={classes.img} alt="" />
      </div>
      <Card className={classes.loginCard}>
        <CardContent className={classNames(classes.cardContent)}>
          <Typography variant="h4" className={classes.titleLogin} component="h3">
            Iniciar Sesión
          </Typography>
          <ValidatorForm
            novalidate
            className={classes.formVerify}
            ref={formRef}
            instantValidate
            onSubmit={async () => {
              try {
                await Service.login(state);
                return window.location.assign(redirectListPage());
              } catch (error) {
                setErrorMessage(ERROR_LOGIN[error.message]);
              }
            }}
          >
            <TextValidator
              id="username"
              name="username"
              required
              placeholder="ejemplo@dominio.com"
              value={username}
              label="Usuario"
              validatorListener={validatorListener}
              inputProps={{ maxLength: 100 }}
              className={classes.textField}
              autoComplete="off"
              validators={['requiredValue', 'maxStringLength:100', 'isValidEmail']}
              errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, 'Usuario con formato inválido']}
              margin="normal"
              onChange={e => {
                dispatch({ [e.target.name]: validateEmailCharacters(e.target.value) });
              }}
            />
            <TextValidator
              id="password"
              required
              name="password"
              type="password"
              label="Contraseña"
              inputProps={{ maxLength: 20 }}
              className={classes.textField}
              validatorListener={validatorListener}
              value={password}
              validators={['requiredValue', 'maxStringLength:20', 'minStringLength:8']}
              errorMessages={[
                REQUIRED_FIELD,
                MAX_STRING_LENGTH,
                'Contraseña mínimo debe contener 8 caracteres'
              ]}
              margin="normal"
              onChange={e => {
                dispatch({
                  [e.target.name]: e.target.value
                });
              }}
            />
            <div className={classes.errorMessage}>{errorMessage}</div>
            <div className={classes.footerLoginButton}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
                disabled={!disabled}
              >
                {'Ingresar'}
              </Button>
            </div>
          </ValidatorForm>
        </CardContent>
      </Card>
    </div>
  );
};

export default withStyles(styles)(Login);
