import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import List from './list';
import { generalClasses as styles } from './styles';

const Users = props => {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title="Usuarios" />
      <CardContent className={classes.cardContent}>
        <List />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(Users);
