export const FORM_WIDTH = '100%';

export const generalClasses = theme => ({
  exportButton: {
    margin: 0,
    width: 0,
    color: '#ffffff',
    background: '#458B00',
    '&:hover': {
      backgroundColor: '#9CBA7F',
      borderColor: '#9CBA7F'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#9CBA7F',
      borderColor: '#9CBA7F'
    }
  },
  formControl: {
    margin: 0,
    border: 0,
    display: 'inline-flex',
    padding: 0,
    'align-items': 'center',
    position: 'relative',
    'min-width': 0,

    'flex-direction': 'column',
    'vertical-align': 0,
    '& p': {
      textAlign: 'center'
    }
  }
});
