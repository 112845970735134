import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  async componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? (
      <h1>Ha pasado una incidencia, por favor, comunicarse con soporte@genera.cl</h1>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
