import React, { useReducer, useState, useEffect, useRef } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getValidations } from '../../libraries/validators';
import { useToggleNavBar } from '../../libraries/contexts';
import InputMaskValidatorElement from '../../components/fields/inputWithMask';
import {
  getFormatNumber,
  validateFloorOffice,
  validateAddress,
  validateNameBranchOffice,
  validateEmailCharacters,
  save
} from '../../libraries/utils';
import {
  MAX_STRING_LENGTH,
  MIN_STRING_LENGTH_PHONE,
  REQUIRED_FIELD,
  BACK,
  EMAIL_ERROR,
  UPDATED,
  ERROR_BRANCHES_OFFICES,
  NO_OPTION_LABELS,
  FLOOR_OFFICE,
  ADMINISTRATOR_MAIL,
  SAVE,
  MAX_OPTIONS_SELECTED,
  PHONE_NUMBER,
  NAME,
  MASK_PHONE_NUMBER,
  COMPANIES,
  ADDRESS,
  CREATED
} from '../../libraries/texts';
import SelectValidatorElement from '../../components/fields/selectSearch';
import CompaniesService from '../../services/companies';
import BranchOfficeService from '../../services/branches';
import { generalClasses as styles } from './styles';

const maxCompanies = 10;

const formReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};
const initialState = {
  name: '',
  phone: '',
  address: '',
  email: '',
  office: '',
  companies: []
};

const Form = props => {
  const { classes, branchOffice, history } = props;
  const formBranchOffice = useRef(null);
  const onlineStatus = useOnlineStatus();
  const [state, dispatch] = useReducer(formReducer, branchOffice || initialState);
  const [companiesList, setCompanies] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [, changeToggleNavBar] = useToggleNavBar();
  const { name, address, office, companies, phone, email } = state;

  const getRequests = async () => {
    const companiesFullList = await CompaniesService.getAll();

    const getCompanies = companiesFullList.map(item => {
      const { name: companyName, _id: id } = item;
      const jsonOffice = {
        value: companyName,
        label: companyName,
        id
      };
      return jsonOffice;
    });

    setCompanies(getCompanies);
  };

  const submitForm = async () => {
    try {
      const { _id: hasId } = state;

      const result = await save(hasId, state, BranchOfficeService);

      if (result) {
        changeToggleNavBar({
          message: { message: `Sucursal ${hasId ? UPDATED : CREATED}`, open: true }
        });
        return history.push('/branch-offices/list');
      }
      return changeToggleNavBar({ message: { message: 'Not found', open: true } });
    } catch (err) {
      return changeToggleNavBar({
        message: { message: ERROR_BRANCHES_OFFICES[err.message], open: true }
      });
    }
  };

  useEffect(() => {
    getValidations();
    getRequests();
  }, []);

  const validatorListener = async result => {
    const result1 = await formBranchOffice.current.isFormValid();
    setDisabled(result1);
  };

  return (
    <ValidatorForm className={classes.formVerify} ref={formBranchOffice} onSubmit={submitForm}>
      <div className={classes.backForm}>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          size="small"
          className={classes.buttonSearch}
          to="/branch-offices/list"
          disabled={!onlineStatus}
        >
          <ArrowBack className={classes.rightIcon} />
          {BACK}
        </Button>
      </div>

      <TextValidator
        id="name"
        required
        disabled={!!branchOffice}
        name="name"
        label={NAME}
        inputProps={{ maxLength: 100 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={name}
        validators={['requiredValue', 'maxStringLength:100']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateNameBranchOffice(e.target.value) });
        }}
      />
      <TextValidator
        id="address"
        name="address"
        required
        label={ADDRESS}
        inputProps={{ maxLength: 100 }}
        className={classes.textField}
        autoComplete="off"
        value={address}
        validatorListener={validatorListener}
        validators={['requiredValue', 'maxStringLength:100']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({
            [e.target.name]: validateAddress(e.target.value)
          });
        }}
      />
      <InputMaskValidatorElement
        id="phone"
        label={PHONE_NUMBER}
        format={MASK_PHONE_NUMBER}
        name="phone"
        required
        className={classes.textField}
        errorClassName={classes.errorValidation}
        autoComplete="off"
        validatorListener={validatorListener}
        containerClassName={classes.inputDiv}
        validators={['requiredValue', 'maxStringLength:10', 'minStringLength:9']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, MIN_STRING_LENGTH_PHONE]}
        value={phone}
        onChange={e => {
          dispatch({
            phone: getFormatNumber(e.value, { maxLength: 10 })
          });
        }}
      />
      <TextValidator
        id="email"
        name="email"
        required
        value={email}
        label={ADMINISTRATOR_MAIL}
        validatorListener={validatorListener}
        inputProps={{ maxLength: 30 }}
        className={classes.textField}
        autoComplete="off"
        validators={['requiredValue', 'maxStringLength:100', 'isEmail']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, EMAIL_ERROR]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateEmailCharacters(e.target.value) });
        }}
      />
      <TextValidator
        id="office"
        name="office"
        required
        value={office}
        label={FLOOR_OFFICE}
        validatorListener={validatorListener}
        inputProps={{ maxLength: 20 }}
        className={classes.textField}
        autoComplete="off"
        validators={['requiredValue', 'maxStringLength:20']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateFloorOffice(e.target.value) });
        }}
      />
      <SelectValidatorElement
        id="companies"
        name="companies"
        required
        validatorListener={validatorListener}
        value={companies}
        placeholder={COMPANIES}
        isMulti
        options={companies.length === maxCompanies ? [] : companiesList}
        noOptionsMessage={() =>
          companies.length === maxCompanies ? MAX_OPTIONS_SELECTED : NO_OPTION_LABELS
        }
        onChange={e => {
          dispatch({ companies: e });
        }}
      />
      <div className={classes.footerSaveButton}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={!onlineStatus || !disabled}
        >
          {SAVE}
        </Button>
      </div>
    </ValidatorForm>
  );
};

export default withStyles(styles)(Form);
