import React, { useState } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, withTheme } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import {
  ChevronLeft,
  ChevronRight,
  Menu,
  AccountCircle,
  PowerSettingsNewOutlined
} from '@material-ui/icons';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { isLogged } from '../../services/authorization-service';

import { useToggleNavBar } from '../../libraries/contexts';
import UserService from '../../services/user-service';
import { navBarButtons, isActiveLink, SecondaryNavigationButtons } from '../../routers/navigation';
import { sidebar as styles } from './css';

const SideBar = props => {
  const { classes, theme } = props;
  const [{ main, secondary, message }, setToggleValue] = useToggleNavBar();
  const [secondaryMenu, setSecondaryMenu] = useState([]);
  const [anchorUserOption, setAnchorUserOption] = useState(null);
  const detectMobile = useMobileDetect();

  const showChevron = theme => {
    return theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />;
  };

  const openSecondaryMenu = key => () => {
    setSecondaryMenu(SecondaryNavigationButtons[key]);

    if (!detectMobile.isDesktop()) {
      return setToggleValue({ main: false, secondary: !secondary });
    }
    return setToggleValue({ main, secondary: !secondary });
  };

  const user = UserService.getCurrentUser();

  const userName = `${user.name} ${user.surname}  `;

  const closeSession = async () => {
    await UserService.logout();
    return window.location.assign('/login');
  };

  const openOptionPrimary = () => () => {
    if (!detectMobile.isDesktop()) {
      return setToggleValue({ main: false, secondary: false });
    }
    return setToggleValue({ main, secondary: false });
  };
  const clickSecondaryOption = () => () => {
    if (!detectMobile.isDesktop()) {
      return setToggleValue({ main: false, secondary: false });
    }
    return setToggleValue({ main, secondary: true });
  };
  if (!isLogged()) {
    return <React.Fragment />;
  }
  const userOptions = () => {
    return (
      <Popover
        id="user-popover"
        className={classes.UserPopover}
        classes={{
          paper: classes.paper
        }}
        open={Boolean(anchorUserOption)}
        anchorEl={anchorUserOption}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        onClose={() => {
          setAnchorUserOption(null);
        }}
        disableRestoreFocus
      >
        <List>
          <ListItem>
            <ListItemText className={classes.UserName} primary={userName.toUpperCase()} />
          </ListItem>
          <Divider />
          <ListItem button divider onClick={closeSession}>
            <ListItemIcon>
              <PowerSettingsNewOutlined />
            </ListItemIcon>
            <ListItemText primary="Salir" />
          </ListItem>
        </List>
      </Popover>
    );
  };

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, classes.drawerSidebar, {
          [classes.drawerOpen]: main,
          [classes.drawerClose]: !main
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: main,
            [classes.drawerClose]: !main
          })
        }}
        open={main}
      >
        <div className={classNames(classes.toolbar)}>
          <IconButton onClick={() => setToggleValue({ main: !main, secondary, message })}>
            {main ? showChevron(theme) : <Menu />}
          </IconButton>
        </div>
        <Divider />

        <List className={classNames(classes.sideBarNavList)}>
          {navBarButtons.map(({ text, to, key, icon, childrens, permissions, disabled }, index) => {
            return (
              <React.Fragment key={key}>
                {childrens ? (
                  <Tooltip title={main ? '' : text} placement="right">
                    <ListItem
                      button
                      component={NavLink}
                      key={index.toString()}
                      disabled={disabled}
                      onClick={openSecondaryMenu(key)}
                      isActive={isActiveLink(childrens)}
                      activeClassName={classes.activeLink}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItem>
                  </Tooltip>
                ) : (
                  <Tooltip title={main ? '' : text} placement="right">
                    <ListItem
                      button
                      component={NavLink}
                      key={index.toString()}
                      disabled={disabled}
                      to={to}
                      onClick={openOptionPrimary()}
                      activeClassName={classes.activeLink}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItem>
                  </Tooltip>
                )}
              </React.Fragment>
            );
          })}

          <ListItem
            className={classes.userOption}
            activeClassName=""
            button
            component={NavLink}
            key="person"
            onClick={event => setAnchorUserOption(event.currentTarget)}
          >
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary={userName.toUpperCase()} />
          </ListItem>
          {userOptions()}
        </List>
      </Drawer>

      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, classes.secondaryNavbar, {
          [classes.drawerOpen]: secondary,
          [classes.drawerOpenSecondary]: secondary,
          [classes.drawerCloseSecondary]: !secondary,
          [classes.enterBothDrawer]: main,
          [classes.leavingBothDrawer]: !main
        })}
        classes={{
          paper: classNames(classes.secondaryNavbar, {
            [classes.drawerOpen]: secondary,
            [classes.drawerOpenSecondary]: secondary,
            [classes.drawerCloseSecondary]: !secondary,
            [classes.enterBothDrawer]: main,
            [classes.leavingBothDrawer]: !main
          })
        }}
        open={secondary}
      >
        <List className={classes.listSecondary}>
          {secondaryMenu.map(({ text, to, icon, childrens, permissions, disabled }, index) => (
            <Tooltip title={text} placement="right">
              <ListItem
                button
                component={NavLink}
                key={index.toString()}
                to={to}
                disabled={disabled}
                onClick={clickSecondaryOption()}
                isActive={isActiveLink(childrens)}
                activeClassName={classes.activeLink}
              >
                <ListItemIcon>{icon}</ListItemIcon>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
};

SideBar.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withTheme()(withStyles(styles)(SideBar));
