import React, { useReducer, useContext } from 'react';

const ToggleNavBarContext = React.createContext();

const initialToggleNavBar = {
  main: false,
  secondary: false,
  message: {
    undo: false,
    open: false
  }
};

const reducerToggleNavBar = (state, action) => {
  return action;
};

export const ToggleNavBarProvider = ({ children }) => {
  const contextValue = useReducer(reducerToggleNavBar, initialToggleNavBar);
  return (
    <ToggleNavBarContext.Provider value={contextValue}>{children}</ToggleNavBarContext.Provider>
  );
};

export const useToggleNavBar = () => {
  const contextValue = useContext(ToggleNavBarContext);
  return contextValue;
};

const LoadingBarContext = React.createContext();

const initialLoadingBar = {
  principalLoading: false
};

const reducerLoadingBar = (state, action) => {
  return action;
};

export const LoadingBarProvider = ({ children }) => {
  const contextValue = useReducer(reducerLoadingBar, initialLoadingBar);
  return <LoadingBarContext.Provider value={contextValue}>{children}</LoadingBarContext.Provider>;
};

export const useLoadingBar = () => {
  const contextValue = useContext(LoadingBarContext);
  return contextValue;
};


const messageContext = React.createContext();

const initialMessageContext = {};

const reducerMessageContext = (state, action) => {
  return action;
};

export const MessageContextProvider = ({ children }) => {
  const contextValue = useReducer(reducerMessageContext, initialMessageContext);
  return <messageContext.Provider value={contextValue}>{children}</messageContext.Provider>;
};

export const useMessageContext = () => {
  const contextValue = useContext(messageContext);
  return contextValue;
};
