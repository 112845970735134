import React, { useEffect, useState, useReducer } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { Typography, LinearProgress } from '@material-ui/core';
import useOnlineStatus from '@rehooks/online-status';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import VirtualizedTable from '../../components/table';
import ImportButton from '../../components/import';
import BlackListService from '../../services/blackList';
import { cleanDots, getFormatRut } from '../../libraries/utils';
import { validateData, validateFile, readFile, VALID_EXTENSIONS } from './csv-utils';
import ImportErrors from './import-errors';
import { useToggleNavBar } from '../../libraries/contexts';

import {
  TABLE_HEAD_NAME,
  TABLE_HEAD_ID,
  SEARCH,
  NO_DATA,
  REPORTED_BY,
  LOADING,
  REASON,
  ORIGIN,
  DUPLICATED_BLACK_LIST_USER_ON_BD,
  SUCCESSFUL_LOAD
} from '../../libraries/texts';

import { generalClasses as styles } from './styles';

const columns = [
  {
    minWidth: 150,
    label: TABLE_HEAD_ID,
    dataKey: 'numberId',
    cellDataGetter: ({ columnData, dataKey, rowData }) => rowData[dataKey].toUpperCase()
  },
  {
    minWidth: 150,
    label: TABLE_HEAD_NAME,
    dataKey: 'fullName'
  },
  {
    minWidth: 200,
    label: ORIGIN,
    dataKey: 'origin'
  },
  {
    minWidth: 250,
    label: REASON,
    dataKey: 'reason',
    hasTooltip: true,
    maxSize: 70,
    rowWidth: 250
  },
  {
    minWidth: 150,
    label: REPORTED_BY,
    cellDataGetter: ({ columnData, dataKey, rowData }) => {
      const { user } = rowData;
      return user
        ? [user.name, user.surname]
            .filter(Boolean)
            .join(' ')
            .toUpperCase()
        : '';
    }
  }
];
const columnsMobile = [
  {
    minWidth: 100,
    label: TABLE_HEAD_ID,
    dataKey: 'numberId',
    cellDataGetter: ({ columnData, dataKey, rowData }) => rowData[dataKey].toUpperCase()
  },
  {
    minWidth: 180,
    label: TABLE_HEAD_NAME,
    dataKey: 'fullName'
  },
  {
    minWidth: 120,
    label: ORIGIN,
    dataKey: 'origin'
  }
];

const setRowsReducer = (prevRows, { newRows = [], type }) => {
  switch (type) {
    case 'add':
      return [...prevRows, ...newRows];
    case 'set':
      return newRows;
    default:
      return prevRows;
  }
};

const SearchBlackList = props => {
  const fileUploadInput = React.createRef();
  const detectMobile = useMobileDetect();
  const { classes, formControlClass = classes.middleWidth, history } = props;
  const [, changeToggleNavBar] = useToggleNavBar();
  const onlineStatus = useOnlineStatus();
  const [rows, setRows] = useReducer(setRowsReducer, []);
  const [pageStatus, setPageStatus] = useState({ currentPage: 1, hasNext: true, total: 0 });
  const [searchText, setSearchText] = useState({ curr: '', last: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [fileError, setFileError] = useState([]);
  const [fileDataError, setFileDataError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState([]);

  const getRequests = async (filterData = '', page = 1) => {
    const type = page <= 1 ? 'set' : 'add';
    const visitsList = await BlackListService.getPaginated({
      filterData,
      page,
      order: 'names'
    });

    const { results, currentPage, hasNext, count: total } = visitsList;
    const newRows = results.map(item => {
      const numberId = item.typeId === 'RUT' ? getFormatRut(item.numberId) : item.numberId;
      return { ...item, numberId, data: item };
    });
    setRows({ newRows, type });
    setPageStatus({ currentPage, hasNext, total });
    setIsLoading(false);
  };

  useEffect(() => {
    getRequests();
  }, [importFile]);

  const handleSearch = e => {
    const { curr, last } = searchText;
    if (curr !== last) {
      setIsLoading(true);
      setSearchText({ ...searchText, last: curr });
      getRequests(curr);
    }
  };

  const handleChange = e => {
    let text = e.target.value;
    text = cleanDots(text);
    setSearchText({ ...searchText, curr: text });
  };

  const isRowLoaded = hasItems => {
    if (!hasItems && pageStatus.hasNext) {
      setPageStatus({ ...pageStatus, hasNext: false });
      return false;
    }
    return true;
  };

  const loadMoreRows = e => {
    const pageToRequest = pageStatus.currentPage + 1;
    getRequests(searchText.curr, pageToRequest);
  };

  const handleOnClickRow = e => {
    const { rowData } = e;
    const { data } = rowData;
    return history.push(`/blackList/detail/${data.numberId}/${data.typeId}`);
  };

  const renderTable = () => {
    return (
      <Paper className={classes.tablePaper}>
        <VirtualizedTable
          rowCount={rows.length}
          isRowLoaded={({ index }) => isRowLoaded(!!rows[index])}
          rowGetter={({ index }) => rows[index]}
          loadMoreRows={e => loadMoreRows(e)}
          remoteRowCount={pageStatus.total}
          columns={!detectMobile.isDesktop() ? columnsMobile : columns}
          onRowClick={handleOnClickRow}
        />
      </Paper>
    );
  };

  const renderMessage = () => {
    return (
      <Typography variant="h5" component="h3" className={classes.tableNoData}>
        {isLoading ? LOADING : NO_DATA}
      </Typography>
    );
  };

  function handleUploadButton() {
    fileUploadInput.current.value = '';
    setFileError([]);
    setFileDataError([]);
    fileUploadInput.current.click();
  }

  async function handleFileUpload(file) {
    try {
      setLoading(true);
      if (validateFile(file, setFileError)) {
        const data = await readFile(file);
        data.shift();
        const errorROWS = data.map((val, i) => validateData(data, i)).flat();
        setFileDataError(errorROWS);

        if (errorROWS.length === 0) {
          const result = await BlackListService.bulk({ data, validateField: 'numberId' });
          setImportFile(result);
          changeToggleNavBar({ message: { message: SUCCESSFUL_LOAD, open: true } });
        }
      }

      setLoading(false);
    } catch (error) {
      const { errors } = error;
      const dupKeys = errors.length ? errors.flat() : [];
      const dupKeysErrors = dupKeys.map(val => ({
        row: '-',
        message: DUPLICATED_BLACK_LIST_USER_ON_BD(val.numberId)
      }));
      setLoading(false);
      setFileError([]);
      setFileDataError(dupKeysErrors);
    }
  }

  return fileDataError.length > 0 ? (
    <ImportErrors rows={fileDataError} handleAccept={setFileDataError} />
  ) : (
    <Card className={classes.formCard}>
      {loading ? <LinearProgress /> : null}
      <CardContent className={classes.cardContent}>
        {
          <React.Fragment>
            <div
              className={classNames(
                formControlClass,
                classes.tableMiddleWidth,
                classes.serialControl
              )}
            >
              <ValidatorForm
                className={classes.tableFormSearch}
                onSubmit={async e => {
                  handleSearch(e);
                }}
              >
                <TextValidator
                  id="names"
                  name="names"
                  value={searchText.curr}
                  label={SEARCH}
                  className={classes.textField}
                  margin="normal"
                  type="search"
                  disabled={!onlineStatus}
                  onChange={e => handleChange(e)}
                  autoComplete="off"
                />
                <div className={classes.secondFunctionalities}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonSearch}
                    disabled={!onlineStatus}
                    type="submit"
                  >
                    {SEARCH}
                  </Button>
                </div>
                <div className={classes.secondFunctionalities}>
                  <ImportButton
                    errorMessage={fileError}
                    onClick={handleUploadButton}
                    disabled={!onlineStatus}
                  />
                </div>
              </ValidatorForm>
            </div>
            <div className={classes.tableContainer}>
              {rows.length ? renderTable() : renderMessage()}
            </div>
            <input
              ref={fileUploadInput}
              type="file"
              style={{ display: 'none' }}
              id="fileUpload"
              disabled={!onlineStatus}
              onChange={async v => v.target.files.length && handleFileUpload(v.target.files[0])}
              accept={VALID_EXTENSIONS.toString()}
            />
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(SearchBlackList);
