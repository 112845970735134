import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BlackListIcon = props => {
  return (
    <SvgIcon viewBox="0 0 512 512" style={{ 'font-size': '15rem' }} {...props}>
      <g>
        <g fillRule="evenodd">
          <path
            d="m481.101562 512h-450.203124c-17.066407 0-30.898438-13.832031-30.898438-30.898438v-392.824218h512v392.824218c0 17.066407-13.832031 30.898438-30.898438 30.898438zm0 0"
            fill="#dd352e"
            data-original="#DD352E"
            className="active-path"
            data-old_color="#dd352e"
            style={{ fill: '#21CBE2' }}
          />
          <path
            d="m512 105.929688h-512v-75.03125c0-17.066407 13.832031-30.898438 30.898438-30.898438h450.203124c17.066407 0 30.898438 13.832031 30.898438 30.898438zm0 0"
            fill="#28384c"
            data-original="#28384C"
            className=""
          />
          <path
            d="m35.308594 35.308594h35.3125v35.3125h-35.3125zm0 0"
            fill="#dd352e"
            data-original="#DD352E"
            className="active-path"
            data-old_color="#dd352e"
            style={{ fill: '#21CBE2' }}
          />
          <path
            d="m105.929688 35.308594h35.3125v35.3125h-35.3125zm0 0"
            fill="#ebba16"
            data-original="#EBBA16"
            className=""
            style={{ fill: '#166EEB' }}
            data-old_color="#ebba16"
          />
          <path
            d="m176.550781 35.308594h35.3125v35.3125h-35.3125zm0 0"
            fill="#23a24d"
            data-original="#23A24D"
            className=""
          />
        </g>
        <path
          d="m167.722656 326.621094h-114.757812c-3.160156 0-6.082032-1.6875-7.652344-4.433594-1.570312-2.742188-1.5625-6.105469.027344-8.84375l61.792968-105.929688c2.453126-4.203124 7.863282-5.625 12.074219-3.179687 4.210938 2.453125 5.632813 7.867187 3.179688 12.078125l-54.050781 92.652344h99.386718c4.875 0 8.828125 3.957031 8.828125 8.828125 0 4.871093-3.953125 8.828125-8.828125 8.828125zm0 0"
          fill="#fff"
          data-original="#FFF"
          className=""
          data-old_color="#fff"
          style={{ fill: '#FFFFFF' }}
        />
        <path
          d="m123.585938 379.585938c-4.871094 0-8.828126-3.953126-8.828126-8.828126v-105.929687c0-4.875 3.957032-8.828125 8.828126-8.828125 4.875 0 8.828124 3.953125 8.828124 8.828125v105.929687c0 4.875-3.953124 8.828126-8.828124 8.828126zm0 0"
          fill="#fff"
          data-original="#FFF"
          className=""
          data-old_color="#fff"
          style={{ fill: '#FFFFFF' }}
        />
        <path
          d="m450.207031 326.621094h-114.757812c-3.160157 0-6.082031-1.6875-7.65625-4.433594-1.570313-2.742188-1.5625-6.105469.027343-8.84375l61.792969-105.929688c2.457031-4.203124 7.867188-5.625 12.078125-3.179687 4.210938 2.453125 5.632813 7.867187 3.175782 12.078125l-54.050782 92.652344h99.390625c4.871094 0 8.828125 3.957031 8.828125 8.828125 0 4.871093-3.957031 8.828125-8.828125 8.828125zm0 0"
          fill="#fff"
          data-original="#FFF"
          className=""
          data-old_color="#fff"
          style={{ fill: '#FFFFFF' }}
        />
        <path
          d="m406.070312 379.585938c-4.875 0-8.828124-3.953126-8.828124-8.828126v-105.929687c0-4.875 3.953124-8.828125 8.828124-8.828125 4.871094 0 8.828126 3.953125 8.828126 8.828125v105.929687c0 4.875-3.957032 8.828126-8.828126 8.828126zm0 0"
          fill="#fff"
          data-original="#FFF"
          className=""
          data-old_color="#fff"
          style={{ fill: '#FFFFFF' }}
        />
        <path
          d="m251.585938 379.585938c-31.636719 0-57.378907-25.742188-57.378907-57.378907v-61.792969c0-31.636718 25.742188-57.378906 57.378907-57.378906 31.636718 0 57.378906 25.742188 57.378906 57.378906v61.792969c0 31.636719-25.742188 57.378907-57.378906 57.378907zm0-158.894532c-21.902344 0-39.722657 17.820313-39.722657 39.722656v61.792969c0 21.902344 17.820313 39.722657 39.722657 39.722657 21.902343 0 39.722656-17.820313 39.722656-39.722657v-61.792969c0-21.902343-17.820313-39.722656-39.722656-39.722656zm0 0"
          fill="#fff"
          data-original="#FFF"
          className=""
          data-old_color="#fff"
          style={{ fill: '#FFFFFF' }}
        />
      </g>
    </SvgIcon>
  );
};

export default BlackListIcon;
