import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Users = props => {
  return (
    <SvgIcon viewBox="0 0 258.75 258.75" {...props}>
      <g>
        <circle cx="129.375" cy="60" r="60" />
        <path d="M129.375,150c-60.061,0-108.75,48.689-108.75,108.75h217.5C238.125,198.689,189.436,150,129.375,150z" />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
};

export default Users;
