import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Turnstiles = props => {
  return (
    <SvgIcon viewBox="0 0 494.667 494.667" {...props}>
      <path d="m494.667 7.083v480.5h-138.598v-299.597l-128.069-128.069v-52.834z" />
      <path d="m415.598 123.289h30v30h-30z" />
      <path d="m416.069 63.289h30v30h-30z" />
      <path d="m322.736 63.289h63.333v30h-63.333z" />
      <path
        d="m234.117 118.688h50.452v101.199h-50.452z"
        transform="matrix(.707 -.707 .707 .707 -43.745 232.966)"
      />
      <path d="m187.804 240.833h-45.415l-112.119 112.119 42.064 42.064 115.47-115.47z" />
      <path d="m217.804 240.833v209.799h59.488v-185.446l-24.353-24.353z" />
      <path d="m187.804 175.699-24.353-24.353h-163.451v59.487h187.804z" />
    </SvgIcon>
  );
};

export default Turnstiles;
