export const FORM_WIDTH = '100%';
const errorRed = 'red';

export const generalClasses = theme => ({
  positionAbsolute: {
    position: 'absolute'
  },
  middleWidthContainer: {
    width: '50%',
    display: 'inline-flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& > div:nth-child(2)': {
      paddingTop: '0px',
      marginTop: '0px',
      '& > label': {
        paddingLeft: '43px',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '3px'
        }
      }
    }
  },
  greenButton: {
    margin: 0,
    width: 0,
    color: '#ffffff',
    background: '#458B00',
    '&:hover': {
      backgroundColor: '#9CBA7F',
      borderColor: '#9CBA7F'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#9CBA7F',
      borderColor: '#9CBA7F'
    }
  },
  inputDiv: {
    marginBottom: 8,
    marginTop: 16
  },

  flexSpaceAround: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div:nth-child(4)': {
      paddingLeft: '0px'
    },
    [theme.breakpoints.down('md')]: {
      '& > div:nth-child(4)': {
        width: '28%'
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& > div:nth-child(4)': {
        width: '30%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& > div': {
        width: '100%',
        margin: '0px 0px 0px 0px'
      },
      '& > div:nth-child(4)': {
        width: '100%',
        paddingLeft: '10px'
      }
    }
  },
  flex: {
    display: 'flex'
  },
  reason: { width: '100%!important' },
  companion: {
    display: 'flex',
    'margin-top': '-1.5rem'
  },
  companionCloseForm: {
    display: 'flex'
  },
  hasCompanion: {
    marginTop: '1.6rem',
    padding: '0px 0px 0px 10px!important'
  },
  quantityCompanion: {
    width: '5rem',
    marginLeft: '1px',
    marginRight: '1px'
  },
  startDate: {
    marginBottom: '2rem'
  },
  labelTitle: {
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    fontWeight: 'bold'
  },
  alignCenter: {
    'justify-content': 'center'
  },
  alignRigth: {
    'justify-content': 'flex-end'
  },
  alignLeft: {
    'justify-content': 'flex-start'
  },
  formVerify: {
    width: '100%',
    padding: '30px'
  },
  formStyle: {
    width: '100%'
  },
  textField: {
    marginLeft: '1px',
    marginRight: '1rem',
    width: '47%'
  },
  footerSaveButton: {
    display: 'flex',
    marginTop: '2rem',
    justifyContent: 'flex-end'
  },
  serialControl: {
    flex: '1 0',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '& > span': {
      padding: '0px 10px 0px 10px'
    },
    '& label > span': {
      paddingLeft: '2px'
    },
    '& > div:first-child': {
      width: '100%'
    }
  },
  card: {
    alignSelf: 'center',
    width: FORM_WIDTH,
    margin: '50px 0px 15px 0px',
    '& > div:nth-child(2)': {
      paddingBottom: '0px'
    }
  },
  backForm: {
    display: 'flex',
    'justify-content': 'flex-start',
    'margin-bottom': '2rem'
  },
  secondFunctionalities: {
    display: 'flex',
    width: '30%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      marginBottom: '1rem',
      justifyContent: 'center'
    }
  },
  footerButton: {
    display: 'flex',
    'justify-content': 'center',
    'margin-top': '2rem'
  },
  buttonSearch: {
    display: 'flex',
    margin: 0,
    marginRight: 100,
    padding: '0 10px',
    height: '34px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    }
  },
  exportButton: {
    margin: 0,
    color: '#ffffff',
    background: '#458B00',
    '&:hover': {
      backgroundColor: '#9CBA7F',
      borderColor: '#9CBA7F'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#9CBA7F',
      borderColor: '#9CBA7F'
    }
  },
  searchGuest: {
    display: 'flex',
    'flex-wrap': 'wrap'
  },
  buttonSearchGuest: { margin: '2rem' },
  formCard: {
    alignSelf: 'center',
    width: '90%',
    overflow: 'inherit',
    margin: '50px 0px 15px 0px',
    '& > div:nth-child(2)': {
      paddingBottom: '0px'
    }
  },
  cardColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    padding: '0px',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center'
  },
  cardContentForm: {
    padding: '0px',
    display: 'flex',
    'justify-content': 'center'
  },
  cardTitle: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '0px 10px 0px 10px',
    '& div span': {
      color: 'white',
      fontSize: '1.2rem',
      padding: '13px 23px'
    }
  },
  titlePdl0: {
    '& div span': {
      paddingLeft: '0'
    }
  },
  thirdWidth: {
    width: '33%',
    padding: '0px 30px 0px 30px',
    margin: '15px 0px 15px 0px'
  },
  quarterWidth: {
    width: '25%',
    padding: '0px 30px 0px 30px',
    margin: '15px 0px 15px 0px',
    '& + .quarterWidth': {
      padding: '0px 10px 0px 10px'
    },
    '& > label': {
      padding: '0px 0px 0px 38px'
    }
  },

  checkBoxField: {
    '& > span:first-child': {
      height: '35px'
    },
    '& > span:nth-child(2)': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.8em'
    },
    marginLeft: '0px'
  },
  middleWidth: {
    width: '50%',
    padding: '0px 30px 0px 30px',
    margin: '15px 0px 15px 0px',
    '& label': {
      padding: '0px 0px 0px 3px'
    },
    '& .checkbox-label': {
      padding: '0px 0px 0px 10px'
    },
    '& > label': {
      padding: '0px 0px 0px 38px'
    }
  },
  fullWidth: {
    width: '100%',
    padding: '0px 30px 0px 30px',
    margin: '25px 0px 25px 0px',
    '& label': {
      padding: '0px 0px 0px 3px'
    }
  },
  fingerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '120px',
    marginTop: '17px',
    '& > div': {
      width: '65px',
      height: '65px',
      border: '1px solid #eaeaea',
      borderRadius: '50%'
    },
    '& > div > div': {
      marginTop: '25px',
      width: '40px',
      height: '40px',
      top: '5px',
      marginLeft: '12px',
      '& > svg': {
        overflow: 'initial',
        '& > circle': {
          r: '35',
          strokeWidth: '2',
          animation: 'none',
          strokeDasharray: 'none'
        }
      }
    },
    '& > svg': {
      height: '1.8em',
      width: '1.8em',
      top: '27px'
    },

    '& button': {
      bottom: '8px'
    },
    '& > div:last-child': {
      bottom: '-20px',
      fontSize: '12px',
      position: 'absolute'
    }
  },
  fingerErrorContainter: {
    '& > div > div': {
      '& > div': {
        color: '#ff0000'
      },
      backgroundColor: '#ff0606b0'
    },
    '& > div > svg > g > path': {
      color: 'white'
    }
  },
  fingerWarningContainer: {
    '& > div > div': {
      '& > div': {
        color: '#ff7a00'
      },
      backgroundColor: '#ffc395'
    },
    '& > div > svg > g > path': {
      color: 'white'
    }
  },
  fingerDisabledContainer: {
    '& > div > div': { backgroundColor: '#eaeaea57' },
    '& > div > div > div': {
      color: 'rgba(0, 0, 0, 0.26)'
    }
  },
  fingerOkContainer: {
    '& > div > div': {
      backgroundColor: '#2b9c34bf',
      '& > div': {
        color: '#62906b'
      }
    },
    '& > div > svg > g > path': {
      color: 'white'
    }
  },
  fingerRunningContainter: {
    '& > div > div > div > svg > circle': {
      animation: 'mui-progress-circular-dash 1.4s ease-in-out infinite',
      strokeDasharray: '80px, 200px'
    }
  },
  midSixWidth: {
    width: '15%',
    padding: '10px 10px 10px 10px'
  },
  fullWidthError: {
    width: '100%',
    padding: '0px 30px 0px 30px',
    margin: '-15px 0px 15px 0px',
    color: '#ff7a00',
    fontSize: '10px'
  },
  error: {
    '& label': {
      color: errorRed
    },
    '& div::before': {
      borderColor: errorRed
    }
  },
  dniControl: {
    display: 'inline-flex',
    flex: '1 0',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& > span': {
      padding: '0px 10px 0px 10px'
    },
    '& label > span': {
      paddingLeft: '2px'
    },
    '& > div:first-child': {
      width: 'calc(100% - 80px)'
    }
  },
  errorDniControl: {
    padding: '0px 30px 0px 30px',
    margin: '-15px 0px 15px 0px'
  },
  dni: {
    width: '50px',
    '& > input': {
      textAlign: 'center'
    }
  },
  errorMessages: {
    fontSize: '10px',
    color: errorRed
  },
  notMargin: {
    margin: '0'
  },
  marginBottom: {
    marginBottom: '25px'
  },
  flexEnd: {
    alignItems: 'flex-end'
  },

  emptyError: {
    color: 'transparent',
    fontSize: '5px',
    width: '5px',
    height: '12px'
  },
  /* Init Table search visit */
  tableMiddleWidth: {
    margin: '15px auto',
    width: '75%'
  },
  tableFormSearch: {
    display: 'flex',
    'justify-content': 'flex-start',
    'margin-bottom': '2rem',
    margin: '15px auto',
    width: '100%',
    alignItems: 'baseline',
    justifyContent: 'center'
  },
  tableContainer: {
    display: 'flex',
    marginTop: '20px',
    overflowX: 'auto',
    alignSelf: 'center',
    width: '900px',
    border: '1px solid rgb(224, 224, 224)',
    borderRadius: '5px'
  },
  tableNoData: {
    textAlign: 'center',
    width: '100%',
    margin: '20px 0'
  },
  tablePaper: {
    height: 280,
    width: '100%',
    minWidth: '550px'
  },
  ReactVirtualizedTableRow: {
    '&:hover': {
      backgroundColor: '#000'
    }
  },
  /* End Table search visit */
  line: {
    width: '100%',
    display: 'block'
  },
  btnBlackList: {
    marginTop: '20px',
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    footerSaveButton: { justifyContent: 'center' },
    searchGuest: {
      'justify-content': 'center'
    },
    middleWidth: {
      width: '100%',
      padding: '0px 15px 0px 15px',
      '& label': {
        padding: '0px 0px 0px 3px'
      },
      '& > label': {
        padding: '0px 0px 0px 19px'
      }
    },
    fullWidth: {
      padding: '0px 15px 0px 15px',
      '& label': {
        padding: '0px 0px 0px 18px'
      }
    },
    quarterWidth: {
      width: '100%',
      padding: '0px 15px 0px 15px',
      '& > label': {
        padding: '0px 0px 0px 19px'
      }
    },
    card: {
      width: '100%'
    },
    tablePaper: {
      height: 280,
      width: '100%',
      minWidth: '280px'
    },
    formCard: {
      alignSelf: 'center',
      width: '90%',
      padding: '1rem',
      overflow: 'inherit',
      margin: '0px 0px 15px 0px',
      '& > div:nth-child(2)': {
        paddingBottom: '0px'
      }
    },
    textField: {
      marginLeft: '1px',
      marginRight: '1px',
      width: '100%'
    },
    serialControl: {
      flex: '1 0',
      flexDirection: 'column',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& > span': {
        padding: '0px 10px 0px 10px'
      },
      '& label > span': {
        paddingLeft: '2px'
      },
      '& > div:first-child': {
        width: '100%'
      }
    },
    tableFormSearch: {
      width: '80%',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    tableContainer: {
      width: '450px',
      overflowX: 'scroll'
    }
  },
  [theme.breakpoints.down('md')]: {
    tableContainer: {
      width: '90%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    textField: {
      width: '100%'
    },
    tableFormSearch: {
      width: '100%'
    },
    tableContainer: {
      width: '100%'
    }
  },

  group: {
    marginTop: 15,
    '& > label': {
      padding: '0px 0px 0px 8px'
    }
  }
});
