import axiosRetry from 'axios-retry';
import { useMessageContext } from '../libraries/contexts';
import API_PUBLIC_SERVICE, { SERVER_API, publicInstance } from './http';
import persistenceService from './persistence-service';

publicInstance.interceptors.response.use(
  response => response,
  error => {
    try {
      const [value, setValue] = useMessageContext();
      setValue(error);
    } catch (er) {}
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.toLowerCase().includes('token')
    ) {
      persistenceService.clearPersistentDataProps();
      window.location.reload();
    }
    throw error;
  }
);

publicInstance.interceptors.request.use(
  config => {
    const access_token = persistenceService.get('token');

    if (access_token !== null) {
      config.headers.Authorization = `${access_token}`;
    }
    if (!access_token && !config.url.includes('login')) {
      window.location.reload();
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axiosRetry(publicInstance, {
  retries: 3
});

const PREFIX = 'users';
const path = PREFIX;
let activeUser = null;
const PERMISSIONS = {
  ADMIN: [
    'INSERT_VISITOR',
    'CLOSE_VISITOR',
    'MAINTAINER_LIST',
    'REPORT_LIST',
    'BRANCH_OFFICES',
    'COMPANIES',
    'VISIT_REPORT',
    'DAILY_CLOSING',
    'USERS',
    'BLACK_LIST',
    'ACCESSES',
    'CREDENTIALS',
    'VISITEDS',
    'COMPANY_LIST',
    'EDIT_COMPANY',
    'ADD_COMPANY',
    'BRANCH_OFFICE_LIST',
    'EDIT_BRANCH_OFFICE',
    'ADD_BRANCH_OFFICE',
    'CREDENTIAL_LIST',
    'EDIT_CREDENTIAL',
    'ADD_CREDENTIAL',
    'VISITEDS_LIST',
    'CREATE_VISITEDS',
    'EDIT_VISITEDS',
    'ACCESS_LIST',
    'EDIT_ACCESS',
    'ADD_ACCESS',
    'USER_LIST',
    'EDIT_USER',
    'ADD_USER',
    'CONFIG_LIST',
    'GUESTS_UPDATE',
    'CONFIG_DAILY_CLOSING',
    'CONFIG_GUESTS_UPDATE'
  ],
  SUPER_ADMIN: [
    'INSERT_VISITOR',
    'CLOSE_VISITOR',
    'MAINTAINER_LIST',
    'REPORT_LIST',
    'BRANCH_OFFICES',
    'COMPANIES',
    'VISIT_REPORT',
    'DAILY_CLOSING',
    'USERS',
    'BLACK_LIST',
    'ACCESSES',
    'CREDENTIALS',
    'VISITEDS',
    'COMPANY_LIST',
    'EDIT_COMPANY',
    'ADD_COMPANY',
    'BRANCH_OFFICE_LIST',
    'EDIT_BRANCH_OFFICE',
    'ADD_BRANCH_OFFICE',
    'CREDENTIAL_LIST',
    'EDIT_CREDENTIAL',
    'ADD_CREDENTIAL',
    'VISITEDS_LIST',
    'CREATE_VISITEDS',
    'EDIT_VISITEDS',
    'ACCESS_LIST',
    'EDIT_ACCESS',
    'ADD_ACCESS',
    'USER_LIST',
    'EDIT_USER',
    'ADD_USER',
    'DAILY_CLOSING',
    'SETTINGS',
    'GUESTS_UPDATE',
    'CONFIG_LIST',
    'CONFIG_DAILY_CLOSING',
    'CONFIG_SETTINGS_COMPANY',
    'CONFIG_GUESTS_UPDATE'
  ],
  USER: ['INSERT_VISITOR', 'CLOSE_VISITOR', 'REPORT_LIST', 'VISIT_REPORT'],
  SUPERVISOR: [
    'INSERT_VISITOR',
    'CLOSE_VISITOR',
    'BLACK_LIST',
    'VISIT_REPORT',
    'MAINTAINER_LIST',
    'VISITEDS',
    'REPORT_LIST',
    'VISITEDS_LIST',
    'CREATE_VISITEDS',
    'EDIT_VISITEDS'
  ]
};

PERMISSIONS['Administrador'] = PERMISSIONS.ADMIN;
PERMISSIONS['Usuario'] = PERMISSIONS.USER;
PERMISSIONS['Supervisor'] = PERMISSIONS.SUPERVISOR;
PERMISSIONS['SuperAdmin'] = PERMISSIONS.SUPER_ADMIN;

const getPermissionsPerRol = name => {
  const permisions = PERMISSIONS[name];
  return permisions || [];
};

const checkPassword = async password => {
  try {
    const user = persistenceService.getObject('user');
    const json = {
      username: user.username,
      password
    };
    await publicInstance.post(`${SERVER_API}login`, json);
  } catch (err) {
    throw err;
  }
};
const login = async json => {
  try {
    const res = await publicInstance.post(`${SERVER_API}login`, json);

    const { data } = await res;
    const { token, user } = data;
    user.token = token;
    persistenceService.save('token', token);
    persistenceService.save('user', user);
    activeUser = user;
    return data;
  } catch (err) {
    const {
      response: { data }
    } = await err;
    persistenceService.save('token', '');
    throw Object({
      ...data,
      errors: ['password']
    });
  }
};
const hasPermission = value => {
  const user = getCurrentUser();
  return user.permissions.includes(value);
};

const IsLogged = () => {
  const token = persistenceService.get('token');
  const currentUser = getCurrentUser();
  return Boolean(token && currentUser.enabled);
};

const hasRol = val => {
  const user = getCurrentUser();
  return user.role === val;
};
const getCurrentUser = () => {
  let user = activeUser;
  if (!activeUser) {
    user = persistenceService.getObject('user');
    activeUser = user;
  }

  if (!user) return {};
  const { role } = user;
  const permissions = getPermissionsPerRol(role);
  user.permissions = permissions;
  return user;
};

const getAdminUser = async () => {
  const res = await publicInstance.get(`${SERVER_API}${PREFIX}/get-admin-user`);
  const { data } = res;
  return data;
};
const getAdminCompany = async () => {
  const res = await publicInstance.get(`${SERVER_API}${PREFIX}/get-admin-company`);
  const { data } = res;
  return data;
};

const logout = async () => {
  try {
    persistenceService.removeKey('user');
    persistenceService.removeKey('token');
    await publicInstance.get(`${SERVER_API}logout`);
  } catch (err) {
    const {
      response: { data }
    } = await err;
    throw Object({
      ...data,
      errors: ['logout']
    });
  }
};

const userPermission = {
  getCurrentUser,
  IsLogged,
  hasPermission,
  hasRol
};

const UserService = {
  path,
  login,
  checkPassword,
  logout,
  getAdminUser,
  getAdminCompany,
  ...userPermission,
  ...API_PUBLIC_SERVICE
};

export default UserService;
