import React, { useState, useEffect, useRef, useReducer, useContext, useCallback } from 'react';
import moment from 'moment';
import useOnlineStatus from '@rehooks/online-status';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import ArrowBack from '@material-ui/icons/ArrowBack';
import FormLabel from '@material-ui/core/FormLabel';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import AlertDialog from '../../../components/dialog';
import SearchableSelect from '../../../components/fields/searchableSelect';
import BlackListService from '../../../services/blackList';
import CountriesService from '../../../services/countries';
import VisitorService from '../../../services/visitor';
import MailerService from '../../../services/mailer';
import StepsContext from '../../../contexts/stepsContext';
import { useToggleNavBar } from '../../../libraries/contexts';
import { requiredValue } from '../../../libraries/validators';
import RadioValidatorElement from '../../../components/fields/radioButton';
import { validateLatinCharacters, cleanDots } from '../../../libraries/utils';
import {
  REGISTER_TITLE_STEP_2,
  CONTINUE,
  IDENTIFICATION_NUMBER,
  VISITOR_NAMES,
  BACK,
  VISITOR_SURNAME,
  VISITOR_SECOND_SURNAME,
  REGISTER_STEP_2_BTN,
  MAX_STRING_LENGTH,
  REQUIRED_FIELD,
  HAS_VISIT_ACTIVE_MSG,
  NO_OPTION_LABELS,
  VISITOR_COUNTRY
} from '../../../libraries/texts';
/* import { generalClasses as styles } from '../styles'; */
import { generalClasses as styles } from '../../accesses/styles';

const sexs = [{ value: 'F', label: 'Femenino' }, { value: 'M', label: 'Masculino' }];

const addVisitorReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};

const initialState = {
  documentId: '',
  names: '',
  surname: '',
  secondSurname: '',
  country: { label: 'Chile', value: 'Chile' },
  gender: '',
  documentType: '',
  blackListVisitor: {}
};

const fillInitialValues = (obj, countries) => {
  const { names, surname, secondSurname, passport, gender, country } = obj;
  const countryObject = countries.find(({ code }) => code.toUpperCase() === country);
  return {
    documentId: passport,
    names,
    surname,
    secondSurname,
    country: countryObject,
    gender
  };
};

const AddVisitor = props => {
  const onlineStatus = useOnlineStatus();
  const [state, dispatch] = useReducer(addVisitorReducer, initialState);
  const [countries, setCountries] = useState([]);
  const stepsContext = useContext(StepsContext);
  const [, changeToggleNavBar] = useToggleNavBar();
  const formRefAddVisitor = useRef(null);
  const { dispatch: stepsDispatch, beforeStep: data } = stepsContext;
  const { status, visitor } = data;
  const {
    documentId,
    names,
    surname,
    secondSurname,
    country,
    gender,
    blackListVisitor,
    _id: id
  } = state;
  const [disabled, setDisabled] = useState(false);
  const { classes, formControlClass = classes.middleWidth } = props;
  const [hasActiveVisit, setHasActiveVisit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const validatorListener = async () => {
    const result = await formRefAddVisitor.current.isFormValid();
    setDisabled(result);
  };

  const getRequests = async () => {
    const countriesList = await CountriesService.getAll();
    const newArray = countriesList.map(item => ({
      value: item.name,
      label: item.name,
      code: item.code3
    }));

    setCountries(newArray);
  };

  const AskPermissionIfVisitorIsInBlackList = useCallback(() => {
    async function fecthBlackListVisitor() {
      try {
        const datablackListVisitor = await BlackListService.getByDocId(
          cleanDots(data.documentId),
          data.documentType
        );
        if (!datablackListVisitor) return;

        if (!data._id) {
          data.status = null;
          data.names = datablackListVisitor.names;
          data.surname = datablackListVisitor.surname;
          data.secondSurname = datablackListVisitor.secondSurname || '';
          data.gender = datablackListVisitor.gender;
          data.blackList = datablackListVisitor._id;
          data.country = {
            label: datablackListVisitor.country,
            value: datablackListVisitor.country
          };
          dispatch(data);
        } else if (!data.blackList) {
          data.blackList = datablackListVisitor._id;
          dispatch(data);
        }
        dispatch({ blackListVisitor: datablackListVisitor });
        setDialogOpen(true);
      } catch (err) {
        console.error('[ fetch blacklist ]', err);
      }
    }
    fecthBlackListVisitor();
  }, [data]);

  const ReturnIfVisitorHasVisitActive = useCallback(() => {
    const timeToRedirect = 2000;
    const hasVisit = !!data.activeVisit;
    if (!hasVisit) {
      return false;
    }
    setHasActiveVisit(hasVisit);
    setTimeout(() => {
      stepsDispatch({ type: 'back', data });
    }, timeToRedirect);
    changeToggleNavBar({
      message: { message: HAS_VISIT_ACTIVE_MSG, open: true }
    });
    return true;
  }, [data, stepsDispatch, changeToggleNavBar]);

  useEffect(() => {
    ValidatorForm.addValidationRule('requiredValue', requiredValue);
    getRequests();

    ReturnIfVisitorHasVisitActive() || AskPermissionIfVisitorIsInBlackList();
  }, [dispatch, AskPermissionIfVisitorIsInBlackList, ReturnIfVisitorHasVisitActive]);

  useEffect(() => {
    if (visitor && countries.length) {
      dispatch(fillInitialValues(visitor, countries));
    }
  }, [visitor, countries]);

  useEffect(() => {
    dispatch(data);
  }, [data]);

  const saveNewVisitor = async visitorData => {
    changeToggleNavBar({
      message: { message: 'Guardando Nuevo Visitante', open: true }
    });

    if (!visitorData) {
      changeToggleNavBar({
        message: { message: 'Error al guardar Nuevo Visitante', open: true }
      });
      return false;
    }
    if (!visitorData.documentId && visitorData.documentId.length === 0) {
      changeToggleNavBar({
        message: { message: 'Número de identificación es obligatorio', open: true }
      });
      return false;
    }
    if (!visitorData.names && visitorData.names.length === 0) {
      changeToggleNavBar({
        message: { message: 'Campo Nombres es obligatorio ', open: true }
      });
      return false;
    }
    if (!visitorData.surname && visitorData.surname.length === 0) {
      changeToggleNavBar({
        message: { message: 'Campo Apellido Paterno es obligatorio', open: true }
      });
      return false;
    }
    if (!visitorData.secondSurname && visitorData.secondSurname.length === 0) {
      changeToggleNavBar({
        message: { message: 'Campo Apellido Materno es obligatorio', open: true }
      });
      return false;
    }

    const savedData = await VisitorService.post(visitorData);
    return savedData;
  };

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContentForm}>
        {
          <React.Fragment>
            <AlertDialog
              title="El visitante tiene restricción de acceso"
              description={[
                `Motivo: ${blackListVisitor.reason}`,
                `Fecha: ${moment(blackListVisitor.createdAt).format('DD/MM/YYYY HH:mm:ss')}`
              ]}
              open={dialogOpen}
              onOK={() => setDialogOpen(false)}
              onCancel={() => {
                MailerService.sendMail(blackListVisitor._id);
                stepsDispatch({ type: 'back', data });
              }}
            />
            <div className={classNames(formControlClass, classes.serialControl)}>
              <div className={classes.backForm}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => stepsDispatch({ type: 'back', data })}
                  className={classes.buttonSearch}
                  disabled={!onlineStatus || hasActiveVisit || dialogOpen}
                >
                  <ArrowBack className={classes.rightIcon} />
                  {BACK}
                </Button>
              </div>
              <ValidatorForm
                autoComplete="off"
                className={classes.formStyle}
                ref={formRefAddVisitor}
                onSubmit={async () => {
                  const { _id: hasId } = state;
                  let savedData = state;

                  if (!hasId) {
                    const dataTosave = state;
                    dataTosave.manual = state.manual;
                    dataTosave.country =
                      state.country && state.country.value ? state.country.value : null;
                    dataTosave.documentId = cleanDots(state.documentId);
                    savedData = await saveNewVisitor(dataTosave);
                  } else if (hasId && state.blackList) {
                    savedData = await VisitorService.put(state._id, { blackList: state.blackList });
                  }

                  if (savedData) stepsDispatch({ type: 'next', data: savedData });
                }}
              >
                <FormLabel component="legend">{REGISTER_TITLE_STEP_2}</FormLabel>
                <TextField
                  id="documentId"
                  label={IDENTIFICATION_NUMBER}
                  className={classes.textField}
                  margin="normal"
                  value={documentId}
                  disabled
                />
                <TextValidator
                  id="names"
                  name="names"
                  label={VISITOR_NAMES}
                  autoComplete="off"
                  className={classes.textField}
                  margin="normal"
                  required
                  inputProps={{ maxLength: 30 }}
                  value={names}
                  validatorListener={validatorListener}
                  validators={['requiredValue', 'maxStringLength:30']}
                  errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
                  disabled={!status}
                  onChange={e => {
                    dispatch({ [e.target.name]: validateLatinCharacters(e.target.value) });
                  }}
                />
                <TextValidator
                  id="surname"
                  name="surname"
                  inputProps={{ maxLength: 30 }}
                  label={VISITOR_SURNAME}
                  autoComplete="off"
                  required
                  className={classes.textField}
                  margin="normal"
                  value={surname}
                  validatorListener={validatorListener}
                  validators={['requiredValue', 'maxStringLength:30']}
                  errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
                  disabled={!status}
                  onChange={e => {
                    dispatch({ [e.target.name]: validateLatinCharacters(e.target.value) });
                  }}
                />
                <TextValidator
                  id="second-surname"
                  name="secondSurname"
                  label={VISITOR_SECOND_SURNAME}
                  inputProps={{ maxLength: 30 }}
                  className={classes.textField}
                  disabled={!status}
                  autoComplete="off"
                  validatorListener={validatorListener}
                  value={secondSurname}
                  validators={['maxStringLength:30']}
                  errorMessages={[MAX_STRING_LENGTH]}
                  margin="normal"
                  onChange={e => {
                    dispatch({ [e.target.name]: validateLatinCharacters(e.target.value) });
                  }}
                />

                <SearchableSelect
                  label={VISITOR_COUNTRY}
                  value={typeof country === 'object' ? country : { label: country, value: country }}
                  options={countries}
                  isDisabled={!status}
                  noOptionsMessage={() => NO_OPTION_LABELS}
                  onChange={e => {
                    dispatch({ country: e });
                  }}
                />

                <RadioValidatorElement
                  aria-label="Sexo"
                  name="gender"
                  value={gender}
                  disabled={!status}
                  className={classes.group}
                  legend="sexo"
                  data={sexs}
                  onChange={e => {
                    dispatch({ [e.target.name]: e.target.value });
                  }}
                />
                <div className={classes.footerButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonSearch}
                    disabled={!disabled || !onlineStatus || hasActiveVisit || dialogOpen}
                    type="submit"
                  >
                    {id ? CONTINUE : REGISTER_STEP_2_BTN}
                  </Button>
                </div>
              </ValidatorForm>
            </div>
            <Divider />
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(AddVisitor);
