import React, { useReducer, useState, useEffect, useRef } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getValidations } from '../../libraries/validators';
import SimpleSelectValidatorElement from '../../components/fields/SimpleSelectValidatorElement';
import InputMaskValidatorElement from '../../components/fields/inputWithMask';
import { useToggleNavBar } from '../../libraries/contexts';
import CheckboxValidatorElement from '../../components/fields/checkBox';
import {
  validatePassword,
  latinAlphaNumericCharactersAllow,
  save,
  getFormatNumber,
  validateEmailCharacters
} from '../../libraries/utils';
import SelectValidatorElement from '../../components/fields/selectSearch';
import CompaniesService from '../../services/companies';
import {
  MAX_STRING_LENGTH,
  REQUIRED_FIELD,
  BACK,
  SAVE,
  ERROR_USERS,
  MIN_STRING_LENGTH_PHONE,
  PHONE_NUMBER,
  EMAIL_ERROR,
  ROLE_LIST,
  NO_OPTION_LABELS,
  NOT_VALID_PASSWORD_ERR_MSG
} from '../../libraries/texts';
import UsersService from '../../services/users';
import { generalClasses as styles } from './styles';
import UserService from '../../services/user-service';

const formReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};
const initialState = {
  name: '',
  surname: '',
  role: 'USER',
  company: '',
  phoneNumber: '',
  username: '',
  MIN_STRING_LENGTH_PHONE,
  confirmPassword: '',
  password: '',
  associatedCompanies: []
};

const Form = props => {
  const { classes, entity, hidePassword, history } = props;
  const formRef = useRef(null);
  const [state, dispatch] = useReducer(formReducer, entity || initialState);
  const [disabled, setDisabled] = useState(false);
  const onlineStatus = useOnlineStatus();
  const [showPassword, setShowPassword] = useState(!hidePassword);
  const [addAllCompanies, setAddAllCompanies] = useState(false);
  const [companyAdmin, setCompanyAdmin] = useState({ name: '' });
  const [, changeToggleNavBar] = useToggleNavBar();
  const [companies, setCompanies] = useState([]);
  const {
    name,
    surname,
    associatedCompanies,
    username,
    role,
    company,
    confirmPassword,
    phoneNumber,
    password
  } = state;

  const submitForm = async () => {
    try {
      const { _id: hasId } = state;
      const result = await save(
        hasId,
        { ...state, showPassword, company: companyAdmin },
        UsersService
      );

      if (result) {
        changeToggleNavBar({
          message: { message: `Usuario ${hasId ? 'actualizado' : 'creado'}`, open: true }
        });
        return history.push('/users/list');
      }
      return changeToggleNavBar({ message: { message: 'Not found', open: true } });
    } catch (err) {
      return changeToggleNavBar({
        message: { message: ERROR_USERS[err.message], open: true }
      });
    }
  };

  useEffect(() => {
    getValidations();
    getRequests();
  }, []);

  const getRequests = async () => {
    const getCompanyAdmin = await UserService.getAdminCompany();

    const companiesList = await CompaniesService.getAll();
    const getCompanies = companiesList.map(item => {
      const { name: companyName, _id: id } = item;
      const jsonOffice = {
        value: companyName,
        label: companyName,
        id,
        companyName
      };
      return jsonOffice;
    });
    setCompanyAdmin(getCompanyAdmin);
    setCompanies(getCompanies);
  };

  const validatorListener = async () => {
    const result = await formRef.current.isFormValid();
    setDisabled(result);
  };

  return (
    <ValidatorForm
      novalidate
      className={classes.formVerify}
      ref={formRef}
      instantValidate
      onSubmit={submitForm}
    >
      <div className={classes.backForm}>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          size="small"
          className={classes.buttonSearch}
          to="/users/list"
          disabled={!onlineStatus}
        >
          <ArrowBack className={classes.rightIcon} />
          {BACK}
        </Button>
      </div>
      <TextValidator
        id="name"
        required
        name="name"
        label="Nombre"
        inputProps={{ maxLength: 30 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={name}
        validators={['requiredValue', 'maxStringLength:30']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: latinAlphaNumericCharactersAllow(e.target.value) });
        }}
      />
      <TextValidator
        id="surname"
        required
        name="surname"
        label="Apellido"
        inputProps={{ maxLength: 30 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={surname}
        validators={['requiredValue', 'maxStringLength:30']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: latinAlphaNumericCharactersAllow(e.target.value) });
        }}
      />
      <InputMaskValidatorElement
        id="phoneNumber"
        label={PHONE_NUMBER}
        format="+(56)##########"
        name="phoneNumber"
        required
        className={classes.numberMask}
        errorClassName={classes.errorValidation}
        autoComplete="off"
        validatorListener={validatorListener}
        containerClassName={classes.inputDiv}
        validators={['requiredValue', 'maxStringLength:10', 'minStringLength:9']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, MIN_STRING_LENGTH_PHONE]}
        value={phoneNumber}
        onChange={e => {
          dispatch({
            phoneNumber: getFormatNumber(e.value, { maxLength: 10 })
          });
        }}
      />

      <TextValidator
        id="username"
        name="username"
        required
        value={username}
        label="E-mail"
        validatorListener={validatorListener}
        inputProps={{ maxLength: 50 }}
        className={classes.textField}
        autoComplete="off"
        validators={['requiredValue', 'maxStringLength:50', 'isEmail', 'minStringLength:5']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, EMAIL_ERROR, 'Minimo 5 caracteres']}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateEmailCharacters(e.target.value) });
        }}
      />
      <SimpleSelectValidatorElement
        value={role}
        id="role"
        name="role"
        classes={classes}
        label="Rol"
        validatorListener={validatorListener}
        options={ROLE_LIST}
        onChange={e => {
          dispatch({ [e.target.name]: e.target.value });
        }}
      />
      <TextField
        id="company"
        name="company"
        label="Empresa"
        disabled
        margin="normal"
        required
        value={(entity && company.name) || (companyAdmin && companyAdmin.name)}
        className={classes.textField}
      />

      <div style={{ display: !entity ? 'none' : 'block' }}>
        <CheckboxValidatorElement
          label="Cambiar Contraseña"
          value={showPassword}
          name="showPassword"
          onChange={async e => {
            await setShowPassword(!showPassword);
            validatorListener(validatorListener);
          }}
        />
      </div>
      <div style={{ display: showPassword ? 'block' : 'none' }}>
        <TextValidator
          id="password"
          required={showPassword}
          name="password"
          label="Contraseña"
          inputProps={{ maxLength: 20 }}
          className={classes.textField}
          validatorListener={validatorListener}
          value={password}
          type="password"
          validators={
            showPassword
              ? ['requiredValue', 'maxStringLength:20', 'minStringLength:8', 'isValidPassword']
              : []
          }
          errorMessages={
            showPassword
              ? [
                  REQUIRED_FIELD,
                  MAX_STRING_LENGTH,
                  'Mínimo 8 caracteres',
                  NOT_VALID_PASSWORD_ERR_MSG
                ]
              : []
          }
          margin="normal"
          onChange={e => {
            dispatch({ [e.target.name]: validatePassword(e.target.value) });
          }}
        />
        <TextValidator
          id="confirmPassword"
          required={showPassword}
          name="confirmPassword"
          label="Confirmar Contraseña"
          inputProps={{ maxLength: 20 }}
          className={classes.textField}
          validatorListener={validatorListener}
          type="password"
          value={confirmPassword}
          validators={
            showPassword
              ? [`isPasswordMatch:${password}`, 'requiredValue', 'minStringLength:8']
              : []
          }
          errorMessages={
            showPassword
              ? ['Las contraseñas deben coincidir', REQUIRED_FIELD, 'Mínimo 8 caracteres']
              : []
          }
          margin="normal"
          onChange={e => {
            dispatch({ [e.target.name]: validatePassword(e.target.value) });
          }}
        />
      </div>
      <CheckboxValidatorElement
        label="Todas las empresas"
        value={addAllCompanies}
        name="addAllCompanies"
        onChange={async e => {
          dispatch({
            associatedCompanies: !addAllCompanies ? companies : []
          });
          await setAddAllCompanies(!addAllCompanies);
          validatorListener(validatorListener);
        }}
      />
      <SelectValidatorElement
        id="associatedCompanies"
        name="associatedCompanies"
        required
        validatorListener={validatorListener}
        value={associatedCompanies}
        placeholder="Empresas Asociadas"
        isMulti
        getOptionLabel={item => item.companyName || item.name}
        getOptionValue={item => item.id || item._id}
        options={companies}
        noOptionsMessage={() => NO_OPTION_LABELS}
        onChange={e => {
          dispatch({ associatedCompanies: e });
          validatorListener(validatorListener);
        }}
      />
      <div className={classes.footerSaveButton}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={!onlineStatus || !disabled}
        >
          {SAVE}
        </Button>
      </div>
    </ValidatorForm>
  );
};

export default withStyles(styles)(Form);
