import Unauthorized from '../pages/unauthorized';
import User from './user-service';

export const checkIsLogged = view => {
  if (!User.IsLogged()) return Unauthorized;
  return view;
};

export const isLogged = () => User.IsLogged();

export const checkAuthorizationView = (view, permissions) => {
  if (!User.IsLogged()) return Unauthorized;
  return checkPermissions(permissions) ? view : Unauthorized;
};

export const checkPermissions = permissions => {
  const userPermissions = User.getCurrentUser().permissions;
  return permissions.some(elem => userPermissions.includes(elem));
};

export const checkOwnPermissions = value => permissions => {
  const userPermissions = checkPermissions(permissions);
  const user = User.getCurrentUser();
  const deleteUser = permissions.includes('DELETE_USER') && value === user._id;
  return userPermissions && !deleteUser;
};

export const getItemsMenu = navBarButtons => {
  const userPermissions = isLogged() ? User.getCurrentUser().permissions : [];

  return navBarButtons.filter(item => userPermissions.includes(item.menuPermission));
};
