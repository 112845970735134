import React from 'react';
import 'moment/locale/es';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import { InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';

const materialTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        width: '210px',
        height: '40px'
      }
    }
  }
});

const containerDivStyle = { marginTop: '10px', marginLeft: '20px' };

const dateTimePicker = props => {
  const { value, onChange, label, variant, format, ampm, minDate, maxDate, ...other } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
      <MuiThemeProvider theme={materialTheme}>
        <div className="picker" style={containerDivStyle}>
          <InlineDateTimePicker
            keyboard
            ampm={ampm}
            variant={variant}
            label={label}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            format={format}
            {...other}
          />
        </div>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

dateTimePicker.propTypes = {
  value: PropTypes.string,
  variant: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  label: PropTypes.string,
  ampm: PropTypes.bool,
  format: PropTypes.string,
  onChange: PropTypes.func
};

dateTimePicker.defaultProps = {
  value: undefined,
  variant: null,
  minDate: null,
  maxDate: null,
  ampm: false,
  format: null,
  label: null,
  onChange: () => {}
};

export default dateTimePicker;
