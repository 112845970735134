import { isValidRut } from '../../libraries/validators';
import { getFormatRut, getFormatRutNoDots } from '../../libraries/utils';

import {
  FILE_SIZE_IS_TOO_BIG,
  INVALID_FILE_EXTENSION,
  REQUIRED_FIELDS_CSV,
  INVALID_CSV_DATA,
  DUPLICATED_BLACK_LIST_USER
} from '../../libraries/texts';

const REQUIRED_FIELDS = 'Tipo documento, Nº de documento, Nombres, Apellido Paterno y Motivo';
const INVALID_DATA =
  'Tipo documento [RUT o PASAPORTE], Nº de documento [sin puntos], Nombres y Apellidos [Sólo letras], Sexo [F o M], Nacionalidad [País de nacimiento], procedencia [Max 50], motivo [max 500]';
const VALID_TYPE_ID = ['RUT', 'PASSPORT'];
const VALID_GENDER = ['F', 'M'];
const DOT_REGEX = /\./g;
const ONLY_LETTERS_REGEX = /(^(\s)|[^a-z 'çàáâãäåèéêëìíîïòóôõöùúûüñ]|([ ]{2,}))/gi;
const VALID_SPACES = /(^(\s)|([ ]{2,}))/gi;
const VALID_PASSPORT_REGEX = /[^a-z0-9-]/gi;
const MAX_NAMES_LENGTH = 30;
const MAX_COUNTRY_LENGTH = 50;
const MAX_ORIGIN_LENGTH = 50;
const MAX_REASON_LENGTH = 500;
const VALID_EXTENSIONS = ['.csv'];
const MAX_FILE_SIZE = 10485760;
const MIN_PASSPORT_LENGTH = 7;
const MAX_PASSPORT_LENGTH = 50;
const createJSON = array => {
  const [
    prevTypeId,
    prevNumberId,
    prevNames,
    prevSurname,
    prevSecondSurName,
    prevCountry,
    prevGender,
    prevOrigin,
    prevReason,
    prevEmailAdmin
  ] = array;
  let typeId = prevTypeId.replace(/\s/gi, '').toUpperCase();
  typeId = typeId === 'PASAPORTE' ? 'PASSPORT' : typeId;
  const numberId =
    typeId === 'RUT'
      ? getFormatRutNoDots(prevNumberId)
      : prevNumberId.replace(VALID_PASSPORT_REGEX, '');
  const names = prevNames.trim().replace(VALID_SPACES, ' ');
  const surname = prevSurname.trim().replace(VALID_SPACES, ' ');
  const secondSurName = prevSecondSurName.trim().replace(VALID_SPACES, ' ');
  const country = prevCountry.trim().replace(VALID_SPACES, ' ');
  const gender = prevGender.replace(/\s/gi, '').toUpperCase();
  const origin = prevOrigin.trim().replace(VALID_SPACES, ' ') || 'Particular';
  const reason = prevReason.trim().replace(VALID_SPACES, ' ');
  const emailAdmin = prevEmailAdmin || 'admin@admin.io';
  return {
    typeId,
    numberId,
    names,
    surname,
    secondSurName,
    country,
    gender,
    origin,
    reason,
    emailAdmin
  };
};
const readFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(
        reader.result.split('\n').reduce((result, line) => {
          if (line) {
            result.push(createJSON(line.split(',')));
          }
          return result;
        }, [])
      );
    };
    reader.onerror = () => {
      return reject(new Error('Error al leer el archivo.'));
    };
    reader.readAsText(file);
  });
};

const requiredFields = (typeId, numberId, surname, reason) =>
  !!typeId && !!numberId && !!surname && !!reason;

const isUnique = (arr, typeId, numberId) =>
  arr.filter(
    item => item.typeId.toUpperCase() === typeId.toUpperCase() && item.numberId === numberId
  );

const validateData = (arr, index) => {
  const { typeId, numberId, names, surname, secondSurName, gender, country, origin, reason } = arr[
    index
  ];
  const errorsRow = new Set();

  if (VALID_TYPE_ID.indexOf(typeId.toUpperCase()) === -1) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (DOT_REGEX.test(numberId)) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (ONLY_LETTERS_REGEX.test(names) || names.length > MAX_NAMES_LENGTH) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (ONLY_LETTERS_REGEX.test(surname) || surname.length > MAX_NAMES_LENGTH) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (ONLY_LETTERS_REGEX.test(secondSurName) || secondSurName.length > MAX_NAMES_LENGTH) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (gender) {
    VALID_GENDER.indexOf(gender) === -1 && errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (typeId.toUpperCase() === 'RUT' && !isValidRut(getFormatRut(numberId))) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (typeId.toUpperCase() === 'PASSPORT') {
    (numberId.length < MIN_PASSPORT_LENGTH ||
      numberId.length > MAX_PASSPORT_LENGTH ||
      VALID_PASSPORT_REGEX.test(numberId)) &&
      errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (origin) {
    origin.length > MAX_ORIGIN_LENGTH && errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (ONLY_LETTERS_REGEX.test(country) || country.length > MAX_COUNTRY_LENGTH) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (reason.length > MAX_REASON_LENGTH || VALID_SPACES.test(reason)) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (!requiredFields(typeId, numberId, names, surname, reason)) {
    errorsRow.add(REQUIRED_FIELDS_CSV(REQUIRED_FIELDS));
  }
  if (isUnique(arr, typeId, numberId).length > 1) {
    errorsRow.add(DUPLICATED_BLACK_LIST_USER(numberId));
  }
  return Array.from(errorsRow, val => ({ row: `${index + 1}`, message: val }));
};

const validateFile = ({ name, size }, fn) => {
  let isValid = true;
  const fileExtension = name
    .split('.')
    .pop()
    .toLowerCase();

  if (VALID_EXTENSIONS.indexOf(`.${fileExtension}`) === -1) {
    fn(prevError => [...prevError, INVALID_FILE_EXTENSION]);
    isValid = false;
  }

  if (size > MAX_FILE_SIZE) {
    fn(prevError => [...prevError, FILE_SIZE_IS_TOO_BIG]);
    isValid = false;
  }
  return isValid;
};

export { validateData, validateFile, readFile, VALID_EXTENSIONS };
