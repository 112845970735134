import axios from 'axios';
import API_PUBLIC_SERVICE from './http';
import persistenceService from './persistence-service';
import { useMessageContext } from '../libraries/contexts';

const PREFIX = 'guests';

const path = PREFIX;

const { REACT_APP_API_URL } = process.env;

export const SERVER_API = REACT_APP_API_URL;

const REMOVE_LINK_TIME = 100;
const headers = {
  'Content-Type': 'application/json; charset=utf-8'
};

export const publicInstance = axios.create({
  baseURL: SERVER_API,
  timeout: 300000,
  crossdomain: true,
  headers
});
publicInstance.interceptors.response.use(
  response => response,
  error => {
    try {
      const [value, setValue] = useMessageContext();
      setValue(error);
    } catch (er) {}
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.toLowerCase().includes('token')
    ) {
      persistenceService.clearPersistentDataProps();
      window.location.reload();
    }
    throw error;
  }
);

publicInstance.interceptors.request.use(
  config => {
    const access_token = persistenceService.get('token');

    if (access_token !== null) {
      config.headers.Authorization = `${access_token}`;
    }
    if (!access_token && !config.url.includes('login')) {
      window.location.reload();
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
const downloadCSV = async ({ filter = '', select }) => {
  try {
    const filtered = new URLSearchParams(`?filter=${filter}&select=${select}`);
    const [date] = new Date(Date.now()).toJSON().split('T') || [''];
    const fileName = `Lista-Visitados-${date}.csv`;

    await publicInstance({
      url: `${SERVER_API}${path}/download-csv?${filtered.toString()}`,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      const { data } = response;
      if (!data.size) {
        return null;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), REMOVE_LINK_TIME);
      return true;
    });
  } catch (err) {
    const {
      response: { data }
    } = await err;

    throw data;
  }
};

const InsuranceService = {
  ...API_PUBLIC_SERVICE,
  downloadCSV,
  path
};

export default InsuranceService;
