import React from 'react';
import red from '@material-ui/core/colors/red';

import NoSsr from '@material-ui/core/NoSsr';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import Select from './searchableSelect';

const red300 = red['500'];

const style = {
  fontSize: '12px',
  color: red300,
  marginTop: '8px'
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: '1rem 0rem 1rem 0rem'
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: '0.5 0.25'
  },
  chipFocused: {
    backgroundColor: red
  },
  noOptionsMessage: {
    padding: '1 2'
  },
  singleValue: {
    fontSize: '16s'
  },
  placeholder: {
    position: 'absolute',
    left: 10,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '1p',
    left: 0,
    right: 0
  },
  divider: {
    height: '2px'
  }
});

const selectStyles = {
  control: styles => ({
    ...styles,
    marginTop: '20px',
    border: 'none',
    borderBottom: '1px solid gray',
    borderRadius: '0px',
    boxShadow: '0 !important',
    '&:hover': {
      borderBottom: '2px solid gray'
    },
    '.css-1hwfws3': {
      padding: '1px'
    }
  }),
  placeholder: styles => ({
    ...styles,
    font: 'inherit',
    color: 'gray',
    fontWeight: '500'
  })
};

class SelectValidatorElement extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      disabled,
      required,
      options,
      classes,
      label,
      id,
      className,
      TextFieldProps,
      isClearable = true,
      ...rest
    } = this.props;

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            inputId={id}
            styles={selectStyles}
            className={className}
            required={required}
            placeholder={label}
            maxMenuHeight={150}
            value={value}
            textFieldProps={TextFieldProps}
            isDisabled={disabled}
            options={options}
            isClearable={isClearable}
            {...rest}
          />

          {!this.state.isValid ? (
            <div style={style}>
              <p>{this.getErrorMessage()}</p>
            </div>
          ) : null}
        </NoSsr>
      </div>
    );
  }
}

export default withStyles(styles)(SelectValidatorElement);
