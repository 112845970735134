import { isValidRut } from '../../libraries/validators';
import {
  getFormatRut,
  validateEmailCharacters,
  validateLatinCharacters,
  getFormatRutNoDots
} from '../../libraries/utils';

import {
  FILE_SIZE_IS_TOO_BIG,
  INVALID_FILE_EXTENSION,
  REQUIRED_FIELDS_CSV,
  INVALID_CSV_DATA,
  DUPLICATED_VISITED_USER
} from '../../libraries/texts';

const REQUIRED_FIELDS = 'Nº documento, Nombre y RUT Empresa';
const COMPANY_NOT_ASSOCIATED = company =>
  `La compañia ${company} no se encuentra asociada a su usuario.`;
const INVALID_DATA =
  'Nº documento [Sin puntos], Nombre [Sólo letras], RUT Empresa [Sin puntos], Teléfono[ sólo números min 9 max 10], E-mail [usuario@dominio.extensión], Cargo [max 50], Oficina [max 50]';
const PHONE_REGEX = /^[0-9]{9,10}$/;
const VALID_SPACES = /(^(\s)|([ ]{2,}))/gi;
const EMAIL_MIN_LENGTH = 5;
const EMAIL_MAX_LENGTH = 100;
const POSITION_MAX_LENGTH = 50;
const DOT_REGEX = /\./g;
const ALPHA_NUMERIC = /([^a-z0-9\s\-àáâãäåèéêëìíîïòóôõöùúûüñç']|[ ]{2,})/i;
const MAX_NAMES_LENGTH = 100;
const VALID_EXTENSIONS = ['.csv'];
const MAX_FILE_SIZE = 10485760;

const createJSON = array => {
  const [
    prevNumberId,
    prevNames,
    prevSurname,
    prevSecondSurname,
    prevCompany,
    prevBranchOffice,
    prevPhoneNumber,
    prevEmail,
    prevPosition
  ] = array;
  const numberId = getFormatRutNoDots(prevNumberId);
  const names = prevNames
    .trim()
    .replace(VALID_SPACES, ' ')
    .toUpperCase();
  const surname = prevSurname
    .trim()
    .replace(VALID_SPACES, ' ')
    .toUpperCase();
  const secondSurname = prevSecondSurname
    .trim()
    .replace(VALID_SPACES, ' ')
    .toUpperCase();
  const company = getFormatRutNoDots(prevCompany);
  const branchOffice = prevBranchOffice
    .trim()
    .replace(VALID_SPACES, ' ')
    .toUpperCase();
  const phoneNumber = prevPhoneNumber.replace(/\s/gi, '');
  const email = prevEmail.replace(/\s/gi, '');
  const position = prevPosition
    .trim()
    .replace(VALID_SPACES, ' ')
    .toUpperCase();
  return {
    numberId,
    names,
    surname,
    secondSurname,
    company,
    branchOffice,
    phoneNumber,
    email,
    position
  };
};

const readFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(
        reader.result.split('\n').reduce((result, line) => {
          if (line) {
            result.push(createJSON(line.split(',')));
          }
          return result;
        }, [])
      );
    };
    reader.onerror = () => {
      return reject(new Error('Error al leer el archivo.'));
    };
    reader.readAsText(file);
  });
};

const requiredFields = (numberId, names, surname, company) =>
  !!numberId && !!names && !!surname && !!company;

const isUnique = (arr, numberId) => arr.filter(item => item.numberId === numberId);

const validateData = (arr, index, isSupervisor = false, validCompanies = []) => {
  const {
    numberId,
    names,
    surname,
    secondSurname,
    company,
    branchOffice,
    phoneNumber,
    email,
    position
  } = arr[index];
  const errorsRow = new Set();

  if (DOT_REGEX.test(numberId)) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }

  if (names && (ALPHA_NUMERIC.test(names) || names.length > MAX_NAMES_LENGTH)) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (surname && (ALPHA_NUMERIC.test(surname) || surname.length > MAX_NAMES_LENGTH)) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (secondSurname) {
    (ALPHA_NUMERIC.test(secondSurname) || secondSurname.length > MAX_NAMES_LENGTH) &&
      errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (company && !isValidRut(getFormatRut(company))) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (isSupervisor) {
    if (!validCompanies.length || !validCompanies.includes(company)) {
      errorsRow.add(COMPANY_NOT_ASSOCIATED(company));
    }
  }
  if (branchOffice) {
    !validateLatinCharacters(branchOffice) && errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (phoneNumber && !PHONE_REGEX.test(phoneNumber)) {
    errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (email) {
    (!validateEmailCharacters(email) ||
      (email.length < EMAIL_MIN_LENGTH && email.length > EMAIL_MAX_LENGTH)) &&
      errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (position) {
    (!validateLatinCharacters(position) || position.length > POSITION_MAX_LENGTH) &&
      errorsRow.add(INVALID_CSV_DATA(INVALID_DATA));
  }
  if (!requiredFields(numberId, names, surname, company)) {
    errorsRow.add(REQUIRED_FIELDS_CSV(REQUIRED_FIELDS));
  }
  if (isUnique(arr, numberId).length > 1) {
    errorsRow.add(DUPLICATED_VISITED_USER(numberId));
  }
  return Array.from(errorsRow, val => ({ row: `${index + 1}`, message: val }));
};

const validateFile = ({ name, size }, fn) => {
  let isValid = true;
  const fileExtension = name
    .split('.')
    .pop()
    .toLowerCase();

  if (VALID_EXTENSIONS.indexOf(`.${fileExtension}`) === -1) {
    fn(prevError => [...prevError, INVALID_FILE_EXTENSION]);
    isValid = false;
  }

  if (size > MAX_FILE_SIZE) {
    fn(prevError => [...prevError, FILE_SIZE_IS_TOO_BIG]);
    isValid = false;
  }
  return isValid;
};

export { validateData, validateFile, readFile, VALID_EXTENSIONS };
