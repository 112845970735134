import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Visiteds = props => {
  return (
    <SvgIcon viewBox="0 0 432.232 432.232" {...props}>
      <g>
        <path
          d="M412.232,62.764H20c-11.028,0-20,8.972-20,20v266.704c0,11.028,8.972,20,20,20h392.232c11.028,0,20-8.972,20-20V82.764
		C432.232,71.736,423.26,62.764,412.232,62.764z M184.616,284.552l-0.078,30.842H50.981l-0.079-31.023v-24.619
		c0-12.592,13.593-17.375,24.515-21.217c2.571-0.904,5.501-1.936,7.822-2.976c-0.461-0.509-0.93-1.022-1.405-1.543
		c-9.467-10.383-20.193-22.146-20.261-42.901c-0.124-1.572-0.974-12.427-1.273-16.277c-2.846-36.608,15.428-42.238,43.088-50.76
		c4.073-1.255,8.284-2.552,12.798-4.048c5.804-1.923,11.505-2.899,16.944-2.899c11.542,0,21.637,4.317,29.195,12.485
		c9.761,10.549,14.339,26.609,12.893,45.222l-1.273,16.276c-0.068,20.754-10.794,32.518-20.261,42.901
		c-0.476,0.521-0.944,1.036-1.405,1.544c2.321,1.041,5.251,2.072,7.822,2.977c10.922,3.843,24.515,8.625,24.515,21.217
		L184.616,284.552z M380.329,301.792H238.098v-26.627h142.232V301.792z M380.329,240.509H238.098v-26.627h142.232V240.509z
		 M380.329,179.226H238.098v-26.627h142.232V179.226z"
        />
        <path
          d="M117.767,233.237c24.282-0.036,44.049-19.832,44.049-44.129V176.17c0-1.365-0.066-2.744-0.198-4.127h-6.089
		c-10.46,0-14.083-9.981-15.821-16.171c-9.882,4.675-34.575,15.665-65.806,16.154c-0.133,1.389-0.2,2.773-0.2,4.144v12.938
		C73.703,213.405,93.47,233.201,117.767,233.237z"
        />
        <polygon points="117.76,286.564 152.028,250.218 83.492,250.218 	" />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
};

export default Visiteds;
