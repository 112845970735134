import React, { useState, useEffect, useReducer, useContext } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import Card from '@material-ui/core/Card';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FormLabel from '@material-ui/core/FormLabel';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import StepsContext from '../../../contexts/stepsContext';
import GuestService from '../../../services/guest';
import SearchableSelect from '../../../components/fields/searchableSelect';
import CheckboxValidatorElement from '../../../components/fields/checkBox';
import {
  CONTINUE,
  BACK,
  RECEPTION,
  SELECT_GUEST,
  NO_OPTION_LABELS,
  SEARCH_GUEST
} from '../../../libraries/texts';
import { generalClasses as styles } from '../../accesses/styles';
/* import { generalClasses as styles } from '../styles'; */

const initialState = {
  reception: false,
  guest: null
};

const addGuestReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};

const SearchGuest = props => {
  const onlineStatus = useOnlineStatus();
  const stepsContext = useContext(StepsContext);
  const { dispatch: stepsDispatch, beforeStep: data } = stepsContext;
  initialState.reception = data.reception;
  initialState.guest = data.guest;
  const [state, dispatch] = useReducer(addGuestReducer, initialState);
  const [guestList, setGuestLis] = useState([]);
  const [selectError, setSelectError] = useState(false);
  const { reception, guest } = state;
  const { classes, formControlClass = classes.middleWidth } = props;
  const isAvailableNextStep = reception || guest;

  const getRequests = async () => {
    const guests = await GuestService.getAll();
    const newArray = guests.map(item => {
      const { names, surname, secondSurname, companyName } = item;
      const { office: officeNumber = '' } = item.branchOffice ? item.branchOffice : {};
      return {
        ...item,
        value: `${names} ${surname} ${secondSurname} - ${companyName} - ${officeNumber}`,
        label: `${names} ${surname} ${secondSurname} - ${companyName} - ${officeNumber}`
      };
    });

    setGuestLis(newArray);
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContentForm}>
        {
          <React.Fragment>
            <div className={classNames(formControlClass, classes.serialControl)}>
              <div className={classes.backForm}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!onlineStatus}
                  onClick={() => {
                    stepsDispatch({ type: 'back', data });
                  }}
                  className={classes.buttonSearch}
                >
                  <ArrowBack className={classes.rightIcon} />
                  {BACK}
                </Button>
              </div>
              <ValidatorForm
                autoComplete="off"
                className={classes.formStyle}
                onSubmit={() => {
                  data.guest = guest;
                  data.reception = reception;
                  stepsDispatch({ type: 'next', data });
                }}
              >
                <FormLabel component="legend" />

                <CheckboxValidatorElement
                  label={RECEPTION}
                  checked={reception}
                  value={reception}
                  name="reception"
                  onChange={e => {
                    dispatch({
                      [e.target.name]: e.target.checked,
                      guest: e.target.checked ? null : guest
                    });
                  }}
                />

                {!reception ? (
                  <SearchableSelect
                    label={SEARCH_GUEST}
                    value={guest}
                    required={!reception}
                    isDisabled={reception}
                    placeholder={SELECT_GUEST}
                    onBlur={e => {
                      setSelectError(!!e.target.value && !reception);
                    }}
                    hasError={!reception && selectError}
                    options={guestList}
                    noOptionsMessage={() => NO_OPTION_LABELS}
                    onChange={dataGuest => {
                      dispatch({ guest: dataGuest });
                      setSelectError(!!dataGuest && reception);
                    }}
                  />
                ) : null}

                <div className={classes.footerButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!isAvailableNextStep || !onlineStatus}
                    type="submit"
                    className={classes.buttonSearch}
                  >
                    {CONTINUE}
                  </Button>
                </div>
              </ValidatorForm>
            </div>
            <Divider />
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(SearchGuest);
