import VisitReports from '../pages/visits/report';
import { checkAuthorizationView } from '../services/authorization-service';

import VisitReportList from '../pages/visits/report/visitsReport';

export const VISIT_REPORT_LIST_PATH = '/pages/visits/report/visitsReport';

export const VISIT_REPORT_PATHS = [
  {
    path: VISIT_REPORT_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(VisitReports, ['VISIT_REPORT'])
  },
  {
    path: VISIT_REPORT_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(VisitReportList, ['VISIT_REPORT'])
  }
];

export const reportFormRouters = [...VISIT_REPORT_PATHS];
