import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getPassportsValues, PASSPORT_LENGTH } from './utils';

export { cleanScannerText, couldBeScannerInput, isInputEnd } from './utils';

const style = {
  height: 0,
  margin: 0,
  border: 0,
  padding: 0
};

const TIME_FOCUS_LOAD = 150;
const EMPTY_STRING = '';

const setFocus = ref => {
  ref.current && setTimeout(() => ref.current.focus(), TIME_FOCUS_LOAD);
};

const RUNScanner = React.forwardRef((props, ref) => {
  const {
    onChange = () => {},
    isManualInput,
    className,
    id,
    autoComplete,
    autoFocus = true
  } = props;
  const [scannedText, setScannedText] = useState(EMPTY_STRING);

  const handleFocusScan = e => {
    setFocus(isManualInput);
    setScannedText(EMPTY_STRING);
  };

  const handleChangeScan = e => {
    const { value } = e.target;

    setScannedText(value);

    if (value && value.length === PASSPORT_LENGTH) {
      const parseResult = getPassportsValues(value);
      onChange(parseResult || '');
      setScannedText(EMPTY_STRING);
    }
  };

  const handleBlurScan = e => {
    if (!isManualInput) {
      const inner = { current: e.currentTarget };
      setFocus(inner);
    }
  };

  return (
    <input
      id={id}
      className={className}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      ref={ref}
      style={style}
      value={scannedText}
      onChange={e => handleChangeScan(e)}
      onFocus={e => handleFocusScan(e)}
      onBlur={e => handleBlurScan(e)}
    />
  );
});

RUNScanner.propTypes = {
  id: PropTypes.string,
  className: PropTypes.object,
  isManualInput: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

RUNScanner.defaultProps = {
  id: 'RUNScanner',
  autoFocus: true,
  autoComplete: 'off'
};

export default RUNScanner;
