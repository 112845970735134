import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { NO_OPTION_LABELS } from './texts';

export const sliceWord = (word, max) => {
  if (max && word.length > max) {
    return `${word.slice(0, max)} ...`;
  }
  return word;
};

export const getFormatRut = (value, validations = {}) => {
  let rut = value;
  rut = truncateText(rut, validations.maxLength);
  rut = cleanEverythingButNumbers(rut);
  const len = rut.length;

  if (len <= 1) {
    return cleanLetters(rut);
  }

  const dv = rut.slice(len - 1, len);
  let num = rut.slice(0, len - 1);
  num = parseInt(num, 10);
  num = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  return `${num}-${dv}`;
};

export const getFormatRutNoDots = (value, validations = {}) => {
  let rut = value;
  rut = truncateText(rut, validations.maxLength);
  rut = cleanEverythingButNumbers(rut);
  const len = rut.length;

  if (len <= 1) {
    return cleanLetters(rut);
  }

  const dv = rut.slice(len - 1, len);
  let num = rut.slice(0, len - 1);
  num = parseInt(num, 10);

  return `${num}-${dv}`;
};

export const validateOnlyBeforeWhiteSpaces = text => {
  const startWithWhiteSpaceRegex = /(^\s)+/i;

  if (startWithWhiteSpaceRegex.test(text)) {
    return text.replace(startWithWhiteSpaceRegex, '');
  }

  const doubleWhiteSpaceRegex = /([ ]{2,})+/i;

  if (doubleWhiteSpaceRegex.test(text)) {
    return text.replace(doubleWhiteSpaceRegex, ' ');
  }
  return text;
};
export const validatorQuantity = value => {
  const numeric = /([^0-9])+/gim;
  const [first, second] = value;
  if (first < 1 || numeric.test(first)) {
    return '';
  }
  if (!second) {
    return value;
  }
  if (second && first > 1) {
    return first;
  }
  if (second > 0 && first <= 1) {
    return first;
  }
  if (numeric.test(value)) {
    return value.replace(numeric, '');
  }
  return value;
};

export const save = async (id, json, service) => {
  if (id) {
    return service.put(id, json);
  }
  return service.post(json);
};
export const validateLatinCharacters = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([^a-z\s\-àáâãäåèéêëìíîïòóôõöùúûüñç'´]|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(text)) {
    return text.replace(latinAlphaNumeric, '');
  }
  return text;
};

const deleteSpecialToBranchOfficeNameCharacters = text => {
  const regex = /([@=</[\]>/?:;^\\])/gi;
  if (regex.test(text)) {
    return text.replace(regex, '');
  }
  return text;
};
export const validateNameBranchOffice = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([^a-z0-9\s\-àáâãäåèéêëìíîïòóôõöùúûüñç.-_]|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(text)) {
    text = text.replace(latinAlphaNumeric, '');
    return deleteSpecialToBranchOfficeNameCharacters(text);
  }
  return deleteSpecialToBranchOfficeNameCharacters(text);
};

export const validateEmailCharacters = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([^a-z0-9\s\-àáâãäåèéêëìíîïòóôõöùúûüñç'@._]|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(text)) {
    return text.replace(latinAlphaNumeric, '');
  }
  return text;
};

const deleteSpecialToAddressCharacters = text => {
  const regex = /([@=</[\]>/?:;^\\])/gi;
  if (regex.test(text)) {
    return text.replace(regex, '');
  }
  return text;
};
export const validateAddress = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const addressValue = /([^/a-z0-9\s\-àáâãäåèéêëìíîïòóôõöùúûüñ´ç'#.-_,]|[ ]{2,})+/i;
  if (addressValue.test(text)) {
    text = text.replace(addressValue, '');
    return deleteSpecialToAddressCharacters(text);
  }

  return deleteSpecialToAddressCharacters(text);
};

export const validateCodeCompany = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([^a-z0-9-àáâãäåèéêëìíîïòóôõöùúûüñç_-]|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(text)) {
    return text.replace(latinAlphaNumeric, '');
  }
  return text;
};

export const validateFloorOffice = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([^a-z0-9-_\s]|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(text)) {
    return text.replace(latinAlphaNumeric, '');
  }
  return text;
};

export const latinAlphaNumericCharactersAllow = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([^a-z0-9àáâãäåèéêëìíîïòóôõöùúûüñç´'\s]|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(text)) {
    return text.replace(latinAlphaNumeric, '');
  }
  return text;
};
export const validateBussinessName = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([^a-z0-9\s\-àáâãäåèéêëìíîïòóôõöùúûüñç´.-_,&]|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(text)) {
    text = text.replace(latinAlphaNumeric, '');
    return deleteSpecialToAddressCharacters(text);
  }
  return deleteSpecialToAddressCharacters(text);
};

export const validateSpecialCharacters = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([^a-z0-9\s\-àáâãäåèéêëìíîïòóôõöùúûüñç´']|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(text)) {
    return text.replace(latinAlphaNumeric, '');
  }
  return text;
};

export const validatePassword = value => {
  let text = value;
  text = validateOnlyBeforeWhiteSpaces(text);
  const latinAlphaNumeric = /([\s])/i;

  if (latinAlphaNumeric.test(text)) {
    return text.replace(latinAlphaNumeric, '');
  }
  return text;
};
export const getFormatPassport = (value, validations) => {
  const latinAlphaNumeric = /([^a-z0-9-]|[ ]{2,})+/i;

  if (latinAlphaNumeric.test(value)) {
    return value.replace(latinAlphaNumeric, '');
  }
  const truncatedValue = truncateText(value, validations.maxLength);
  return truncatedValue;
};

export const getFormatNumber = (value, validations) => {
  const numeric = /([^0-9+]|[ ]{2,})+/i;

  if (numeric.test(value)) {
    return value.replace(numeric, '');
  }
  const truncatedValue = truncateText(value, validations.maxLength);
  return truncatedValue;
};

export const Option = props => {
  const {
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    children,
    selectProps: { classes }
  } = props;

  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      className={classes.wrap}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        fontSize: '13px',
        whiteSpace: 'pre-wrap'
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
};

export const GuestPlaceholder = props => {
  const { selectProps, innerProps, children } = props;

  return (
    <Typography
      color={selectProps.value && !selectProps.isDisabled ? 'textPrimary' : 'textSecondary'}
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {selectProps.value || children}
    </Typography>
  );
};

export const GuestOption = props => {
  const {
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    data,
    selectProps: { classes }
  } = props;

  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      className={classes.wrap}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
        fontSize: '13px',
        whiteSpace: 'pre-wrap'
      }}
      {...innerProps}
    >
      <div>
        <div>{`${data.name.toUpperCase()} ${data.surname.toUpperCase()}`}</div>
        <div style={{ display: 'inline-block' }}>
          <div>
            {data.company}
            {' - Anexo: '}
            {data.annex}
            {' - Oficina: '}
            {data.office}
          </div>
        </div>
      </div>
    </MenuItem>
  );
};

export const Control = inputComponent => props => {
  const { innerRef, selectProps, innerProps, children } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          inputRef: innerRef,
          children: children,
          ...innerProps
        }
      }}
      {...selectProps.textFieldProps}
    />
  );
};

export const Placeholder = props => {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color={selectProps.value && !selectProps.isDisabled ? 'textPrimary' : 'textSecondary'}
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {selectProps.value || children}
    </Typography>
  );
};

export const NoOptionsMessage = props => {
  const { selectProps, innerProps, text = '' } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {!text ? NO_OPTION_LABELS : text}
    </Typography>
  );
};

function truncateText(text, length) {
  return length && text.length > length ? text.slice(0, length) : text;
}

export const cleanWhiteSpaces = text => {
  const regexWhiteSpaces = /([ ]{1,})/g;
  return regexWhiteSpaces.test(text) ? text.replace(regexWhiteSpaces, '') : text;
};

export const cleanDots = text => text.replace(/\./g, '');

export const cleanDash = text => text.replace(/-/g, '');

export const cleanInitWhiteSpace = text => text.replace(/^(\s*)/, '');

const cleanEverythingButNumbers = text => text.replace(/[^0-9kK]+/g, '');

const cleanLetters = text => text.replace(/[a-z]/gi, '');
