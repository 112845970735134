/* eslint-disable no-underscore-dangle */
import React, { useContext, useReducer, useEffect } from 'react';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import useOnlineStatus from '@rehooks/online-status';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckboxValidatorElement from '../../../components/fields/checkBox';

import {
  getFormatRut,
  cleanInitWhiteSpace,
  validateOnlyBeforeWhiteSpaces
} from '../../../libraries/utils';

import persistenceService from '../../../services/persistence-service';

import VisitsService from '../../../services/visits';
import BlackListService from '../../../services/blackList';
import UserService from '../../../services/user-service';

import StepsContext from '../../../contexts/stepsContext';
import { useToggleNavBar } from '../../../libraries/contexts';

import { generalClasses as styles } from './styles';

import {
  START_DATE,
  FORMAT_DATE,
  FORMAT_HOUR,
  GUEST_VISITED,
  COMPANION,
  QUANTITY,
  LENT_CREDENTIAL,
  CREDENTIAL,
  ACCESS,
  OBSERVATION,
  CLOSE_VISIT,
  ADD_TO_BLACKLIST,
  GUEST_PHONE_ANNEX,
  CLOSE_VISIT_DATE,
  VISITOR,
  BACK,
  RECEPTION,
  CLOSE_VISIT_SUCCESS,
  CLOSE_VISIT_FAIL,
  REPORTED_BY,
  REQUIRED_FIELD,
  MAX_STRING_LENGTH
} from '../../../libraries/texts';

import { RESTRICTION_REASON, NOTIFICATION_EMAIL } from '../../texts';
import { requiredValue } from '../../../libraries/validators';

const FETCH_BLACKLIST_FAIL = 'Error al obtener la lista negra';
const FETCH_ADMIN_USER_FAIL = 'Error al obtener el usuario administrador';
const CLOSE_VISIT_AND_ADD_TO_BL = 'Se ha restringido el ingreso del visitante';

const initialState = {
  observation: '',
  reason: '',
  blackListVisitorExists: false,
  returnCredential: true,
  addToBlackList: false,
  submitDisabled: false,
  closeHour: null
};

const getVisitorData = data => {
  const { visitorName, visitorOrigin, visitorNumberId } = data;
  const numberId =
    data.visitor && data.visitor.documentType === 'RUT'
      ? getFormatRut(visitorNumberId)
      : visitorNumberId;
  return `${visitorName} ${numberId} ${visitorOrigin}`;
};

const getGuestData = data => {
  if (data.guest) {
    const { guestName = '' } = data;
    const { companyName = '', branchOffice } = data.guest;
    const { name: branchOfficeName = '', office = '' } = branchOffice || {};
    return `${guestName} - ${companyName} - ${branchOfficeName} - ${office} `;
  }
  return RECEPTION;
};

const getDateFormat = date => {
  return ` ${moment(date).format(FORMAT_DATE)} ${moment(date).format(FORMAT_HOUR)}`;
};

const hasCredential = credential => {
  return !!(credential && credential.code);
};

const getPhoneData = data => {
  return data.guest ? `${GUEST_PHONE_ANNEX}: ${data.guest.phoneNumber}` : '';
};

const dataReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};

const CloseVisitor = props => {
  const { classes, formControlClass = classes.middleWidth } = props;
  const { role } = persistenceService.getObject('user');
  const { dispatch: stepsDispatch, beforeStep: data, history } = useContext(StepsContext);
  const onlineStatus = useOnlineStatus();
  const [, changeToggleNavBar] = useToggleNavBar();

  const [state, dispatch] = useReducer(dataReducer, initialState);
  const { observation, returnCredential, closeHour } = state;

  const user = UserService.getCurrentUser();

  useEffect(() => {
    async function fetchAdminUser() {
      try {
        const adminUser = await UserService.getAdminUser();
        dispatch({ adminUser });
      } catch (err) {
        console.error('[ fetch admin user ]', err);
        changeToggleNavBar({ message: { message: FETCH_ADMIN_USER_FAIL, open: true } });
      }
    }

    async function fecthBlackListVisitor() {
      try {
        const {
          names,
          surname,
          secondSurname,
          country,
          documentId: numberId,
          _id: visitorId,
          gender,
          documentType: typeId
        } = data.visitor || {};

        const defaultDataBL = {
          reason: '',
          origin: data.visitorOrigin,
          user: null,
          names,
          surname,
          secondSurname,
          country,
          gender,
          numberId,
          visitorId,
          typeId
        };

        const blackListData =
          data.visitor.blackList && (await BlackListService.get(data.visitor.blackList));

        const blackListState = { blackListVisitor: { ...defaultDataBL, ...blackListData } };

        if (blackListData) {
          blackListState.blackListVisitorExists = true;
          blackListState.addToBlackList = true;
        }
        dispatch(blackListState);
      } catch (err) {
        console.error('[ fetch blacklist ]', err);
        changeToggleNavBar({ message: { message: FETCH_BLACKLIST_FAIL, open: true } });
      }
    }

    async function getCurrentTime() {
      const currentDate = await VisitsService.getTime();
      dispatch({ closeHour: currentDate });
    }

    fetchAdminUser();
    fecthBlackListVisitor();
    getCurrentTime();

    ValidatorForm.addValidationRule('requiredValue', requiredValue);

    dispatch({ returnCredential: hasCredential(data.credential) });
  }, [data, changeToggleNavBar]);

  const validatorListener = result => {
    dispatch({ submitDisabled: !result });
  };

  const closeVisit = async () => {
    try {
      const snackMsg = { message: { message: CLOSE_VISIT_SUCCESS, open: true } };
      if (!state.blackListVisitorExists && state.addToBlackList) {
        state.blackListVisitor.emailAdmin = state.adminUser.username;
        await BlackListService.post(state.blackListVisitor);
        snackMsg.message.message = CLOSE_VISIT_AND_ADD_TO_BL;
      }
      changeToggleNavBar(snackMsg);
      state.typeClosed = 'MANUAL';
      await VisitsService.put(data._id, state);
      history.push('/visits/close');
    } catch (err) {
      changeToggleNavBar({ message: { message: CLOSE_VISIT_FAIL, open: true } });
    }
    stepsDispatch({ type: 'back' });
  };

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContentForm}>
        {
          <React.Fragment>
            <div className={classNames(formControlClass, classes.serialControl)}>
              <div className={classes.backForm}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => stepsDispatch({ type: 'back', data })}
                  className={classes.buttonSearch}
                  disabled={!onlineStatus}
                >
                  <ArrowBack className={classes.rightIcon} />
                  {BACK}
                </Button>
              </div>
              <ValidatorForm
                autoComplete="off"
                className={(classes.formStyle, classes.formCloseVisit)}
                onSubmit={closeVisit}
              >
                <div className={classNames(classes.flex, classes.alignCenter, classes.startDate)}>
                  <InputLabel>
                    {CLOSE_VISIT_DATE}
                    {closeHour ? getDateFormat(closeHour) : ''}
                  </InputLabel>
                </div>
                <div className={classes.labelTitle}>
                  <InputLabel>{VISITOR}</InputLabel>
                </div>
                <InputLabel className={classes.line}>{getVisitorData(data)}</InputLabel>
                <InputLabel>
                  {START_DATE}
                  {getDateFormat(data.createdAt)}
                </InputLabel>
                <div className={classes.labelTitle}>
                  <InputLabel>{GUEST_VISITED}</InputLabel>
                </div>
                <InputLabel className={classes.line}>{getGuestData(data)}</InputLabel>
                <InputLabel className={classes.line}>{getPhoneData(data)}</InputLabel>

                <div className={classNames(classes.companionCloseForm)}>
                  <CheckboxValidatorElement
                    label={COMPANION}
                    className={classes.hasCompanion}
                    name="hasCompanion"
                    checked={!!parseInt(data.quantity, 10)}
                    disabled
                  />
                  <TextValidator
                    id="quantity"
                    name="quantity"
                    label={QUANTITY}
                    type="text"
                    autoComplete="off"
                    className={classNames(classes.quantityCompanion)}
                    margin="normal"
                    disabled
                    value={data.quantity}
                  />
                </div>

                <CheckboxValidatorElement
                  label={LENT_CREDENTIAL}
                  name="returnCredential"
                  checked={returnCredential}
                  disabled={!hasCredential(data.credential)}
                  onChange={e => {
                    dispatch({ returnCredential: e.target.checked });
                  }}
                />

                <TextValidator
                  label={CREDENTIAL}
                  type="text"
                  autoComplete="off"
                  margin="normal"
                  disabled
                  value={data.credential && data.credential.code ? data.credential.code : ''}
                  className={classNames(classes.textField, classes.textFieldMultiLine)}
                />

                <TextValidator
                  label={ACCESS}
                  type="text"
                  autoComplete="off"
                  margin="normal"
                  disabled
                  value={(data.access && data.access.description) || ''}
                  className={classes.textField}
                />

                <CheckboxValidatorElement
                  disabled={!!state.blackListVisitorExists || role === 'USER'}
                  checked={state.addToBlackList}
                  label={ADD_TO_BLACKLIST}
                  name="addToBlackList"
                  className={classes.btnBlackList}
                  onChange={e => {
                    dispatch({
                      addToBlackList: e.target.checked,
                      submitDisabled: e.target.checked,
                      blackListVisitor: { ...state.blackListVisitor, reason: '' }
                    });
                  }}
                />

                {state.addToBlackList && (
                  <>
                    <InputLabel>
                      {REPORTED_BY}:{user && ` ${user.name} ${user.surname}`}
                    </InputLabel>
                    <InputLabel>
                      {NOTIFICATION_EMAIL}: {state.adminUser && state.adminUser.username}
                    </InputLabel>
                    <TextValidator
                      required
                      disabled={!!state.blackListVisitorExists}
                      id="reason"
                      name="reason"
                      label={RESTRICTION_REASON}
                      className={classNames(classes.textField, classes.reason)}
                      margin="normal"
                      validators={['requiredValue', 'maxStringLength:500']}
                      errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
                      inputProps={{ maxLength: 500 }}
                      multiline
                      autoComplete="off"
                      value={state.blackListVisitor.reason}
                      validatorListener={validatorListener}
                      onChange={e => {
                        const value = validateOnlyBeforeWhiteSpaces(e.target.value);
                        dispatch({
                          blackListVisitor: { ...state.blackListVisitor, reason: value }
                        });
                      }}
                    />
                  </>
                )}

                <TextValidator
                  id="observation"
                  name="observation"
                  label={OBSERVATION}
                  className={classNames(classes.textField, classes.reason)}
                  margin="normal"
                  inputProps={{ maxLength: 200 }}
                  multiline
                  autoComplete="off"
                  value={observation}
                  onChange={e => {
                    const value = cleanInitWhiteSpace(e.target.value);
                    dispatch({ observation: value });
                  }}
                />
                <div className={classes.footerButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={state.submitDisabled || !onlineStatus}
                    type="submit"
                    className={classes.buttonSearch}
                  >
                    {CLOSE_VISIT}
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(CloseVisitor);
