import React, { useEffect, useReducer } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import SearchableSelect from '../../../components/fields/searchableSelect';
import ConfigurationService from '../../../services/configuration';

import { generalClasses as styles } from './styles';
import {
  HOUR,
  MINUTE,
  SAVE,
  GUESTS_UPDATE_SAVED,
  GUESTS_UPDATE_FAIL,
  FETCH_GUESTS_UPDATE_FAIL
} from './texts';
import CheckboxValidatorElement from '../../../components/fields/checkBox';
import { useToggleNavBar } from '../../../libraries/contexts';

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const HOUR_DIGITS = 2;
const MINUTE_DIGITS = 2;

const initialState = {
  deactivate: false,
  minute: { label: '00', value: 0 },
  minuteInput: '',
  hour: { label: '00', value: 0 },
  hourInput: ''
};

const guestsUpdateReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};

const pad = (num, size) => {
  const s = `00${num}`;
  return s.substr(s.length - size);
};

const generateNumberOptions = (optionNumber, digitsNum) => {
  return Array.from(Array(optionNumber), (_, x) => {
    const val = pad(x, digitsNum);
    return { label: val, value: val };
  });
};

const getValidatedNumberInput = (number, min, max, digitsNum) => {
  let val = number.replace(/[^0-9]/g, '').substring(0, digitsNum);
  const valInt = parseInt(val, 10);
  val = (valInt > max && pad(max, digitsNum)) || (valInt < min && pad(min, digitsNum)) || val;
  return val;
};

const getValidatedNumberOption = (inputNumber, selectedOption, digitsNum) => {
  if (!inputNumber && selectedOption) {
    return selectedOption;
  }
  const val =
    (inputNumber && pad(parseInt(inputNumber, 10), digitsNum)) || pad(parseInt(0, 10), digitsNum);
  return { label: val, value: val };
};

const GuestsUpdate = props => {
  const [state, dispatch] = useReducer(guestsUpdateReducer, initialState);
  const onlineStatus = useOnlineStatus();
  const { deactivate, hour, minute, hourInput, minuteInput } = state;

  const { classes, formControlClass = classes.middleWidth, history } = props;

  const [, changeToggleNavBar] = useToggleNavBar();

  const submitForm = async () => {
    try {
      await ConfigurationService.setGuestsUpdate({
        active: !deactivate,
        minute: minute.value,
        hour: hour.value
      });
      changeToggleNavBar({ message: { message: GUESTS_UPDATE_SAVED, open: true } });
      return history.push('/');
    } catch (err) {
      return changeToggleNavBar({ message: { message: GUESTS_UPDATE_FAIL, open: true } });
    }
  };

  useEffect(() => {
    async function fecthGuestsUpdate() {
      try {
        const data = await ConfigurationService.getGuestsUpdate();
        if (data) {
          dispatch({
            deactivate: !data.active,
            hour: { label: pad(data.hour, HOUR_DIGITS), value: pad(data.hour, HOUR_DIGITS) },
            minute: {
              label: pad(data.minute, MINUTE_DIGITS),
              value: pad(data.minute, MINUTE_DIGITS)
            }
          });
        }
      } catch (err) {
        changeToggleNavBar({ message: { message: FETCH_GUESTS_UPDATE_FAIL, open: true } });
      }
    }
    fecthGuestsUpdate();
  }, [changeToggleNavBar]);

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContentForm}>
        {
          <React.Fragment>
            <div className={classNames(formControlClass, classes.serialControl)}>
              <ValidatorForm
                autoComplete="off"
                className={classes.formVerify}
                onSubmit={submitForm}
              >
                <div className={classNames(classes.middleWidth, classes.alignContainerCenter)}>
                  <div className={classes.flex}>
                    <SearchableSelect
                      className={classes.timeField}
                      isClearable={false}
                      clearIndicator
                      label={HOUR}
                      value={hour}
                      inputValue={hourInput}
                      required
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      onBlur={e => {
                        dispatch({
                          hour: getValidatedNumberOption(hourInput, hour, HOUR_DIGITS)
                        });
                      }}
                      onInputChange={e => {
                        dispatch({
                          hourInput: getValidatedNumberInput(e, 0, HOURS_IN_DAY - 1, HOUR_DIGITS)
                        });
                      }}
                      onChange={e => {
                        dispatch({ hour: e });
                      }}
                      options={generateNumberOptions(HOURS_IN_DAY, HOUR_DIGITS)}
                    />
                    <SearchableSelect
                      className={classes.timeField}
                      isClearable={false}
                      label={MINUTE}
                      value={minute}
                      inputValue={minuteInput}
                      required
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      onBlur={e => {
                        dispatch({
                          minute: getValidatedNumberOption(minuteInput, minute, MINUTE_DIGITS)
                        });
                      }}
                      onInputChange={e => {
                        dispatch({
                          minuteInput: getValidatedNumberInput(
                            e,
                            0,
                            MINUTES_IN_HOUR - 1,
                            MINUTE_DIGITS
                          )
                        });
                      }}
                      onChange={e => {
                        dispatch({ minute: e });
                      }}
                      options={generateNumberOptions(MINUTES_IN_HOUR, MINUTE_DIGITS)}
                    />
                  </div>
                  <div className={classNames(classes.flex)}>
                    <CheckboxValidatorElement
                      checked={deactivate}
                      label="Desactivar"
                      name="addToBlackList"
                      className={classes.btnBlackList}
                      onChange={e => {
                        dispatch({ deactivate: !deactivate });
                      }}
                    />
                  </div>
                </div>
                <div className={classes.footerButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonSearch}
                    type="submit"
                    disabled={!onlineStatus}
                  >
                    {SAVE}
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(GuestsUpdate);
