/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useContext, useReducer } from 'react';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { Table, Typography } from '@material-ui/core';
import useOnlineStatus from '@rehooks/online-status';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import VirtualizedTable from '../../../components/table';
import DateTimePicker from '../../../components/fields/dateTimePicker';
import VisitsService from '../../../services/visits';
import { cleanDots, cleanDash, getFormatRut } from '../../../libraries/utils';
import StepsContext from '../../../contexts/stepsContext';
import SearchableSelect from '../../../components/fields/searchableSelect';
import ExportButton from '../../../components/export';

import {
  VISIT_REPORT_PAGE,
  SEARCH,
  NO_DATA,
  DATE_TIME_FORMAT,
  LOADING,
  RECEPTION,
  DATE_FROM,
  DATE_TO,
  DATE_RANGE_VALIDATION_MSG
} from '../../../libraries/texts';

import { generalClasses as styles } from './styles';

const TEXT = VISIT_REPORT_PAGE;

const errorMsgStyle = {
  color: '#f44336',
  fontSize: '0.75rem',
  margin: '8px 20px 0'
};

const columns = [
  {
    minWidth: 100,
    label: TEXT.TABLE_HEAD_ID,
    dataKey: 'numberId',
    cellDataGetter: ({ columnData, dataKey, rowData }) => rowData[dataKey].toUpperCase()
  },
  {
    minWidth: 200,
    label: TEXT.TABLE_HEAD_VISITOR_NAME,
    dataKey: 'visitor'
  },
  {
    minWidth: 150,
    label: TEXT.TABLE_HEAD_GUEST_NAME,
    dataKey: 'guest'
  },
  {
    minWidth: 100,
    label: TEXT.TABLE_HEAD_PATENTE,
    dataKey: 'patente'
  },
  {
    minWidth: 100,
    label: TEXT.TABLE_HEAD_VISITOR_IN,
    dataKey: 'manual',
    cellDataGetter: ({ columnData, dataKey, rowData }) =>
      rowData[dataKey] === 'true' ? 'MANUAL' : 'SCANER'
  },
  {
    minWidth: 100,
    label: TEXT.TABLE_HEAD_DATE_TIME,
    dataKey: 'date'
  },
  {
    minWidth: 100,
    label: TEXT.TABLE_HEAD_TYPE_CLOSED,
    dataKey: 'typeClosed'
  },
  {
    minWidth: 120,
    label: TEXT.TABLE_HEAD_CREDENTIAL,
    dataKey: 'credential',
    cellDataGetter: ({ columnData, dataKey, rowData }) =>
      rowData[dataKey] ? rowData[dataKey].code : 'Sin tarjeta'
  }
];

const columnsForCsv = {
  visitorNumberId: TEXT.TABLE_HEAD_ID,
  visitorName: TEXT.TABLE_HEAD_VISITOR_NAME,
  guestName: TEXT.TABLE_HEAD_GUEST_NAME,
  companyName: TEXT.TABLE_HEAD_COMPANY_NAME,
  createdAt: TEXT.TABLE_HEAD_DATE_TIME,
  typeClosed: TEXT.TABLE_HEAD_TYPE_CLOSED,
  credential: TEXT.TABLE_HEAD_CREDENTIAL,
  sello: TEXT.TABLE_HEAD_SELLO,
  patente: TEXT.TABLE_HEAD_PATENTE,
  guia: TEXT.TABLE_HEAD_GUIA,
  factura: TEXT.TABLE_HEAD_FACTURA,
  manual: TEXT.TABLE_HEAD_MANUAL,
  user: TEXT.TABLE_HEAD_USER,
  access: TEXT.TABLE_HEAD_ACCESS
};

const setRowsReducer = (prevRows, { newRows = [], type }) => {
  switch (type) {
    case 'add':
      return [...prevRows, ...newRows];
    case 'set':
      return newRows;
    default:
      return prevRows;
  }
};

const cleanText = value => {
  let text = cleanDots(value);
  text = cleanDash(text);
  return text;
};

const getGuestInfo = data => {
  if (data.guest) {
    const { guestName = '' } = data;
    const { companyName = '', branchOffice = {} } = data.guest;
    const { name: branchOfficeName = '', office = '' } = branchOffice || {};
    /* return `${guestName} - ${companyName} - ${branchOfficeName} - ${office} `; */
    return `${guestName} - ${companyName} `;
  }
  return RECEPTION;
};

const getFormatDocumentid = data => {
  const { visitorNumberId } = data;
  const documentId =
    (data.visitor && data.visitor.documentType === 'RUT') || data.employee
      ? getFormatRut(visitorNumberId)
      : visitorNumberId;
  return documentId;
};

const getFormatedDate = data => {
  const { createdAt } = data;
  const date = moment(createdAt).format(DATE_TIME_FORMAT);
  return date;
};

const FormSearcher = props => {
  const { classes, formControlClass = classes.middleWidth } = props;

  const steps = useContext(StepsContext);
  const { dispatch: stepsDispatch } = steps;
  const today = new Date();

  const onlineStatus = useOnlineStatus();
  const [rows, setRows] = useReducer(setRowsReducer, []);

  const [pageCountTable, setPageCountTable] = useState(0);
  const [hasNextTable, setHasNextTable] = useState(false);
  const [hasPreviousTable, setHasPreviousTable] = useState(false);
  const [currentPageTable, setCurrentPageTable] = useState(0);
  const [pageSizeTable, setPageSizeTable] = useState(0);
  const [countTable, setCountTable] = useState(0);

  const [pageStatus, setPageStatus] = useState({
    currentPage: 1,
    hasNext: true,
    total: 0
  });
  const [searchText, setSearchText] = useState({
    curr: '',
    last: ''
  });
  const [filteringByDate, setFilteringByDate] = useState(true);
  const [dateRange, setDateRange] = useState({
    from: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
    to: new Date()
  });
  const [dateRangeErr, setDateRangeErr] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [typeClosed, setTypeClosed] = useState();

  const getRequests = async (
    filterData = '',
    from,
    to,
    dateFiltering,
    typeClosedSelected,
    page = 1
  ) => {
    const type = page <= 1 ? 'set' : 'add';
    const visitsList = await VisitsService.getAllVisitsPaginated({
      filterData,
      page,
      order: '-createdAt',
      from,
      to,
      dateFiltering,
      typeClosed: typeClosedSelected
    });

    setCountTable(visitsList.count);
    setPageSizeTable(visitsList.pageSize);
    setCurrentPageTable(visitsList.currentPage);
    setPageCountTable(visitsList.pageCount);
    setPageCountTable(visitsList.pageCount);
    setHasPreviousTable(visitsList.hasPrevious);
    setHasNextTable(visitsList.hasNext);

    const { results, currentPage, hasNext, count: total } = visitsList;

    const newRows = results.map(item => {
      const { visitorName: visitor, credential, manual, patente } = item;
      const numberId = getFormatDocumentid(item);
      const date = getFormatedDate(item);
      const typeClosedItem = item.typeClosed;
      return {
        numberId,
        visitor,
        credential,
        guest: getGuestInfo(item),
        date,
        data: item,
        typeClosed: typeClosedItem,
        manual,
        patente
      };
    });
    setRows({ newRows, type });
    setPageStatus({ currentPage, hasNext, total });
    setIsLoading(false);
  };

  useEffect(() => {
    const todayDt = new Date();
    getRequests(
      '',
      new Date(todayDt.getFullYear(), todayDt.getMonth(), todayDt.getDate(), 0, 0, 0),
      new Date(),
      true
    );
  }, []);

  const handleSearch = e => {
    const { curr } = searchText;
    const { from = '', to = '' } = filteringByDate ? dateRange : {};
    if (filteringByDate && !dateRangeErr) {
      const text = cleanText(curr);
      setIsLoading(true);
      setSearchText({ ...searchText, last: curr });
      getRequests(text, from, to, filteringByDate, (typeClosed && typeClosed.value) || null);
    }
  };

  useEffect(() => {
    const dateRangeHasError = () => {
      const { from, to } = dateRange;
      const dateDifference = moment(to).diff(moment(from));
      setDateRangeErr(dateDifference < 0);
      setFilteringByDate(dateDifference >= 0);
    };
    dateRangeHasError();
  }, [dateRange]);

  const handleChange = e => {
    const text = cleanDots(e.target.value);
    setSearchText({ ...searchText, curr: text });
  };

  const isRowLoaded = hasItems => {
    if (!hasItems && pageStatus.hasNext) {
      setPageStatus({ ...pageStatus, hasNext: false });
      return false;
    }
    return true;
  };

  const loadMoreRows = e => {
    const pageToRequest = pageStatus.currentPage + 1;
    const text = cleanText(searchText.curr);
    const { from = '', to = '' } = filteringByDate ? dateRange : {};
    getRequests(
      text,
      from,
      to,
      filteringByDate,
      (typeClosed && typeClosed.value) || null,
      pageToRequest
    );
  };

  const handleOnClickRow = e => {
    if (!onlineStatus) return;
    const {
      rowData: { data }
    } = e;
    stepsDispatch({ type: 'next', data });
  };

  const rederPager = () => {
    return pageCountTable > 1 && hasNextTable ? (
      <button
        style={{ margin: '10px 0px', float: 'right', fontSize: '0.9em' }}
        type="button"
        onClick={e => loadMoreRows(e)}
      >
        Ver más resultados
      </button>
    ) : (
      ''
    );
  };

  const renderTable = () => {
    return (
      <Paper>
        <div className="tableWrapper">
          {rederPager()}
          <table className="table">
            <thead>
              <tr>
                {columns.map(column => (
                  <th key={column.label}>{column.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr
                  key={i}
                  onClick={() => {
                    stepsDispatch({ type: 'next', data: row.data });
                  }}
                >
                  <td>{row.numberId ? getFormatRut(row.numberId) : ''} </td>
                  <td>{row.visitor || ''}</td>
                  <td>{row.guest}</td>
                  <td>{row.patente}</td>
                  <td>{row.manual == 'true' ? 'MANUAL' : 'SCANER'}</td>
                  <td>{row.date}</td>
                  <td>{row.typeClosed}</td>
                  <td>{row.credential ? row.credential.code : 'sin tarjeta'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <span style={{ fontSize: '0.9em', padding: '10px', display: 'block' }}>
            {`Mostrando ${rows.length} de un total de ${countTable} resultados`}
          </span>
        </div>
      </Paper>
    );
  };

  const renderMessage = () => {
    return (
      <Typography variant="h5" component="h3" className={classes.tableNoData}>
        {isLoading ? LOADING : NO_DATA}
      </Typography>
    );
  };

  const handleCSV = async () => {
    const typeClosedValue = (typeClosed && typeClosed.value) || null;
    const { curr } = searchText;
    const { from = '', to = '' } = filteringByDate ? dateRange : {};
    if (filteringByDate && !dateRangeErr) {
      const filter = cleanText(curr);
      setIsLoading(true);
      await VisitsService.downloadCSV({
        filter,
        from,
        to,
        typeClosed: typeClosedValue,
        select: Object.keys(columnsForCsv)
      });
      setIsLoading(false);
    }
  };

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContent}>
        {
          <React.Fragment>
            <div className={classes.fullWidth}>
              <div className={classes.middleWidthContainer}>
                <DateTimePicker
                  value={dateRange.from}
                  minDate={moment()
                    .subtract(36, 'months')
                    .format()}
                  maxDate={Date.now()}
                  label={DATE_FROM}
                  onChange={date => {
                    setDateRange({
                      ...dateRange,
                      from: date.format()
                    });
                    setFilteringByDate(true);
                  }}
                  format={DATE_TIME_FORMAT}
                  ampm={false}
                  variant="outlined"
                />
                <DateTimePicker
                  value={dateRange.to}
                  minDate={moment()
                    .subtract(12, 'months')
                    .format()}
                  maxDate={Date.now()}
                  label={DATE_TO}
                  onChange={date => {
                    setDateRange({ ...dateRange, to: date.format() });
                    setFilteringByDate(true);
                  }}
                  format={DATE_TIME_FORMAT}
                  ampm={false}
                  variant="outlined"
                />
              </div>
              <div className={classes.middleWidthContainer}>
                <div className={classes.fullWidth}>
                  <SearchableSelect
                    label="Tipo de cierre"
                    clearIndicator
                    value={typeClosed}
                    required
                    onBlur={e => {}}
                    onInputChange={e => {}}
                    onChange={e => {
                      setTypeClosed(e);
                    }}
                    options={[
                      { value: 'MANUAL', label: 'MANUAL' },
                      { value: 'AUTO', label: 'AUTOMÁTICO' }
                    ]}
                  />
                </div>
              </div>
              <div style={errorMsgStyle}>{dateRangeErr ? DATE_RANGE_VALIDATION_MSG : null}</div>
            </div>
            <div
              className={classNames(
                formControlClass,
                classes.tableMiddleWidth,
                classes.serialControl
              )}
            >
              <ValidatorForm
                className={classes.tableFormSearch}
                onSubmit={async e => {
                  handleSearch(e);
                }}
              >
                <TextValidator
                  id="names"
                  name="names"
                  value={searchText.curr}
                  label={TEXT.SEARCH_TEXT}
                  className={classes.textField}
                  margin="normal"
                  disabled={!onlineStatus}
                  onChange={e => handleChange(e)}
                  autoComplete="off"
                  type="search"
                />
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.buttonSearch}
                  disabled={!onlineStatus}
                  type="submit"
                >
                  {SEARCH}
                </Button>
                <div className={classes.secondFunctionalities}>
                  <ExportButton errorMessage={[]} onClick={handleCSV} disabled={!onlineStatus} />
                </div>
              </ValidatorForm>
            </div>
            <div className={classNames(classes.fullWidth)}>
              {rows.length ? renderTable() : renderMessage()}
            </div>
            {/* <div className={classes.tableContainer}>
            </div> */}
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(FormSearcher);
