/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import classNames from 'classnames';
import { Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Router from '../../routers';
import { isLogged } from '../../services/authorization-service';

import { content as styles } from './css';
import { useToggleNavBar } from '../../libraries/contexts';

const Content = props => {
  const detectMobile = useMobileDetect();
  const {
    classes: {
      content,
      drawerOpen,
      drawerClose,
      logoutBar,
      drawerOpenAndSecondary,
      drawerCloseAndSecondary
    }
  } = props;

  const [{ main = false, secondary }, setToggleValue] = useToggleNavBar();
  const isOut = !isLogged();

  return (
    <div
      role="contentinfo"
      onClick={() => {
        if (!detectMobile.isDesktop()) {
          return setToggleValue({ main, secondary: false });
        }
        return setToggleValue({ main, secondary });
      }}
    >
      <main
        className={classNames(content, {
          [drawerOpen]: main,
          [drawerClose]: !main,
          [drawerOpenAndSecondary]: main && secondary,
          [drawerCloseAndSecondary]: !main && secondary,
          [logoutBar]: isOut
        })}
      >
        <Switch>
          <Router />
        </Switch>
      </main>
    </div>
  );
};

Content.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Content);
