import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import DailyClosing from './daily-closing';
import { generalClasses as styles } from './styles';

import { DAILY_CLOSING_TITLE } from './texts';

const List = props => {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title={DAILY_CLOSING_TITLE} />
      <CardContent className={classes.cardContent}>
        <DailyClosing {...props} />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(List);
