import React from 'react';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import Select from './simpleSelect';

const red300 = red['500'];

const style = {
  fontSize: '12px',
  color: red300,
  marginTop: '8px'
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '1rem 0rem 1rem 0rem',
    '& div': {
      width: '100%'
    }
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  chip: {
    margin: '0.5 0.25'
  },
  chipFocused: {
    backgroundColor: red
  },
  noOptionsMessage: {
    padding: '1 2'
  },
  singleValue: {
    fontSize: '16s'
  },
  placeholder: {
    position: 'absolute',
    left: 10,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '1p',
    left: 0,
    right: 0
  },
  divider: {
    height: '2px'
  }
});

class SimpleSelectValidatorElement extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      disabled,
      required,
      options,
      classes,
      label,
      id,
      className,
      TextFieldProps,

      ...rest
    } = this.props;

    return (
      <div className={classes.root}>
        <Select
          inputId={id}
          className={className}
          required={required}
          label={label}
          value={value}
          disabled={disabled}
          options={options}
          {...rest}
        />

        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <div style={style}>{this.getErrorMessage()}</div>;
  }
}

export default withStyles(styles)(SimpleSelectValidatorElement);
