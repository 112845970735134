/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useReducer, useEffect } from 'react';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import InputLabel from '@material-ui/core/InputLabel';
import useOnlineStatus from '@rehooks/online-status';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckboxValidatorElement from '../../../components/fields/checkBox';
import {
  getFormatRut,
  cleanInitWhiteSpace,
  validateOnlyBeforeWhiteSpaces
} from '../../../libraries/utils';

import BlackListService from '../../../services/blackList';

import StepsContext from '../../../contexts/stepsContext';
import { useToggleNavBar } from '../../../libraries/contexts';

import { generalClasses as styles } from '../styles';

import {
  START_DATE,
  FORMAT_DATE,
  FORMAT_HOUR,
  GUEST_VISITED,
  COMPANION,
  QUANTITY,
  LENT_CREDENTIAL,
  CREDENTIAL,
  ACCESS,
  OBSERVATION,
  GUEST_PHONE_ANNEX,
  CLOSE_VISIT_DATE,
  VISITOR,
  BACK,
  RECEPTION,
  TYPE_CLOSE,
  TYPES_OF_CLOSED,
  REQUIRED_FIELD,
  MAX_STRING_LENGTH
} from '../../../libraries/texts';

import { requiredValue } from '../../../libraries/validators';
import persistenceService from '../../../services/persistence-service';

const FETCH_BLACKLIST_FAIL = 'Error al obtener la lista negra';

let closeHour = moment();

const initialState = {
  observation: '',
  reason: '',
  blackListVisitorExists: false,
  returnCredential: true,
  addToBlackList: false,
  submitDisabled: false
};

const getVisitorData = data => {
  const { visitorName, visitorOrigin, visitorNumberId } = data;
  const numberId =
    data.visitor && data.visitor.documentType === 'RUT'
      ? getFormatRut(visitorNumberId)
      : visitorNumberId;
  return `${visitorName} ${numberId} ${visitorOrigin}`;
};

const getGuestData = data => {
  if (data.guest) {
    const { guestName = '' } = data;
    const { companyName = '', branchOffice = {} } = data.guest;
    const { name: branchOfficeName = '', office = '' } = branchOffice || {};
    return `${guestName} - ${companyName} - ${branchOfficeName} - ${office} `;
  }
  return RECEPTION;
};

const getDateFormat = date => {
  return ` ${moment(date).format(FORMAT_DATE)} ${moment(date).format(FORMAT_HOUR)}`;
};

const hasCredential = credential => {
  return !!(credential && credential.code);
};

const getPhoneData = data => {
  return data.guest ? `${GUEST_PHONE_ANNEX}: ${data.guest.phoneNumber}` : '';
};

const getTypeOfClose = ({ typeClosed }) => {
  return typeClosed ? TYPES_OF_CLOSED[typeClosed] : '';
};

const dataReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};

const canSend = (...props) => props.find(v => !!v === false) === undefined;

const CloseVisitor = props => {
  const { classes, formControlClass = classes.middleWidth } = props;

  const { dispatch: stepsDispatch, beforeStep: data, history } = useContext(StepsContext);

  const onlineStatus = useOnlineStatus();
  const [, changeToggleNavBar] = useToggleNavBar();

  const [checkboxBlacklist, setCheckboxBlacklist] = useState(false);
  const [reason, setReason] = useState(null);

  const [state, dispatch] = useReducer(dataReducer, initialState);
  const { observation, returnCredential } = data;
  const { role } = persistenceService.getObject('user');
  const handleCheckbox = e => {
    setCheckboxBlacklist(e.target.checked);
    !e.target.checked && setReason(null);
  };

  useEffect(() => {
    async function fecthBlackListVisitor() {
      try {
        const {
          names,
          surname,
          secondSurname,
          country,
          documentId: numberId,
          _id: visitorId,
          gender,
          documentType: typeId
        } = data.visitor || {};
        const defaultDataBL = {
          reason: '',
          origin: data.visitorOrigin,
          emailAdmin: 'admin@admin.io',
          user: null,
          names,
          surname,
          secondSurname,
          country,
          gender,
          numberId,
          visitorId,
          typeId
        };
        const blackListData =
          data.visitor.blackList && (await BlackListService.get(data.visitor.blackList));
        const blackListState = { blackListVisitor: { ...defaultDataBL, ...blackListData } };
        if (blackListData) {
          blackListState.blackListVisitorExists = true;
          blackListState.addToBlackList = true;
        }
        dispatch(blackListState);
      } catch (err) {
        changeToggleNavBar({ message: { message: FETCH_BLACKLIST_FAIL, open: true } });
      }
    }
    fecthBlackListVisitor();
    ValidatorForm.addValidationRule('requiredValue', requiredValue);
    closeHour = moment();
    dispatch({ returnCredential: hasCredential(data.credential) });
  }, [data, changeToggleNavBar]);

  async function removeFromBlacklist() {
    try {
      const { blackListVisitor } = state;
      if (canSend(checkboxBlacklist, reason) && blackListVisitor) {
        await BlackListService.deleteBlacklistRecord(blackListVisitor._id, reason);

        changeToggleNavBar({
          message: { message: 'El visitante ha salido de Lista Negra', open: true }
        });
        history.push('/visits/close');
      }
    } catch (error) {
      changeToggleNavBar({
        message: { message: 'Error al eliminar el usuario de la lista negra', open: true }
      });
    }
    return false;
  }

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContentForm}>
        {
          <React.Fragment>
            <div className={classNames(formControlClass, classes.serialControl)}>
              <div className={classes.backForm}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => stepsDispatch({ type: 'back', data })}
                  className={classes.buttonSearch}
                  disabled={!onlineStatus}
                >
                  <ArrowBack className={classes.rightIcon} />
                  {BACK}
                </Button>
              </div>
              <ValidatorForm
                autoComplete="off"
                className={(classes.formStyle, classes.formCloseVisit)}
                onSubmit={removeFromBlacklist}
              >
                <div className={classNames(classes.flex, classes.alignCenter, classes.startDate)}>
                  <InputLabel>
                    {CLOSE_VISIT_DATE}
                    {getDateFormat(closeHour)}
                  </InputLabel>
                </div>
                <div className={classes.labelTitle}>
                  <InputLabel>{TYPE_CLOSE}</InputLabel>
                </div>
                <InputLabel className={classes.line}>{getTypeOfClose(data)}</InputLabel>
                <div className={classes.labelTitle}>
                  <InputLabel>{VISITOR}</InputLabel>
                </div>
                <InputLabel className={classes.line}>{getVisitorData(data)}</InputLabel>
                <InputLabel>
                  {START_DATE}
                  {getDateFormat(data.createdAt)}
                </InputLabel>
                <div className={classes.labelTitle}>
                  <InputLabel>{GUEST_VISITED}</InputLabel>
                </div>
                <InputLabel className={classes.line}>{getGuestData(data)}</InputLabel>
                <InputLabel className={classes.line}>{getPhoneData(data)}</InputLabel>

                <div className={classNames(classes.companionCloseForm)}>
                  <CheckboxValidatorElement
                    label={COMPANION}
                    className={classes.hasCompanion}
                    name="hasCompanion"
                    checked={!!parseInt(data.quantity, 10)}
                    disabled
                  />
                  <TextValidator
                    id="quantity"
                    name="quantity"
                    label={QUANTITY}
                    type="text"
                    autoComplete="off"
                    className={classNames(classes.quantityCompanion)}
                    margin="normal"
                    disabled
                    value={data.quantity}
                  />
                </div>

                <CheckboxValidatorElement
                  label={LENT_CREDENTIAL}
                  name="returnCredential"
                  checked={returnCredential}
                  disabled
                  onChange={e => {
                    dispatch({ returnCredential: e.target.checked });
                  }}
                />

                <TextValidator
                  label={CREDENTIAL}
                  type="text"
                  autoComplete="off"
                  margin="normal"
                  disabled
                  value={data.credential && data.credential.code ? data.credential.code : ''}
                  className={classNames(classes.textField, classes.textFieldMultiLine)}
                />

                <TextValidator
                  label={ACCESS}
                  type="text"
                  autoComplete="off"
                  margin="normal"
                  disabled
                  value={(data.access && data.access.description) || ''}
                  className={classes.textField}
                />

                <TextValidator
                  disabled
                  id="observation"
                  name="observation"
                  label={OBSERVATION}
                  className={classNames(classes.textField, classes.reason)}
                  margin="normal"
                  inputProps={{ maxLength: 200 }}
                  multiline
                  autoComplete="off"
                  value={observation}
                  onChange={e => {
                    const value = cleanInitWhiteSpace(e.target.value);
                    dispatch({ observation: value });
                  }}
                />
                {role === 'USER' || role === 'SUPERVISOR' ? (
                  <CheckboxValidatorElement disabled label="Sacar de Lista Negra" />
                ) : (
                  <CheckboxValidatorElement
                    disabled={!state.blackListVisitorExists || !onlineStatus}
                    label="Sacar de Lista Negra"
                    name="deleteFromBlackList"
                    onChange={e => handleCheckbox(e)}
                  />
                )}

                {checkboxBlacklist ? (
                  <TextValidator
                    required
                    disabled={!checkboxBlacklist}
                    id="reason"
                    name="reason"
                    label="Motivo"
                    className={classNames(classes.textField, classes.reason)}
                    margin="normal"
                    validators={['requiredValue', 'maxStringLength:500']}
                    errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
                    inputProps={{ maxLength: 500 }}
                    multiline
                    autoComplete="off"
                    value={reason}
                    onChange={e => {
                      setReason(validateOnlyBeforeWhiteSpaces(e.target.value));
                    }}
                  />
                ) : null}
                <div className={classes.footerButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={!canSend(checkboxBlacklist, reason) || !onlineStatus}
                    type="submit"
                    className={classes.buttonSearch}
                  >
                    Aceptar
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(CloseVisitor);
