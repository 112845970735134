import React, { useEffect, useRef, useReducer, useState, useContext, useCallback } from 'react';
import moment from 'moment';
import useOnlineStatus from '@rehooks/online-status';
import Card from '@material-ui/core/Card';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import StepsContext from '../../../contexts/stepsContext';
import { useToggleNavBar } from '../../../libraries/contexts';
import CheckboxValidatorElement from '../../../components/fields/checkBox';
import SearchableSelect from '../../../components/fields/searchableSelect';
import AccessesService from '../../../services/accesses';
import VisitsService from '../../../services/visits';
import CredentialsService from '../../../services/credentials';
import {
  START,
  SELECT_CREDENTIAL,
  REQUIRED_FIELD,
  FORMAT_DATE,
  FORMAT_HOUR,
  VISITOR_ORIGIN,
  BACK,
  PARTICULAR,
  COMPANION,
  QUANTITY,
  REASON,
  START_DATE,
  GUEST_VISITED,
  RECEPTION,
  ASSOCIATED_CREDENTIAL,
  ACCESS,
  MAX_STRING_LENGTH,
  REGISTER_VISIT_SUCCESS,
  REGISTER_VISIT_FAIL,
  NO_OPTION_LABELS,
  HAS_VISIT_ACTIVE_MSG
} from '../../../libraries/texts';
import { requiredValue, isConditionatedBy } from '../../../libraries/validators';
import {
  validatorQuantity,
  validateOnlyBeforeWhiteSpaces,
  validateSpecialCharacters
} from '../../../libraries/utils';
/* import { generalClasses as styles } from '../styles'; */
import { generalClasses as styles } from '../../accesses/styles';
import UserService from '../../../services/user-service';
import BlackListService from '../../../services/blackList';

const FETCH_ADMIN_USER_FAIL = 'Error al obtener el usuario administrador';
const FETCH_BLACKLIST_FAIL = 'Error al obtener la lista negra';

const initialState = {
  guest: '',
  visitorOrigin: '',
  visitingHour: null,
  credential: null,
  isParticular: false,
  reason: '',
  quantity: '',
  hasCompanion: false,
  access: null,
  associatedCredential: false,
  manual: false,
  patente: '',
  factura: '',
  guia: '',
  sello: '',
  closeHour: null,
  adminUser: null,
  currentDate: null
};

const registerVisitReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};

const getDateFormat = date => {
  return ` ${moment(date).format(FORMAT_DATE)} ${moment(date).format(FORMAT_HOUR)}`;
};

const RegisterVisit = props => {
  const formRef = useRef(null);
  const onlineStatus = useOnlineStatus();
  const stepsContext = useContext(StepsContext);
  const [credentials, setCredentials] = useState([]);
  const [credentialError, setCredentialError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [accesses, setAccesses] = useState([]);
  const [state, dispatch] = useReducer(registerVisitReducer, initialState);
  const {
    guest,
    visitorOrigin,
    visitingHour,
    isParticular,
    credential,
    reason,
    access,
    hasCompanion,
    associatedCredential,
    quantity,
    colaborador
  } = state;

  const [hasActiveVisit, setHasActiveVisit] = useState(false);
  const COLABORADOR = 'COLABORADOR';
  const { dispatch: stepsDispatch, beforeStep: data, history } = stepsContext;
  const { classes, formControlClass = classes.middleWidth } = props;
  const [, changeToggleNavBar] = useToggleNavBar();

  const getRequests = async () => {
    const accessesList = await AccessesService.getAll();
    const credentialsList = await CredentialsService.getAllAvailables();
    const getAccesses = accessesList.map(item => {
      const { description, _id: id } = item;
      return {
        value: description,
        label: description,
        id
      };
    });
    const getCredentials = credentialsList.map(item => {
      const { code, _id: id } = item;
      return {
        value: code,
        label: code,
        id
      };
    });

    setAccesses(getAccesses);
    setCredentials(getCredentials);
  };

  const ReturnIfVisitorHasVisitActive = useCallback(() => {
    const timeToRedirect = 2000;
    const hasVisit = !!data.activeVisit;
    const type = data.documentType === COLABORADOR ? 'first' : 'back';
    if (hasVisit && data.documentType === COLABORADOR) {
      Swal.fire({
        icon: 'warning',
        text: 'Colaborador ya cuenta con una visita activa ¿desea cerrar la visita?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sí, cerrar la visita',
        cancelButtonText: 'No, volver al inicio',
        allowOutsideClick: false
      }).then(async res => {
        if (res.isConfirmed) {
          await VisitsService.cerrarVisita(data.activeVisit);
        } else {
          setHasActiveVisit(hasVisit);
          stepsDispatch({ type, data });
        }
      });
    } else {
      if (!hasVisit) {
        return false;
      }
      setHasActiveVisit(hasVisit);

      setTimeout(() => {
        stepsDispatch({ type, data });
      }, timeToRedirect);
      changeToggleNavBar({
        message: { message: HAS_VISIT_ACTIVE_MSG, open: true }
      });
      return true;
    }

    /*     if (!hasVisit) {
      return false;
    }

    setHasActiveVisit(hasVisit);

    setTimeout(() => {
      stepsDispatch({ type, data });
    }, timeToRedirect);
    
    changeToggleNavBar({
      message: { message: HAS_VISIT_ACTIVE_MSG, open: true }
    });
    return true; */
  }, [data, stepsDispatch, changeToggleNavBar]);

  const makeValid = name => {
    const elem = formRef.current.childs.find(item => item.props.name === name);
    elem.makeValid({ isValid: true });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('requiredValue', requiredValue);
    ValidatorForm.addValidationRule('isConditionatedBy', isConditionatedBy);

    async function fetchAdminUser() {
      try {
        data.adminUser = await UserService.getAdminUser();
      } catch (err) {
        console.error('[ fetch admin user ]', err);
        changeToggleNavBar({ message: { message: FETCH_ADMIN_USER_FAIL, open: true } });
      }
    }

    async function getCurrentTime() {
      data.currentDate = await VisitsService.getTime();
    }

    fetchAdminUser();
    getCurrentTime();
    getRequests();
    ReturnIfVisitorHasVisitActive();
  }, [data, ReturnIfVisitorHasVisitActive]);

  useEffect(() => {
    async function getTime() {
      const currentDateTime = await VisitsService.getTime();
      dispatch({ visitingHour: currentDateTime });
    }
    dispatch({ guest: data && data.guest, ...(data || {}) });
    getTime();
  }, [data, changeToggleNavBar]);

  const validatorListener = async () => {
    const result = await formRef.current.isFormValid();
    const accessSelected = !!state.access;
    setDisabled(result && accessSelected);
  };

  const nextPage = async () => {
    stepsDispatch({ type: 'next' });
    const { _id: visitor } = state;
    state.visitor = visitor;
    state.documentId = !colaborador ? state.documentId.replace(/\./g, '') : null;
    state.access = access && accesses.find(item => access && item.value === access.value).id;
    state.credential =
      credential && credentials.find(item => credential && item.value === credential.value).id;
    if (!colaborador) {
      if (!state.visitorOrigin) {
        state.visitorOrigin = 'Particular';
      }
    } else {
      state.visitorOrigin = colaborador.companyName;
    }
    state.visitHourFromClient = state.visitingHour;

    delete state.updatedAt;
    delete state._id;

    try {
      const resp = VisitsService.post(state);
      const result = await resp;
      if (result) {
        changeToggleNavBar({ message: { message: REGISTER_VISIT_SUCCESS, open: true } });
        history.push('/visits/close');
      } else {
        changeToggleNavBar({ message: { message: REGISTER_VISIT_FAIL, open: true } });
      }
    } catch (err) {
      console.error('[ register visit ]', err);
    }
  };

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContentForm}>
        {
          <React.Fragment>
            <div className={classNames(formControlClass, classes.serialControl)}>
              <div className={classes.backForm}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!onlineStatus}
                  onClick={() =>
                    stepsDispatch({
                      type: data.documentType === COLABORADOR ? 'first' : 'back',
                      data
                    })
                  }
                  className={classes.buttonSearch}
                >
                  <ArrowBack className={classes.rightIcon} />
                  {BACK}
                </Button>
              </div>
              <ValidatorForm
                autoComplete="off"
                ref={formRef}
                className={classes.formVerify}
                onSubmit={() => nextPage()}
              >
                <div className={classNames(classes.flex, classes.alignCenter, classes.startDate)}>
                  <InputLabel>
                    {START_DATE}
                    {visitingHour ? getDateFormat(visitingHour) : ''}
                  </InputLabel>
                </div>
                <div className={classes.labelTitle}>
                  <InputLabel>{GUEST_VISITED}</InputLabel>
                </div>
                <InputLabel>{guest ? guest.label : RECEPTION}</InputLabel>
                <FormLabel component="legend" />
                <TextValidator
                  id="visitorOrigin"
                  name="visitorOrigin"
                  label={VISITOR_ORIGIN}
                  autoComplete="off"
                  inputProps={{ maxLength: 50 }}
                  required={!isParticular}
                  validatorListener={validatorListener}
                  className={classes.textField}
                  margin="normal"
                  disabled={!colaborador ? isParticular : true}
                  validators={!isParticular ? [`isConditionatedBy:${isParticular}`] : []}
                  errorMessages={!isParticular ? [REQUIRED_FIELD] : []}
                  value={colaborador ? colaborador.companyName : visitorOrigin}
                  onChange={e => {
                    dispatch({ [e.target.name]: validateOnlyBeforeWhiteSpaces(e.target.value) });
                  }}
                />
                <TextField
                  id="patente"
                  name="patente"
                  label="Patente"
                  autoComplete="off"
                  inputProps={{ maxLength: 7 }}
                  className={classes.textField}
                  margin="normal"
                  value={state.patente}
                  onChange={e => {
                    dispatch({ [e.target.name]: validateOnlyBeforeWhiteSpaces(e.target.value) });
                  }}
                />

                <TextField
                  id="factura"
                  name="factura"
                  label="No. Facturas"
                  autoComplete="off"
                  className={classes.textField}
                  inputProps={{ maxLength: 100 }}
                  margin="normal"
                  multiline
                  rowsMax="4"
                  value={state.factura}
                  onChange={e => {
                    dispatch({ [e.target.name]: validateOnlyBeforeWhiteSpaces(e.target.value) });
                  }}
                />

                <TextField
                  id="guia"
                  name="guia"
                  label="No. Guías de despacho"
                  autoComplete="off"
                  className={classes.textField}
                  margin="normal"
                  inputProps={{ maxLength: 100 }}
                  multiline
                  rowsMax="4"
                  value={state.guia}
                  onChange={e => {
                    dispatch({ [e.target.name]: validateOnlyBeforeWhiteSpaces(e.target.value) });
                  }}
                />

                <TextField
                  id="sello"
                  name="sello"
                  label="No. Sello"
                  autoComplete="off"
                  className={classes.textField}
                  inputProps={{ maxLength: 100 }}
                  margin="normal"
                  multiline
                  rowsMax="4"
                  value={state.sello}
                  onChange={e => {
                    dispatch({ [e.target.name]: validateOnlyBeforeWhiteSpaces(e.target.value) });
                  }}
                />

                <CheckboxValidatorElement
                  label={PARTICULAR}
                  value={isParticular}
                  name="isParticular"
                  validatorListener={validatorListener}
                  disabled={colaborador}
                  onChange={e => {
                    makeValid('visitorOrigin');
                    dispatch({
                      [e.target.name]: e.target.checked,
                      visitorOrigin: e.target.checked ? '' : visitorOrigin
                    });
                  }}
                />
                <div className={classNames(classes.companion)}>
                  <CheckboxValidatorElement
                    label={COMPANION}
                    className={classes.hasCompanion}
                    value={hasCompanion}
                    name="hasCompanion"
                    validatorListener={validatorListener}
                    onChange={e => {
                      makeValid('quantity');
                      dispatch({
                        [e.target.name]: e.target.checked,
                        quantity: !e.target.checked ? '' : quantity
                      });
                    }}
                  />
                  <TextValidator
                    id="quantity"
                    validatorListener={validatorListener}
                    name="quantity"
                    label={QUANTITY}
                    type="text"
                    autoComplete="off"
                    max={20}
                    required={hasCompanion}
                    inputProps={{ min: '0', max: '10', maxLength: 2 }}
                    className={classNames(classes.quantityCompanion)}
                    margin="normal"
                    validators={hasCompanion ? [`isConditionatedBy:${!hasCompanion}`] : []}
                    errorMessages={hasCompanion ? [REQUIRED_FIELD] : []}
                    disabled={!hasCompanion}
                    value={quantity}
                    onChange={e => {
                      dispatch({ [e.target.name]: validatorQuantity(e.target.value) });
                    }}
                  />
                </div>
                <CheckboxValidatorElement
                  label={ASSOCIATED_CREDENTIAL}
                  value={associatedCredential}
                  name="associatedCredential"
                  validatorListener={validatorListener}
                  onChange={e => {
                    setCredentialError(associatedCredential);
                    dispatch({
                      [e.target.name]: e.target.checked,
                      credential: !e.target.checked ? null : credential
                    });
                  }}
                />

                {associatedCredential ? (
                  <SearchableSelect
                    value={credential}
                    required={associatedCredential}
                    isDisabled={!associatedCredential}
                    onBlur={e => {
                      setCredentialError(!!e.target.value && associatedCredential);
                    }}
                    hasError={credentialError}
                    placeholder={associatedCredential ? SELECT_CREDENTIAL : ''}
                    options={credentials}
                    noOptionsMessage={() => NO_OPTION_LABELS}
                    onChange={e => {
                      setCredentialError(!!e && !associatedCredential);
                      dispatch({ credential: e });
                    }}
                  />
                ) : null}

                <SearchableSelect
                  id="access"
                  name="access"
                  value={access}
                  placeholder={ACCESS}
                  options={accesses}
                  required
                  validatorListener={validatorListener}
                  /* noOptionsMessage={() => NO_OPTION_LABELS} */
                  errorMessages={[REQUIRED_FIELD]}
                  onChange={e => {
                    !e ? setDisabled(false) : setDisabled(true);
                    dispatch({ access: e });
                  }}
                />

                <TextValidator
                  id="reason"
                  name="reason"
                  label={REASON}
                  className={classNames(classes.textField, classes.reason)}
                  margin="normal"
                  inputProps={{ maxLength: 500 }}
                  multiline
                  autoComplete="off"
                  validators={['maxStringLength:500']}
                  errorMessages={[MAX_STRING_LENGTH]}
                  value={reason}
                  onChange={e => {
                    dispatch({ [e.target.name]: validateSpecialCharacters(e.target.value) });
                  }}
                />

                <div className={classes.footerButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={
                      !disabled ||
                      !onlineStatus ||
                      hasActiveVisit ||
                      (associatedCredential && !credential)
                    }
                    type="submit"
                    className={classes.buttonSearch}
                  >
                    {START}
                  </Button>
                </div>
              </ValidatorForm>
            </div>
            <Divider />
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(RegisterVisit);
