import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import GuestsUpdate from './guests-update';
import { generalClasses as styles } from './styles';

import { GUESTS_UPDATE_TITLE } from './texts';

const List = props => {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title={GUESTS_UPDATE_TITLE} />
      <CardContent className={classes.cardContent}>
        <GuestsUpdate {...props} />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(List);
