import React from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { GarbageIcon, EditIcon } from '../../icons/customsIcons';
import { useToggleNavBar } from '../../libraries/contexts';
import { generalClasses as styles } from './styles';

const ActionTable = props => {
  const { classes, entity, to, service, getRequests } = props;
  const [toggleValue, changeToggleNavBar] = useToggleNavBar();

  const onlineStatus = useOnlineStatus();
  const { _id: id } = entity;

  const applyRemoveRequest = async value => {
    const { message, error } = await service.delete(value);
    if (message && message === 'IsAdmin' && error) {
      const { main, secondary } = toggleValue;

      changeToggleNavBar({
        main,
        secondary,
        message: {
          message: error,
          open: true
        }
      });
      return false;
    }
    return true;
  };

  const removeElement = async () => {
    const { main, secondary } = toggleValue;

    const success = await applyRemoveRequest(id);
    if (success) {
      await getRequests();

      changeToggleNavBar({
        main,
        secondary,
        undo: true,
        message: {
          message: 'Registro eliminado',
          key: id,
          service,
          getRequests,
          undo: true,
          open: true
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Editar">
        <IconButton
          color="secondary"
          component={NavLink}
          disabled={!onlineStatus}
          to={{ pathname: `${to}/edit`, entity }}
          className={classes.button}
          aria-label="add an alarm"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Eliminar">
        <IconButton
          className={classes.button}
          aria-label="delete"
          component={NavLink}
          to={`${to}/list`}
          disabled={!onlineStatus}
          onClick={removeElement}
          color="primary"
        >
          <GarbageIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
};

export default withStyles(styles)(ActionTable);
