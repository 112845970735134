import React, { useReducer, useState, useEffect, useRef } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getValidations } from '../../libraries/validators';
import SimpleSelectValidatorElement from '../../components/fields/SimpleSelectValidatorElement';
import { useToggleNavBar } from '../../libraries/contexts';
import { validateSpecialCharacters, save } from '../../libraries/utils';
import {
  MAX_STRING_LENGTH,
  REQUIRED_FIELD,
  BACK,
  UPDATED,
  ERROR_CREDENTIALS,
  SAVE,
  CREATED,
  ACTIVE,
  AVAILABLE,
  LOST
} from '../../libraries/texts';
import CredentialsService from '../../services/credentials';
import { generalClasses as styles } from './styles';

const formReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};
const initialState = {
  state: 'AVAILABLE',
  code: ''
};

const stateList = [
  {
    value: 'AVAILABLE',
    label: AVAILABLE
  },
  {
    value: 'ACTIVE',
    label: ACTIVE
  },

  {
    value: 'LOST',
    label: LOST
  }
];

const Form = props => {
  const { classes, entity, history } = props;
  const onlineStatus = useOnlineStatus();
  const formRef = useRef(null);
  const [stateForm, dispatch] = useReducer(formReducer, entity || initialState);
  const [disabled, setDisabled] = useState(false);

  const [, changeToggleNavBar] = useToggleNavBar();
  const { code, state } = stateForm;

  const submitForm = async () => {
    try {
      const { _id: hasId } = stateForm;

      const result = await save(hasId, { ...stateForm }, CredentialsService);

      if (result) {
        changeToggleNavBar({
          message: { message: `Credencial ${hasId ? UPDATED : CREATED}`, open: true }
        });
        return history.push('/credentials/list');
      }
      return changeToggleNavBar({ message: { message: 'Not found', open: true } });
    } catch (err) {
      return changeToggleNavBar({
        message: { message: ERROR_CREDENTIALS[err.message], open: true }
      });
    }
  };

  useEffect(() => {
    getValidations();
  }, []);

  const validatorListener = async () => {
    const result = await formRef.current.isFormValid();
    setDisabled(result);
  };

  return (
    <ValidatorForm
      novalidate
      className={classes.formVerify}
      ref={formRef}
      instantValidate
      onSubmit={submitForm}
    >
      <div className={classes.backForm}>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          size="small"
          className={classes.buttonSearch}
          to="/credentials/list"
          disabled={!onlineStatus}
        >
          <ArrowBack className={classes.rightIcon} />
          {BACK}
        </Button>
      </div>

      <TextValidator
        id="code"
        required
        disabled={!!entity}
        name="code"
        label="Codigo"
        inputProps={{ maxLength: 100 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={code}
        validators={['requiredValue', 'maxStringLength:50']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateSpecialCharacters(e.target.value) });
        }}
        onBlur={e => {
          if (e.target.value)
            dispatch({ [e.target.name]: validateSpecialCharacters(e.target.value) });
        }}
      />

      <SimpleSelectValidatorElement
        value={state}
        id="state"
        name="state"
        label="Estado"
        validatorListener={validatorListener}
        options={stateList}
        onChange={e => {
          dispatch({ [e.target.name]: e.target.value });
        }}
      />

      <div className={classes.footerSaveButton}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={!onlineStatus || !disabled}
        >
          {SAVE}
        </Button>
      </div>
    </ValidatorForm>
  );
};

export default withStyles(styles)(Form);
