import { getFormatRut } from '../../libraries/utils';

const couldBeScannerInput = value => /0i(?:n|$)(?:i|$)(?:t|$)(?:0|$)/.test(value);
const isInputEnd = value => /0end0$/.test(value);

const isScannedText = value => /^0init0.*0end0/.test(value);

const isInputInit = value => /^0init0/.test(value);
const getLastCharacters = value => value.match(/.*0init0.*?(.{0,5})$/);

const hasScannedText = value => /(.*)(0init0.*)/.test(value);
const hasScannedCompleteText = value => /.+0init0.*0end0/.test(value);
const getInputTextAndScannedText = value => value.match(/(.*)0init0.*?(.{0,5})$/);

const cleanScannerText = text => {
  if (isScannedText(text)) return '';

  if (hasScannedCompleteText(text)) {
    const [, inputText] = getInputTextAndScannedText(text);
    return inputText;
  }

  if (isInputInit(text)) {
    const [, chars] = getLastCharacters(text);
    const newText = `0init0${chars}`;
    return newText;
  }

  if (hasScannedText(text)) {
    const [, inputText, lastChars] = getInputTextAndScannedText(text);
    const newText = `${inputText}0init0${lastChars}`;
    return newText;
  }
  return text;
};

const rgxQr = /RUN.{1}([0-9]{7,8}).{1}([0-9kK])/;
const isQrCode = value => rgxQr.test(value);
const getRunFromQr = value => value.match(rgxQr);

const rgxPdf417 = /^0init0([0-9]{7,8}[0-9kK]{1})[0-9\s]{10}.+[A-Z]{3}/;
const isPdf417Code = value => rgxPdf417.test(value);
const getRunFromPdf417 = value => value.match(rgxPdf417);

const getRUNFromCode = code => {
  if (isQrCode(code)) {
    const [, digits, dv] = getRunFromQr(code);
    const run = `${digits}${dv}`;
    return run;
  }

  if (isPdf417Code(code)) {
    const [, run] = getRunFromPdf417(code);
    return run;
  }

  return '';
};

export const RGX_ALL_NON_ALPHANUMERIC_CHARS = /[^a-z0-9]/gi;
export const RGX_OLD_PASSPORT_UNDER_TEN_MILLIONS = /(^[a-z0-9]*)([^a-z0-9]{1})([a-z0-9]*)([^a-z0-9]{12})/gi;
export const RGX_ALL_NON_ALPHA_CHARS = /[^a-z]/gi;
export const RGX_FIND_COUNTRY = /[a-z]{3}/i;
export const RGX_FIND_ALL_LETTERS = /[a-z^k]/gi;
export const RGX_FIND_NAMES = /([^a-z0-9]{2}[a-z]).*/gi;
export const RGX_FIND_ALL_SURNAMES_NEW = /([^a-z0-9]{1}[0-9]{1}[a-z]+).*?(?=[^a-z0-9]{2})/gi;
export const RGX_FIND_ALL_SURNAMES_OLD = /([^a-z0-9]{1}[0-9]{1}[a-z]+)[^0-9].*?(?=[^a-z0-9]{2})/gi;
export const PASSPORT_LENGTH = 90;

const removeAllNonAlpha = val => val.split(RGX_ALL_NON_ALPHA_CHARS).filter(Boolean);
const removeAllNonAlphaNumeric = val => val.split(RGX_ALL_NON_ALPHANUMERIC_CHARS).filter(Boolean);

const clearCountry = country => (country && country.length === 4 ? country.slice(0, 3) : country);

const getPassportAndIdForOldPassport = scan => {
  let passportId = '';
  let genderAndCountry = '';
  if (RGX_OLD_PASSPORT_UNDER_TEN_MILLIONS.test(scan)) {
    const [originalPassportId, , originalGenderAndCountry] = removeAllNonAlphaNumeric(scan);
    passportId = originalPassportId.slice(5);
    genderAndCountry = originalGenderAndCountry;
  } else {
    const [originalPassportId, originalGenderAndCountry] = removeAllNonAlphaNumeric(scan);
    passportId = originalPassportId.slice(5, -4);
    genderAndCountry = originalGenderAndCountry;
  }
  return [passportId, genderAndCountry];
};

const getValuesForNewChileanPassport = scan => {
  const [, genderAndCountryAndID, checkDigit] = removeAllNonAlphaNumeric(scan);
  const [gender, country] = removeAllNonAlpha(genderAndCountryAndID);
  const [namesMatch] = scan.match(RGX_FIND_NAMES);
  const names = removeAllNonAlpha(namesMatch).join(' ');
  const [surnamesMatch] = scan.match(RGX_FIND_ALL_SURNAMES_NEW);
  const [surname, secondSurname = ''] = removeAllNonAlpha(surnamesMatch);

  const [, passportWithoutCheckDigit] = genderAndCountryAndID.split(RGX_FIND_COUNTRY);
  const passport = getFormatRut(`${passportWithoutCheckDigit}-${checkDigit}`);

  return { names, surname, secondSurname, passport, gender, country };
};

const getValuesForOldChileanPassport = scan => {
  const [passportId, genderAndCountry] = getPassportAndIdForOldPassport(scan);
  const passport = passportId && passportId.length ? getFormatRut(passportId) : '';
  const [gender, nonClearCountry] = removeAllNonAlpha(genderAndCountry);
  const country = clearCountry(nonClearCountry);
  const [namesMatch] = scan.match(RGX_FIND_NAMES);
  const names = removeAllNonAlpha(namesMatch).join(' ');
  const [surnamesMatch] = scan.match(RGX_FIND_ALL_SURNAMES_OLD);
  const [surname, secondSurname = ''] = removeAllNonAlpha(surnamesMatch);
  return { names, surname, secondSurname, passport, gender, country };
};

const getPassportsValues = scan => {
  if (!scan.length || scan.length !== PASSPORT_LENGTH) {
    return '';
  }
  const passportType = scan.slice(0, 5);

  if (passportType && (passportType === 'INCHL' || passportType === 'IECHL')) {
    return getValuesForNewChileanPassport(scan);
  }
  if (passportType && passportType === 'IDCHL') {
    return getValuesForOldChileanPassport(scan);
  }
  return '';
};

export { getRUNFromCode, cleanScannerText, couldBeScannerInput, isInputEnd, getPassportsValues };
