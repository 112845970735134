// @ts-check
const clearPersistentDataProps = () => {
  localStorage.clear();
};

const getPersistenceDataProps = (comp, elements) => {
  elements.map(name => {
    const value = JSON.parse(localStorage.getItem(name));
    return comp.setState({ [name]: value });
  });
};

const savePersistenceDataProps = (comp, elements) => {
  elements.map(name => {
    let value = comp.state[name];
    value = typeof value === 'object' ? JSON.stringify(value) : value;
    return localStorage.setItem(name, value);
  });
};

const save = (name, value) => {
  localStorage.setItem(name, typeof value === 'object' ? JSON.stringify(value) : value);
};

const get = name => localStorage.getItem(name) || '';

const removeKey = name => localStorage.removeItem(name);

const getObject = name =>
  localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : '';

const multiget = (...names) => names.map(name => get(name));

const persistenceService = {
  getPersistenceDataProps,
  savePersistenceDataProps,
  clearPersistentDataProps,
  removeKey,
  save,
  get,
  multiget,
  getObject
};

export default persistenceService;
