import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Form from './form';
import { generalClasses as styles } from './styles';

const Create = props => {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title="Nueva Sucursal" />
      <CardContent className={classes.cardContent}>
        <Card className={classes.formCard}>
          <CardContent className={classes.cardContent}>
            <Form {...props} />
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(Create);
