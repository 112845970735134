import React, { useState, useEffect, useContext, useReducer, useRef } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { Card, Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import CheckboxValidatorElement from '../../../components/fields/checkBox';
import StepsContext from '../../../contexts/stepsContext';
import { useToggleNavBar } from '../../../libraries/contexts';
import VisitorService from '../../../services/visitor';
import SearchableSelect from '../../../components/fields/searchableSelect';
import {
  SEARCH,
  IDENTIFICATION_TYPE,
  IDENTIFICATION_NUMBER,
  REQUIRED_FIELD,
  INVALID_RUT,
  MAX_STRING_LENGTH,
  FAIL_RUT_SCAN,
  SCAN_LABEL,
  IDENTIFICATION_NUMBER_SCAN,
  SEARCH_VISIT,
  SELECT_VISIT,
  NO_OPTION_LABELS,
  CONTINUE
} from '../../../libraries/texts';
import { getFormatRut, getFormatPassport } from '../../../libraries/utils';
import { requiredValue, isValidRut } from '../../../libraries/validators';
/* import { generalClasses as styles } from '../styles'; */
import { generalClasses as styles } from '../../accesses/styles';
import RUNScanner, { cleanScannerText, couldBeScannerInput } from '../../../components/runScanner';
import GuestService from '../../../services/guest';

const RUT = 'RUT';
const PASSPORT = 'PASSPORT';
const COLABORADOR = 'COLABORADOR';
const identifications = [
  { value: RUT, label: 'RUT' },
  { value: COLABORADOR, label: 'Colaborador' },
  { value: PASSPORT, label: 'Pasaporte' }
];
const MAX_PASSPORT_LENGTH = 50;
const MAX_RUT_LENGTH = 12;

const validations = {
  RUT: ['requiredValue', 'rutValidator', `maxStringLength:${MAX_RUT_LENGTH}`, 'minStringLength:9'],
  PASSPORT: ['requiredValue', `maxStringLength:${MAX_PASSPORT_LENGTH}`, 'minStringLength:7'],
  COLABORADOR: [
    'requiredValue',
    'rutValidator',
    `maxStringLength:${MAX_RUT_LENGTH}`,
    'minStringLength:9'
  ]
};

const errors = {
  RUT: [REQUIRED_FIELD, INVALID_RUT, MAX_STRING_LENGTH],
  PASSPORT: [REQUIRED_FIELD, MAX_STRING_LENGTH],
  COLABORADOR: [REQUIRED_FIELD, MAX_STRING_LENGTH]
};

const TIME_FOCUS_LOAD = 150;
const useFocus = () => {
  const ref = useRef(null);
  const setFocus = () => ref.current && setTimeout(() => ref.current.focus(), TIME_FOCUS_LOAD);
  return { ref, setFocus };
};

const initialState = {
  colaborador: null
};

const addGuestReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};

const VerifyId = props => {
  const onlineStatus = useOnlineStatus();
  const steps = useContext(StepsContext);

  const { dispatch: stepsDispatch } = steps;
  const { classes, formControlClass = classes.middleWidth } = props;
  const [documentId, setDocumentId] = useState('');
  const [visitor, setVisitor] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [documentType, setDocumentType] = useState(RUT);
  const [guestList, setGuestLis] = useState([]);
  const [state, dispatch] = useReducer(addGuestReducer, initialState);

  const [isManual, setIsManual] = useState(false);
  const [useScanner, setUseScanner] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const scannerInputRef = useFocus(null);
  const documentIdInputRef = useFocus(null);
  const guestInputRef = useFocus(null);
  const [, changeToggleNavBar] = useToggleNavBar();
  const [selectError, setSelectError] = useState(false);
  const { colaborador } = state;
  const formRef = useRef(null);
  const SUBMIT_DELAY = 1500;

  const getRequests = async () => {
    const guests = await GuestService.getAll();
    const newArray = guests.map(item => {
      const { names, surname, secondSurname, companyName } = item;
      const { office: officeNumber = '' } = item.branchOffice ? item.branchOffice : {};
      return {
        ...item,
        value: `${names} ${surname} ${secondSurname} - ${companyName} - ${officeNumber}`,
        label: `${names} ${surname} ${secondSurname} - ${companyName} - ${officeNumber}`
      };
    });

    setGuestLis(newArray);
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('requiredValue', requiredValue);
    ValidatorForm.addValidationRule('rutValidator', isValidRut);
    getRequests();
  }, []);

  const validatorListener = result => {
    setDisabled(result);
  };

  const nextPage = async () => {
    setDisabled(false);
    const searchDni = isEmployee ? colaborador.numberId : documentId.replace(/\./g, '');
    const type = !isEmployee ? 'next' : 'last';
    let data = {};
    try {
      data = await VisitorService.getVisitor(searchDni, documentType);
      data.colaborador = null;
      data.documentId =
        documentType === RUT || documentType === COLABORADOR
          ? getFormatRut(data.documentId) || getFormatRut(searchDni)
          : data.documentId || searchDni;

      data.documentType = documentType;
      data.manual = isManual;

      if (isEmployee) {
        data.colaborador = colaborador;
      }

      stepsDispatch({ type, data });
    } catch (error) {
      data = { status: error };
      data.documentType = documentType;
      data.manual = isManual;

      if (!isEmployee) {
        data.documentId = documentType === RUT ? getFormatRut(searchDni) : searchDni;
      } else {
        data.employee = colaborador;
        data.reception = true;
        data.guest = null;
        data.enabled = true;
        data.isEmployee = true;
        data.documentId = colaborador.numberId;

        if (!visitor) {
          try {
            const v = {
              enabled: true,
              reasonToRemoveFromBlacklist: null,
              documentId: colaborador.numberId,
              names: colaborador.names,
              surname: colaborador.surname,
              secondSurname: colaborador.secondSurname,
              country: colaborador.value,
              gender: '',
              documentType: 'COLABORADOR',
              user: colaborador.user,
              createdAt: new Date(),
              updatedAt: new Date(),
              __v: 0
            };
            setVisitor(v);
            VisitorService.post(v);
          } catch (error) {
            console.error(error);
          }
        }
      }
      const dataWithVisitor = { ...data, visitor };
      stepsDispatch({ type, data: dataWithVisitor });
    }
  };

  const handleCheckIsManual = e => {
    console.log('handleCheckIsManual');

    setIsManual(e.target.checked);
    setDocumentId('');
    if (!e.target.checked) {
      scannerInputRef.setFocus();
    } else {
      isEmployee ? guestInputRef.setFocus() : documentIdInputRef.setFocus();
    }
  };

  const handleChangeScan = obj => {
    console.log('handleChangeScan');
    if (!obj || !obj.passport) {
      changeToggleNavBar({
        message: { message: FAIL_RUT_SCAN, open: true }
      });
      setDocumentId('');
    } else {
      setUseScanner(true);
      setVisitor({ ...obj });
      setDocumentId(obj.passport);

      Object.values(guestList).filter(guest => {
        if (getFormatRut(guest.numberId) === getFormatRut(obj.passport)) {
          setIsManual(false);
          setIsEmployee(true);
          setDocumentType(COLABORADOR);
          setDisabled(!!guest);
          dispatch({ colaborador: guest });
          guestInputRef.setFocus();
        }
      });

      setTimeout(() => {
        handleSubmit();
      }, SUBMIT_DELAY);
    }
  };

  const handleSubmit = () => {
    console.log('handleSubmit');
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleFocusScan = isManualInput => {
    console.log('handleFocusScan');
    if (isManualInput) {
      isEmployee ? guestInputRef.setFocus() : documentIdInputRef.setFocus();
    }
  };

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContentForm}>
        {
          <React.Fragment>
            <div
              className={classNames(
                formControlClass,
                classes.tableMiddleWidth,
                classes.serialControl
              )}
            >
              <ValidatorForm
                ref={formRef}
                autoComplete="off"
                className={classes.formStyle}
                onSubmit={() => nextPage()}
              >
                <Grid container spacing={16}>
                  <Grid item md={12}>
                    <FormLabel component="legend">
                      {IDENTIFICATION_TYPE}
                      {isManual}
                    </FormLabel>
                  </Grid>
                  <Grid item md={isEmployee ? 12 : 6}>
                    <RadioGroup
                      aria-label="identification"
                      name="identification"
                      required
                      value={documentType}
                      onChange={e => {
                        const { value } = e.target;
                        getRequests();
                        setDocumentType(value);
                        setDocumentId('');
                        setIsEmployee(false);

                        if (value === COLABORADOR) {
                          setIsManual(!useScanner);
                          setIsEmployee(true);
                        }

                        if (value === RUT) {
                          setIsManual(false);
                          scannerInputRef.setFocus();
                        } else if (value === COLABORADOR) {
                          guestInputRef.setFocus();
                        } else {
                          // default PASAPORTE
                          setIsManual(true);
                          setDisabled(false);
                          documentIdInputRef.setFocus();
                        }
                      }}
                    >
                      {identifications.map(item => (
                        <FormControlLabel
                          value={item.value}
                          control={<Radio />}
                          label={item.label}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                  {!isEmployee ? (
                    <Grid item md={6}>
                      <CheckboxValidatorElement
                        label={SCAN_LABEL}
                        className={classes.hasCompanion}
                        containerClassName={classes.checkScanContainer}
                        name="manual"
                        checked={isManual}
                        onChange={e => handleCheckIsManual(e)}
                        disabled={documentType === PASSPORT}
                      />
                    </Grid>
                  ) : null}

                  {!isEmployee ? (
                    <Grid item md={12}>
                      <TextValidator
                        id="dni"
                        autoFocus
                        autoComplete="off"
                        inputRef={documentIdInputRef.ref}
                        label={isManual ? IDENTIFICATION_NUMBER : IDENTIFICATION_NUMBER_SCAN}
                        className={classNames(classes.textField, classes.textFieldVerify)}
                        value={documentId}
                        required
                        validators={validations[documentType]}
                        errorMessages={errors[documentType]}
                        onChange={e => {
                          const { value } = e.target;
                          if (couldBeScannerInput(value)) {
                            setDocumentId(cleanScannerText(value));
                            return;
                          }
                          setDocumentId(
                            documentType === RUT
                              ? getFormatRut(value.toUpperCase(), { maxLength: MAX_RUT_LENGTH })
                              : getFormatPassport(value.toUpperCase(), {
                                  maxLength: MAX_PASSPORT_LENGTH
                                })
                          );
                        }}
                        validatorListener={validatorListener}
                        margin="normal"
                        disabled={!isManual}
                      />
                    </Grid>
                  ) : null}
                  {isEmployee ? (
                    <Grid item md={12}>
                      <SearchableSelect
                        inputRef={guestInputRef.ref}
                        label={SEARCH_VISIT}
                        value={colaborador}
                        required={isEmployee}
                        isDisabled={!isEmployee}
                        placeholder={SELECT_VISIT}
                        onBlur={e => {
                          setSelectError(!!e.target.value && isEmployee);
                        }}
                        hasError={isEmployee && selectError}
                        options={guestList}
                        noOptionsMessage={() => NO_OPTION_LABELS}
                        onChange={dataGuest => {
                          setDisabled(!!dataGuest);
                          dispatch({ colaborador: dataGuest });
                        }}
                        validatorListener={validatorListener}
                      />
                    </Grid>
                  ) : null}
                  <Grid item md={12}>
                    <RUNScanner
                      ref={scannerInputRef.ref}
                      isManualInput={!isManual}
                      onChange={e => handleChangeScan(e)}
                      onFocus={e => handleFocusScan(e)}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!disabled || !onlineStatus}
                      type="submit"
                      className={classes.buttonSearch}
                    >
                      {!isEmployee ? SEARCH : CONTINUE}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(VerifyId);
