import React, { useReducer, useState, useEffect, useRef } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getValidations } from '../../libraries/validators';
import { useToggleNavBar } from '../../libraries/contexts';
import CheckboxValidatorElement from '../../components/fields/checkBox';
import InputMaskValidatorElement from '../../components/fields/inputWithMask';
import {
  validateBussinessName,
  validateCodeCompany,
  getFormatNumber,
  getFormatRut,
  validateEmailCharacters,
  save
} from '../../libraries/utils';
import {
  MAX_STRING_LENGTH,
  MIN_STRING_LENGTH_PHONE,
  REQUIRED_FIELD,
  INVALID_RUT,
  BACK,
  EMAIL_ERROR,
  UPDATED,
  BUSINESS_NAME,
  COMPANY_CODE,
  INTEGRATED_GEN,
  MAX_OPTIONS_SELECTED,
  ERROR_COMPANY,
  NO_OPTION_LABELS,
  COMPANY_RUT,
  BRANCH_OFFICES,
  REQUIRED_BRANCH_FIELD,
  ADMINISTRATOR_MAIL,
  SAVE,
  PHONE_NUMBER,
  MIN_STRING_LENGTH_CODE_COMPANY,
  CREATED
} from '../../libraries/texts';
import SelectValidatorElement from '../../components/fields/selectSearch';
import BranchesService from '../../services/branches';
import CompaniesService from '../../services/companies';
import { generalClasses as styles } from './styles';

const maxBranchOffices = 50;
const formReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};
const initialState = {
  integrated: true,
  codeCompany: '',
  rut: '',
  name: '',
  phoneNumber: '',
  email: '',
  branchOffices: []
};

const Form = props => {
  const { classes, company, history } = props;
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const onlineStatus = useOnlineStatus();
  const [state, dispatch] = useReducer(formReducer, company || initialState);
  const [branches, setBranches] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [, changeToggleNavBar] = useToggleNavBar();
  const { integrated, name, rut, codeCompany, branchOffices, phoneNumber, email } = state;

  const getRequests = async () => {
    const branchesList = await BranchesService.getAll();
    const getBranches = branchesList.map(item => {
      const { name: branchName, _id: id } = item;
      const branchOffice = {
        value: branchName,
        label: branchName,
        id
      };
      return branchOffice;
    });

    setBranches(getBranches);
  };

  const submitForm = async () => {
    try {
      const { _id: hasId } = state;

      const result = await save(hasId, state, CompaniesService);

      if (result) {
        changeToggleNavBar({
          message: { message: `Empresa ${hasId ? UPDATED : CREATED}`, open: true }
        });
        return history.push('/companies/list');
      }
      return changeToggleNavBar({ message: { message: 'Not found', open: true } });
    } catch (err) {
      console.error('[ register companies ]', err);
      return changeToggleNavBar({
        message: { message: ERROR_COMPANY[err.message], open: true }
      });
    }
  };

  useEffect(() => {
    getValidations();
    getRequests();
  }, []);

  const validatorListener = async () => {
    const result = await formRef.current.isFormValid();
    setDisabled(result);
  };

  const makeValid = async nameField => {
    const elem = formRef.current.childs.find(item => item.props.name === nameField);

    elem.makeValid({ isValid: true });
  };

  return (
    <ValidatorForm
      novalidate
      className={classes.formVerify}
      ref={formRef}
      instantValidate
      onSubmit={submitForm}
    >
      <div className={classes.backForm}>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          size="small"
          className={classes.buttonSearch}
          to="/companies/list"
          disabled={!onlineStatus}
        >
          <ArrowBack className={classes.rightIcon} />
          {BACK}
        </Button>
      </div>
      <CheckboxValidatorElement
        label={INTEGRATED_GEN}
        validatorListener={validatorListener}
        name="integrated"
        checked={integrated}
        onChange={async e => {
          makeValid('codeCompany');
          const checked = e.target.checked;
          await dispatch({
            [e.target.name]: checked,
            codeCompany: ''
          });

          const result = await formRef.current.isFormValid();
          setDisabled(result);
        }}
      />
      <TextValidator
        id="codeCompany"
        required={integrated}
        name="codeCompany"
        label={COMPANY_CODE}
        inputProps={{ maxLength: 50 }}
        className={classes.textField}
        disabled={!integrated}
        validatorListener={validatorListener}
        value={codeCompany}
        validators={integrated ? ['requiredValue', 'maxStringLength:50', 'minStringLength:3'] : []}
        errorMessages={
          integrated ? [REQUIRED_FIELD, MAX_STRING_LENGTH, MIN_STRING_LENGTH_CODE_COMPANY] : []
        }
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateCodeCompany(e.target.value) });
        }}
        onBlur={e => {
          if (e.target.value) dispatch({ [e.target.name]: validateCodeCompany(e.target.value) });
        }}
      />
      <TextValidator
        id="rut"
        name="rut"
        required
        disabled={!!company}
        label={COMPANY_RUT}
        inputProps={{ maxLength: 12 }}
        className={classes.textField}
        autoComplete="off"
        value={rut}
        validatorListener={validatorListener}
        validators={['requiredValue', 'maxStringLength:12', 'rutValidator']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, INVALID_RUT]}
        margin="normal"
        onChange={e => {
          dispatch({
            [e.target.name]: getFormatRut(e.target.value.toUpperCase(), { maxLength: 12 })
          });
        }}
        onBlur={e => {
          if (e.target.value)
            dispatch({
              [e.target.name]: getFormatRut(e.target.value.toUpperCase(), { maxLength: 12 })
            });
        }}
      />
      <TextValidator
        id="name"
        name="name"
        required
        label={BUSINESS_NAME}
        inputProps={{ maxLength: 100 }}
        validatorListener={validatorListener}
        className={classes.textField}
        autoComplete="off"
        value={name}
        validators={['requiredValue', 'maxStringLength:100']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateBussinessName(e.target.value) });
        }}
        onBlur={e => {
          if (e.target.value) dispatch({ [e.target.name]: validateBussinessName(e.target.value) });
        }}
      />
      <TextValidator
        id="email"
        name="email"
        required
        value={email}
        label={ADMINISTRATOR_MAIL}
        validatorListener={validatorListener}
        inputProps={{ maxLength: 100 }}
        className={classes.textField}
        autoComplete="off"
        validators={['requiredValue', 'maxStringLength:100', 'isEmail']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, EMAIL_ERROR]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateEmailCharacters(e.target.value) });
        }}
        onBlur={e => {
          if (e.target.value)
            dispatch({ [e.target.name]: validateEmailCharacters(e.target.value) });
        }}
      />

      <InputMaskValidatorElement
        id="phoneNumber"
        label={PHONE_NUMBER}
        format="+(56)##########"
        name="phoneNumber"
        required
        className={classes.textField}
        errorClassName={classes.errorValidation}
        autoComplete="off"
        validatorListener={validatorListener}
        containerClassName={classes.inputDiv}
        validators={['requiredValue', 'maxStringLength:10', 'minStringLength:9']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, MIN_STRING_LENGTH_PHONE]}
        value={phoneNumber}
        onChange={e => {
          dispatch({
            phoneNumber: getFormatNumber(e.value, { maxLength: 10 })
          });
        }}
        onBlur={e => {
          if (e.value)
            dispatch({
              phoneNumber: getFormatNumber(e.value, { maxLength: 10 })
            });
        }}
      />

      <SelectValidatorElement
        id="branchOffices"
        name="branchOffices"
        required
        validatorListener={validatorListener}
        value={branchOffices}
        placeholder={BRANCH_OFFICES}
        isMulti
        options={branchOffices.length === maxBranchOffices ? [] : branches}
        noOptionsMessage={() =>
          branchOffices.length === maxBranchOffices ? MAX_OPTIONS_SELECTED : NO_OPTION_LABELS
        }
        sref={inputRef}
        validators={['requiredValueArray']}
        errorMessages={[REQUIRED_BRANCH_FIELD]}
        onChange={e => {
          dispatch({ branchOffices: e });
        }}
      />
      <div className={classes.footerSaveButton}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={!onlineStatus || !disabled}
        >
          {SAVE}
        </Button>
      </div>
    </ValidatorForm>
  );
};

export default withStyles(styles)(Form);
