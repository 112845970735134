import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DialogBox = ({ open, title, description, onOK, onCancel }) => {
  const [openState, setOpenState] = useState(open);

  useEffect(() => {
    setOpenState(open);
  }, [open]);

  const closeHandler = () => {
    setOpenState(false);
  };

  return (
    <>
      <Dialog
        open={openState}
        // onClose={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description.map(e => (
              <>
                {e}
                <br />
              </>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(closeHandler, onCancel)} color="primary" autoFocus>
            Cancelar entrada
          </Button>
          <Button onClick={(closeHandler, onOK)} color="primary">
            Permitir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DialogBox.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onOK: PropTypes.func,
  onCancel: PropTypes.func
};

DialogBox.defaultProps = {
  open: false,
  title: '',
  description: '',
  onOK: () => {},
  onCancel: () => {}
};

export default DialogBox;
