import axios from 'axios';
import axiosRetry from 'axios-retry';
import API_PUBLIC_SERVICE from './http';
import persistenceService from './persistence-service';
import { useMessageContext } from '../libraries/contexts';

const { REACT_APP_API_URL } = process.env;
const PREFIX = 'credentials';
const path = PREFIX;
export const SERVER_API = REACT_APP_API_URL;

const headers = {
  'Content-Type': 'application/json; charset=utf-8'
};

export const publicInstance = axios.create({
  baseURL: SERVER_API,
  timeout: 300000,
  crossdomain: true,
  headers
});

export const httpInstance = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

axiosRetry(publicInstance, {
  retries: 3
});

publicInstance.interceptors.response.use(
  response => response,
  error => {
    try {
      const [value, setValue] = useMessageContext();
      setValue(error);
    } catch (er) {}
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.toLowerCase().includes('token')
    ) {
      persistenceService.clearPersistentDataProps();
      window.location.reload();
    }
    throw error;
  }
);

publicInstance.interceptors.request.use(
  config => {
    const access_token = persistenceService.get('token');

    if (access_token !== null) {
      config.headers.Authorization = `${access_token}`;
    }
    if (!access_token && !config.url.includes('login')) {
      window.location.reload();
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

const getAllAvailables = async () => {
  const res = await publicInstance.get(`${SERVER_API}${path}/allAvailables`);
  const { data } = await res;
  const { results } = data;
  return results;
};

const InsuranceService = {
  ...API_PUBLIC_SERVICE,
  path,
  getAllAvailables
};

export default InsuranceService;
