import React, { useContext } from 'react';
import VerifyId from './verify-id';
import AddVisitor from './add-visitor';
import SearchGuest from './search-guest';
import RegisterVisit from './registerVisit';
import StepsContext from '../../../contexts/stepsContext';

const componentsSteps = [<VerifyId />, <AddVisitor />, <SearchGuest />, <RegisterVisit />];

const FormsRegister = () => {
  const steps = useContext(StepsContext);
  const { step } = steps;

  return <React.Fragment>{componentsSteps[step.page]}</React.Fragment>;
};

export default FormsRegister;
