import axios from 'axios';
import axiosRetry from 'axios-retry';
import persistenceService from './persistence-service';
import { useMessageContext } from '../libraries/contexts';

const { REACT_APP_API_URL } = process.env;

export const SERVER_API = REACT_APP_API_URL;

const headers = {
  'Content-Type': 'application/json; charset=utf-8'
};

export const publicInstance = axios.create({
  baseURL: SERVER_API,
  timeout: 300000,
  crossdomain: true,
  headers
});

export const httpInstance = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

publicInstance.interceptors.response.use(
  response => response,
  error => {
    try {
      const [value, setValue] = useMessageContext();
      setValue(error);
    } catch (er) {}
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.toLowerCase().includes('token')
    ) {
      persistenceService.clearPersistentDataProps();
      window.location.reload();
    }
    throw error;
  }
);

publicInstance.interceptors.request.use(
  config => {
    const access_token = persistenceService.get('token');

    if (access_token !== null) {
      config.headers.Authorization = `${access_token}`;
    }
    if (!access_token && !config.url.includes('login')) {
      window.location.reload();
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

axiosRetry(publicInstance, {
  retries: 3
});

export const JString = json => JSON.stringify(json);

const API_PUBLIC_SERVICE = {
  async get(id) {
    const res = await publicInstance.get(`${SERVER_API}${this.path}/${id}`);
    const { data } = res;
    return data;
  },
  async getAll() {
    const res = await publicInstance.get(`${SERVER_API}${this.path}/all`);
    const { data } = await res;
    const { results } = data;
    return results;
  },
  async getResult() {
    const res = await publicInstance.get(`${SERVER_API}${this.path}/`);
    const { data } = await res;
    const { results } = data;
    return results;
  },
  async put(id, json) {
    try {
      const res = await publicInstance.put(`${SERVER_API}${this.path}/${id}`, JString(json));
      const { data } = await res;
      return data;
    } catch (err) {
      const {
        response: { data }
      } = await err;

      throw data;
    }
  },
  async undo(id) {
    const res = await publicInstance.post(`${SERVER_API}${this.path}/undo/${id}`, JString({}));
    const { data } = res;
    return data;
  },
  async delete(id) {
    const res = await publicInstance.delete(`${SERVER_API}${this.path}/${id}`);
    const { data } = res;
    return data;
  },
  async getPaginated({
    order = '',
    orderBy = '',
    page = 1,
    rowsPerPage = 10,
    filterData = ''
  } = {}) {
    const filtered = `?size=${rowsPerPage}&page=${page}&filter=${filterData}&sort=${order}${orderBy}`;
    const res = await publicInstance.get(`${SERVER_API}${this.path}/${filtered}`);
    const { data } = await res;
    return data;
  },
  async post(json) {
    try {
      const res = await publicInstance.post(`${SERVER_API}${this.path}`, JString(json));
      const { data } = await res;
      return data;
    } catch (err) {
      const {
        response: { data }
      } = await err;

      throw data;
    }
  },
  async bulk(json) {
    try {
      const { data } = await publicInstance.post(`${SERVER_API}${this.path}/bulk`, JString(json));
      return data;
    } catch (err) {
      const {
        response: { data }
      } = await err;
      throw data;
    }
  },
  async save(json) {
    let res = {};
    const { _id: id } = json;

    try {
      if (id) {
        res = await publicInstance.put(`${SERVER_API}${this.path}/${id}`, JString(json));
      } else {
        res = await publicInstance.post(`${SERVER_API}${this.path}`, JString(json));
      }

      const { data } = await res;
      return data;
    } catch (err) {
      const {
        response: { data }
      } = await err;

      throw data;
    }
  }
};

export default API_PUBLIC_SERVICE;
