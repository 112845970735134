import React, { useEffect, useState, useReducer } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import CardContent from '@material-ui/core/CardContent';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import useOnlineStatus from '@rehooks/online-status';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import VirtualizedTable from '../../components/table';
import ActionTable from '../../components/actionTable';
import CompaniesService from '../../services/companies';
import { AddIcon } from '../../icons/customsIcons';
import { cleanDots, getFormatRut } from '../../libraries/utils';

import {
  TABLE_HEAD_COMPANY_NAME,
  TABLE_HEAD_ID_COMPANY,
  SEARCH,
  NO_DATA,
  EMAIL,
  LOADING,
  CREATE,
  PHONE_NUMBER,
  LOCATION
} from '../../libraries/texts';

import { generalClasses as styles } from './styles';

const setRowsReducer = (prevRows, { newRows = [], type }) => {
  switch (type) {
    case 'add':
      return [...prevRows, ...newRows];
    case 'set':
      return newRows;
    default:
      return prevRows;
  }
};
const List = props => {
  const columns = [
    {
      minWidth: 150,
      label: TABLE_HEAD_ID_COMPANY,
      dataKey: 'rut'
    },
    {
      minWidth: 150,
      label: TABLE_HEAD_COMPANY_NAME,
      dataKey: 'name'
    },
    {
      minWidth: 150,
      label: LOCATION,
      style: { color: 'red' },
      styleColumn: { display: 'flex', justifyContent: 'center' },
      dataKey: 'branchOffices',
      cellDataGetter: ({ columnData, dataKey, rowData }) => {
        const locations = rowData[dataKey].map(elem => elem.label).join(' - ');

        return rowData[dataKey].length > 1 ? (
          <Tooltip title={locations}>
            <Badge
              className={classes.margin}
              badgeContent={rowData[dataKey].length}
              color="primary"
            >
              <LocationOnOutlinedIcon />
            </Badge>
          </Tooltip>
        ) : (
          locations.split(' - ')[0]
        );
      }
    },
    {
      minWidth: 200,
      label: EMAIL,
      dataKey: 'email'
    },
    {
      minWidth: 100,
      label: PHONE_NUMBER,
      dataKey: 'phoneNumber'
    },
    {
      minWidth: 150,
      label: '',
      dataKey: '',
      cellDataGetter: ({ columnData, dataKey, rowData }) => (
        <ActionTable
          entity={rowData}
          to="/companies"
          service={CompaniesService}
          getRequests={getRequests}
        />
      )
    }
  ];
  const columnsMobile = [
    {
      minWidth: 130,
      label: TABLE_HEAD_ID_COMPANY,
      dataKey: 'rut'
    },
    {
      minWidth: 150,
      label: TABLE_HEAD_COMPANY_NAME,
      dataKey: 'name'
    }
  ];

  const detectMobile = useMobileDetect();
  const { classes, formControlClass = classes.middleWidth } = props;

  const onlineStatus = useOnlineStatus();
  const [rows, setRows] = useReducer(setRowsReducer, []);
  const [pageStatus, setPageStatus] = useState({ currentPage: 1, hasNext: true, total: 0 });
  const [searchText, setSearchText] = useState({ curr: '', last: '' });
  const [isLoading, setIsLoading] = useState(true);

  const getRequests = async (filterData = '', page = 1) => {
    const type = page <= 1 ? 'set' : 'add';
    const visitsList = await CompaniesService.getPaginated({
      filterData,
      page,
      order: 'name'
    });

    const { results, currentPage, hasNext, count: total } = visitsList;
    const newRows = results.map(item => {
      const rut = getFormatRut(item.rut);
      return { ...item, rut };
    });
    setRows({ newRows, type });
    setPageStatus({ currentPage, hasNext, total });
    setIsLoading(false);
  };

  useEffect(() => {
    getRequests();
  }, []);

  const handleSearch = e => {
    const { curr, last } = searchText;
    if (curr !== last) {
      setIsLoading(true);
      setSearchText({ ...searchText, last: curr });
      getRequests(curr);
    }
  };

  const handleChange = e => {
    let text = e.target.value;
    text = cleanDots(text);
    setSearchText({ ...searchText, curr: text });
  };

  const isRowLoaded = hasItems => {
    if (!hasItems && pageStatus.hasNext) {
      setPageStatus({ ...pageStatus, hasNext: false });
      return false;
    }
    return true;
  };

  const loadMoreRows = e => {
    const pageToRequest = pageStatus.currentPage + 1;
    getRequests(searchText.curr, pageToRequest);
  };

  const renderTable = () => {
    return (
      <Paper className={classes.tablePaper}>
        <VirtualizedTable
          rowCount={rows.length}
          isRowLoaded={({ index }) => isRowLoaded(!!rows[index])}
          rowGetter={({ index }) => rows[index]}
          loadMoreRows={e => loadMoreRows(e)}
          remoteRowCount={pageStatus.total}
          columns={!detectMobile.isDesktop() ? columnsMobile : columns}
        />
      </Paper>
    );
  };

  const renderMessage = () => {
    return (
      <Typography variant="h5" component="h3" className={classes.tableNoData}>
        {isLoading ? LOADING : NO_DATA}
      </Typography>
    );
  };

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContent}>
        {
          <React.Fragment>
            <div
              className={classNames(
                formControlClass,
                classes.tableMiddleWidth,
                classes.serialControl
              )}
            >
              <ValidatorForm
                className={classes.tableFormSearch}
                onSubmit={async e => {
                  handleSearch(e);
                }}
              >
                <TextValidator
                  id="names"
                  name="names"
                  value={searchText.curr}
                  label={SEARCH}
                  className={classes.textField}
                  margin="normal"
                  type="search"
                  disabled={!onlineStatus}
                  onChange={e => handleChange(e)}
                  autoComplete="off"
                />
                <div className={classes.secondFunctionalities}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonSearch}
                    disabled={!onlineStatus}
                    type="submit"
                  >
                    {SEARCH}
                  </Button>
                </div>
                <div>
                  <Tooltip title={CREATE}>
                    <Button
                      component={NavLink}
                      to="/companies/create"
                      variant="contained"
                      disabled={!onlineStatus}
                      color="default"
                      className={classes.greenButton}
                    >
                      <AddIcon className={classes.rightIcon} />
                    </Button>
                  </Tooltip>
                </div>
              </ValidatorForm>
            </div>
            <div className={classes.tableContainer}>
              {rows.length ? renderTable() : renderMessage()}
            </div>
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(List);
