import { ValidatorForm } from 'react-material-ui-form-validator';

const rutRegex = /^(?:\d{1,3}\.){1,3}\d{1,3}-[0-9Kk]$/;
const emailRegex = /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+[.]{1}[A-Za-z]{2,64}/;
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export const requiredValue = value => {
  if (!value) {
    return false;
  }
  return true;
};

export const requiredValueArray = value => value.length >= 1;

const calculateDV = num => {
  if (num.length < 6 || num.length > 9) return -1;
  let m = 0;
  let s = 1;
  let digits = parseInt(num, 10);
  for (; digits; digits = Math.floor(digits / 10)) {
    // eslint-disable-next-line no-plusplus, no-mixed-operators
    s = (s + (digits % 10) * (9 - (m++ % 6))) % 11;
  }
  return s ? (s - 1).toString() : 'K';
};
const cleanFormatter = chileanNationalId =>
  chileanNationalId
    .toUpperCase()
    .split('.')
    .join('')
    .split('-');

export const isValidEmail = email => !email || emailRegex.test(email.trim());
export const isValidPassword = password => !password || passwordRegex.test(password.trim());

const checkCountPoints = (num, raw) =>
  parseInt((num.length - 1) / 3) === raw.split('.').length - 1 || raw.split('.').length - 1 === 0;

const checkValidDV = chileanNationalId => {
  const raw = chileanNationalId;
  const [num, dv] = cleanFormatter(chileanNationalId);
  return calculateDV(num) === dv && checkCountPoints(num, raw);
};

const checkFormat = chileanNationalId => rutRegex.test(chileanNationalId.trim());

export const isValidRut = chileanNationalId =>
  checkFormat(chileanNationalId) && checkValidDV(chileanNationalId);

export const isPasswordMatch = (value, comparisor) => value === comparisor;
export const isFullName = value => value.trim().indexOf(' ') >= 0;

export const isConditionatedBy = (value, conditional) => {
  const isTrueSet = conditional === 'true';
  if (isTrueSet) {
    return true;
  }
  if (isTrueSet && !value) {
    return false;
  }

  if (!value) {
    return false;
  }

  return true;
};

export const getValidations = () => {
  ValidatorForm.addValidationRule('requiredValue', requiredValue);
  ValidatorForm.addValidationRule('rutValidator', isValidRut);
  ValidatorForm.addValidationRule('isValidEmail', isValidEmail);
  ValidatorForm.addValidationRule('isValidPassword', isValidPassword);
  ValidatorForm.addValidationRule('isFullName', isFullName);
  ValidatorForm.addValidationRule('isConditionatedBy', isValidEmail);
  ValidatorForm.addValidationRule('requiredValueArray', requiredValueArray);
  ValidatorForm.addValidationRule('isPasswordMatch', isPasswordMatch);
};
