import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Close } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { useToggleNavBar } from '../../libraries/contexts';

import { snackBar as styles } from './css';

const snackBarShowTime = 5000;
const AppSnackBar = props => {
  const { classes } = props;

  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(false);
  const [snackBarTime, setSnackBarTime] = useState(snackBarShowTime);
  const [toggleValue, setToggleValue] = useToggleNavBar();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setMessageInfo({ key: '' });
  }, []);

  useEffect(() => {
    const { message } = toggleValue;

    if (message && message.open) {
      setMessageInfo(message);
      setOpen(true);
    }
  }, [toggleValue]);

  const handleUndo = async () => {
    try {
      const { main, secondary, message } = toggleValue;
      const { service, key, getRequests } = message;
      await service.undo(key);
      setToggleValue({
        main,
        secondary,
        message: { message: message.message, undo: true, key, service, open: false }
      });
      await getRequests();
      setOpen(false);
    } catch (err) {
      setSnackBarTime(20000);
    }
  };

  const handleExited = () => {
    setToggleValue({ ...toggleValue, message: { message: '', open: false } });
  };

  return (
    <React.Fragment>
      <Snackbar
        key={messageInfo.key}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={snackBarTime}
        onClose={handleClose}
        onExited={handleExited}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{messageInfo.message}</span>}
        action={[
          !toggleValue.undo ? (
            ''
          ) : (
            <Button
              key={`${messageInfo.key}-undo`}
              color="secondary"
              size="small"
              onClick={handleUndo}
            >
              DESHACER
            </Button>
          ),
          <Tooltip key={`${messageInfo.key}-tooltip`} title="Cerrar" placement="top">
            <IconButton
              key={`${messageInfo.key}-close`}
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Tooltip>
        ]}
      />
    </React.Fragment>
  );
};

AppSnackBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppSnackBar);
