/* eslint-disable no-underscore-dangle */
import React, { useReducer, useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { LinearProgress } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import useOnlineStatus from '@rehooks/online-status';
import BranchesService from '../../../services/branches';
import ImportButton from '../../../components/import';
import { getValidations } from '../../../libraries/validators';
import { validateFile, toBase64, VALID_EXTENSIONS } from './image-utils';
import { useToggleNavBar } from '../../../libraries/contexts';
import InputMaskValidatorElement from '../../../components/fields/inputWithMask';
import CheckboxValidatorElement from '../../../components/fields/checkBox';

import {
  validatePassword,
  getFormatNumber,
  validateEmailCharacters,
  save,
  latinAlphaNumericCharactersAllow
} from '../../../libraries/utils';
import {
  MAX_STRING_LENGTH,
  MIN_STRING_LENGTH_PHONE,
  REQUIRED_FIELD,
  EMAIL_ERROR,
  BUSINESS_NAME,
  ERROR_USER,
  NO_OPTION_LABELS,
  COMPANY_RUT,
  IS_FULLNAME,
  BRANCH_OFFICES,
  REQUIRED_BRANCH_FIELD,
  ADMINISTRATOR_MAIL,
  SAVE,
  PHONE_NUMBER,
  ADMIN_USER,
  NOT_VALID_PASSWORD_ERR_MSG,
  REQUIRED_BRANCH_OFFICE
} from '../../../libraries/texts';
import SelectValidatorElement from '../../../components/fields/selectSearch';
import CompaniesService from '../../../services/companies';
import settingService from '../../../services/settings';
import { generalClasses as styles } from './styles';

const maxCompany = 50;
const maxBranchOffices = 50;
const formReducer = (prevState, updatedProperty) => ({
  ...prevState,
  ...updatedProperty
});
const initialState = {
  codeCompany: '',
  fullName: '',
  name: '',
  phoneNumber: '',
  username: '',
  password: '',
  confirmPassword: '',
  branchOffice: ''
};

const Form = props => {
  const { classes } = props;
  const fileUploadInput = React.createRef();
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [companys, setCompanys] = useState([]);
  const [branches, setBranches] = useState([]);
  const [userSetting, setUserSetting] = useState({});
  const [editUser, setEditUser] = useState(false);
  const [showData, setShowData] = useState(editUser);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileError, setFileError] = useState([]);
  const [filename, setFilename] = useState('');
  const [, changeToggleNavBar] = useToggleNavBar();
  const onlineStatus = useOnlineStatus();
  const {
    fullName,
    branchOffice,
    phoneNumber,
    username,
    company,
    confirmPassword,
    password
  } = state;

  const getRequests = async () => {
    const setting = await settingService.getAll();
    const companysList = await CompaniesService.getAll();
    const getCompanys = companysList.map(item => ({
      value: item.rut,
      label: item.rut,
      name: item.name,
      id: item._id,
      branchOffices: item.branchOffices
    }));
    setUserSetting(setting);
    setCompanys(getCompanys);
  };

  useEffect(() => {
    async function getBranchOffices() {
      if (company === null) setBranches([]);
      if (company && company.branchOffices) {
        setBranches(company.branchOffices);
      }

      if (userSetting && userSetting.company && Object.keys(userSetting).length > 0) {
        setEditUser(true);
        let userBranchOffice = [];
        let findCompany = [];
        if (userSetting.branchOffice) {
          userBranchOffice = await BranchesService.get(userSetting.branchOffice);
          findCompany = companys.find(c => c.id === userSetting.company._id);
          if (findCompany) {
            userBranchOffice = findCompany.branchOffices.find(
              bo => bo.value === userBranchOffice.name
            );
            userBranchOffice = userBranchOffice
              ? { value: userBranchOffice.value, label: userBranchOffice.value }
              : '';
          }
        }
        dispatch({
          userId: userSetting._id,
          fullName: `${userSetting.name} ${userSetting.surname}`,
          phoneNumber: userSetting.phoneNumber,
          username: userSetting.username,
          company: findCompany,
          branchOffice: userBranchOffice
        });
      }
    }
    getBranchOffices();
  }, [company, companys, userSetting]);

  function handleUploadButton() {
    fileUploadInput.current.value = '';
    setFileError([]);
    fileUploadInput.current.click();
  }
  async function handleFileUpload(file) {
    try {
      setLoading(true);
      if (validateFile(file, setFileError)) {
        const logo = await toBase64(file);
        setFilename(file.name);
        dispatch({ logo });
      }
    } catch (error) {
      setFileError([]);
    } finally {
      setLoading(false);
    }
  }

  const submitForm = async () => {
    try {
      const { _id: hasId } = state;
      const result = await save(hasId, { ...state, editUser: showData }, settingService);

      if (result) {
        changeToggleNavBar({
          message: { message: 'Configuración exitosa', open: true }
        });

        return setSuccess(true);
      }
      return changeToggleNavBar({ message: { message: 'Not found', open: true } });
    } catch (err) {
      setSuccess(false);
      return changeToggleNavBar({
        message: { message: ERROR_USER[err.message], open: true }
      });
    }
  };

  useEffect(() => {
    async function fetchData() {
      getValidations();
      await getRequests();
    }

    fetchData();
  }, []);

  const validatorListener = async () => {
    const result = await formRef.current.isFormValid();
    setDisabled(result);
  };

  return success ? (
    <Redirect to="/visits/register" />
  ) : (
    <ValidatorForm
      novalidate
      className={classes.formVerify}
      ref={formRef}
      instantValidate
      onSubmit={submitForm}
    >
      {loading ? <LinearProgress /> : null}
      <SelectValidatorElement
        id="company"
        name="company"
        className={classes.selectField}
        required
        autoComplete="off"
        classes={classes}
        validatorListener={validatorListener}
        value={company}
        placeholder={COMPANY_RUT}
        options={companys.length === maxCompany ? [] : companys}
        noOptionsMessage={() => NO_OPTION_LABELS}
        sref={inputRef}
        validators={['requiredValue']}
        errorMessages={[REQUIRED_BRANCH_FIELD]}
        onChange={e => {
          dispatch({ company: e, branchOffice: '' });
        }}
      />

      <SelectValidatorElement
        id="branchOffices"
        name="branchOffices"
        margin="normal"
        className={classes.selectField}
        classes={classes}
        required
        autoComplete="off"
        validatorListener={validatorListener}
        value={branchOffice}
        placeholder={BRANCH_OFFICES}
        options={branches.length === maxBranchOffices ? [] : branches}
        noOptionsMessage={() => NO_OPTION_LABELS}
        sref={inputRef}
        validators={['requiredValue']}
        errorMessages={[REQUIRED_BRANCH_OFFICE]}
        onChange={e => {
          dispatch({ branchOffice: e });
        }}
      />

      <TextValidator
        id="name"
        name="name"
        disabled
        label={BUSINESS_NAME}
        className={classes.textField}
        autoComplete="off"
        value={company ? company.name : ''}
        margin="normal"
      />
      <div style={{ display: editUser ? 'block' : 'none' }}>
        <CheckboxValidatorElement
          label="Cambiar Usuario"
          value={showData}
          name="showPassword"
          onChange={async e => {
            await setShowData(!showData);
            validatorListener(validatorListener);
          }}
        />
      </div>
      <TextValidator
        id="fullName"
        name="fullName"
        required={!editUser || showData}
        style={{ display: !editUser || showData ? 'inline-flex' : 'none' }}
        label={ADMIN_USER}
        placeholder="Ingrese nombre y apellido"
        inputProps={{ maxLength: 30 }}
        className={classes.textField}
        autoComplete="off"
        value={fullName}
        validatorListener={validatorListener}
        validators={
          !editUser || showData ? ['requiredValue', 'maxStringLength:30', 'isFullName'] : []
        }
        errorMessages={
          !editUser || showData ? [REQUIRED_FIELD, MAX_STRING_LENGTH, IS_FULLNAME] : []
        }
        margin="normal"
        onChange={e => {
          dispatch({
            [e.target.name]: latinAlphaNumericCharactersAllow(e.target.value)
          });
        }}
      />
      <TextValidator
        id="username"
        name="username"
        required={!editUser || showData}
        style={{ display: !editUser || showData ? 'inline-flex' : 'none' }}
        value={username}
        label={ADMINISTRATOR_MAIL}
        validatorListener={validatorListener}
        inputProps={{ maxLength: 30 }}
        className={classes.textField}
        autoComplete="off"
        validators={
          !editUser || showData ? ['requiredValue', 'maxStringLength:100', 'isEmail'] : []
        }
        errorMessages={
          !editUser || showData ? [REQUIRED_FIELD, MAX_STRING_LENGTH, EMAIL_ERROR] : []
        }
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateEmailCharacters(e.target.value) });
        }}
      />
      <TextValidator
        id="password"
        required={!editUser || showData}
        style={{ display: !editUser || showData ? 'inline-flex' : 'none' }}
        name="password"
        label="Contraseña"
        inputProps={{ maxLength: 20 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={password}
        type="password"
        validators={
          !editUser || showData
            ? ['requiredValue', 'maxStringLength:20', 'minStringLength:8', 'isValidPassword']
            : []
        }
        errorMessages={
          !editUser || showData
            ? [REQUIRED_FIELD, MAX_STRING_LENGTH, 'Mínimo 8 caracteres', NOT_VALID_PASSWORD_ERR_MSG]
            : []
        }
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validatePassword(e.target.value) });
        }}
      />

      <TextValidator
        id="confirmPassword"
        required={!editUser || showData}
        style={{ display: !editUser || showData ? 'inline-flex' : 'none' }}
        name="confirmPassword"
        label="Confirmar Contraseña"
        inputProps={{ maxLength: 20 }}
        className={classes.textField}
        validatorListener={validatorListener}
        type="password"
        value={confirmPassword}
        validators={
          !editUser || showData
            ? [
                `isPasswordMatch:${password}`,
                'requiredValue',
                'minStringLength:8',
                'isValidPassword'
              ]
            : []
        }
        errorMessages={
          !editUser || showData
            ? [
                'Las contraseñas deben coincidir',
                REQUIRED_FIELD,
                'Mínimo 8 caracteres',
                NOT_VALID_PASSWORD_ERR_MSG
              ]
            : []
        }
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validatePassword(e.target.value) });
        }}
      />

      <InputMaskValidatorElement
        id="phoneNumber"
        label={PHONE_NUMBER}
        format="+(56)##########"
        name="phoneNumber"
        required={!editUser || showData}
        style={{ display: !editUser || showData ? 'inline-flex' : 'none' }}
        className={classes.selectField}
        errorClassName={classes.errorValidation}
        autoComplete="off"
        validatorListener={validatorListener}
        containerClassName={classes.inputDiv}
        validators={
          !editUser || showData ? ['requiredValue', 'maxStringLength:11', 'minStringLength:9'] : []
        }
        errorMessages={
          !editUser || showData ? [REQUIRED_FIELD, MAX_STRING_LENGTH, MIN_STRING_LENGTH_PHONE] : []
        }
        value={phoneNumber}
        onChange={e => {
          dispatch({
            phoneNumber: getFormatNumber(e.value, { maxLength: 10 })
          });
        }}
      />

      <div className={classes.formCard}>
        <div className={classes.cardColumn}>
          <ImportButton
            errorMessage={fileError}
            onClick={handleUploadButton}
            disabled={!onlineStatus}
            footer="Formato JPG, PNG y JPEG max 10MB"
          />
        </div>
        <div className={classes.cardColumn}>{filename}</div>
        <input
          ref={fileUploadInput}
          type="file"
          style={{ display: 'none' }}
          id="fileUpload"
          onChange={async v => v.target.files.length && handleFileUpload(v.target.files[0])}
          accept={VALID_EXTENSIONS.toString()}
        />
      </div>

      <div className={classes.footerSaveButton}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={!disabled || !onlineStatus}
        >
          {SAVE}
        </Button>
      </div>
    </ValidatorForm>
  );
};

export default withStyles(styles)(Form);
