import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepsContext from '../../contexts/stepsContext';

const styles = theme => ({
  root: {
    width: '90%'
  },
  backButton: {
    marginRight: '1px'
  },
  instructions: {
    marginTop: '1px',
    marginBottom: '1px'
  }
});

const Steps = props => {
  const { steps: initialState } = props;
  const stepsContext = useContext(StepsContext);
  const { step } = stepsContext;
  const classes = styles();
  const [initialSteps] = useState(initialState);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={step.page}>
        {initialSteps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default withStyles(styles)(Steps);
