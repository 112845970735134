/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { REQUIRED_FIELD } from '../../libraries/texts';

const selectStyles = {
  control: styles => ({
    ...styles,
    border: 'none',
    borderBottom: '1px solid gray',
    borderRadius: '0px',
    boxShadow: '0 !important',
    '&:hover': {
      borderBottom: '2px solid gray'
    },
    '.css-1hwfws3': {
      padding: '1px'
    }
  }),
  placeholder: styles => ({
    ...styles,
    font: 'inherit',
    color: 'gray',
    fontWeight: '500'
  })
};

const labelStyle = {
  fontSize: '12px',
  color: 'gray',
  marginTop: '20px'
};

const SearchableSelect = props => {
  const {
    value,
    required,
    placeholder,
    label,
    isClearable,
    isSearchable,
    isDisabled,
    hasError,
    isMulti,
    options,
    className,
    onBlur,
    noOptionsMessage,
    onChange,
    ...rest
  } = props;

  return (
    <>
      <div style={labelStyle}>{label}</div>
      <Select
        placeholder={<div>{placeholder}</div>}
        styles={selectStyles}
        value={value}
        onBlur={onBlur}
        hasError={hasError}
        className={className}
        isMulti={isMulti}
        required={required}
        onChange={onChange}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        noOptionsMessage={noOptionsMessage}
        {...rest}
      />
      {hasError ? <div style={{ color: 'red' }}>{REQUIRED_FIELD}</div> : null}
    </>
  );
};

SearchableSelect.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

SearchableSelect.defaultProps = {
  value: undefined,
  placeholder: null,
  isClearable: true,
  isMulti: false,
  isSearchable: true,
  isDisabled: false,
  required: false,
  hasError: false,
  label: null,
  onBlur: () => {},
  onChange: () => {},
  noOptionsMessage: () => {}
};

export default SearchableSelect;
