import DailyClosing from '../pages/configuration/daily-closing';
import GuestsUpdate from '../pages/configuration/guests-update';
import SettingsCreate from '../pages/configuration/settings';
import { checkAuthorizationView } from '../services/authorization-service';

export const DAILY_CLOSING_PATH = '/daily-closing';
export const SETTINGS_CREATE = '/settings';

export const DAILY_CLOSING_PATHS = [
  {
    path: DAILY_CLOSING_PATH,
    exact: true,
    component: checkAuthorizationView(DailyClosing, ['CONFIG_DAILY_CLOSING'])
  }
];
export const SETTINGS_CREATE_PATHS = [
  {
    path: SETTINGS_CREATE,
    exact: true,
    component: checkAuthorizationView(SettingsCreate, ['CONFIG_SETTINGS_COMPANY'])
  }
];

export const GUESTS_UPDATE_PATH = '/guests-update';

export const GUESTS_UPDATE_PATHS = [
  {
    path: GUESTS_UPDATE_PATH,
    exact: true,
    component: checkAuthorizationView(GuestsUpdate, ['CONFIG_GUESTS_UPDATE'])
  }
];

export const configurationFormRouters = [
  ...DAILY_CLOSING_PATHS,
  ...SETTINGS_CREATE_PATHS,
  ...GUESTS_UPDATE_PATHS
];
