import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { generalClasses as styles } from './styles';
import VisitedsList from './list';

const List = props => {
  const { classes } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title="Visitados" />
      <CardContent className={classes.cardContent}>
        <VisitedsList />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(List);
