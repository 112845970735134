import React from 'react';
import Turnstiles from './turnstiles';
import BlackList from './blackListIcon';
import Credentials from './credentials';
import Visiteds from './visiteds';
import Imports from './imports';
import Exports from './exports';
import Add from './add';
import Garbage from './garbage';
import Edit from './edit';
import Users from './users';
import Unauthorazed from './404';

export const TurnstilesIcon = () => <Turnstiles />;
export const UnauthorazedIcon = () => <Unauthorazed />;
export const BlackListIcon = () => <BlackList />;
export const CredentialsIcon = () => <Credentials />;
export const VisitedsIcon = () => <Visiteds />;
export const ImportIcon = () => <Imports />;
export const ExportIcon = () => <Exports />;
export const AddIcon = () => <Add />;
export const EditIcon = () => <Edit />;
export const GarbageIcon = () => <Garbage />;

export const UsersIcon = () => <Users />;
