import React, { useContext } from 'react';
import SearchVisit from './searchVisit';
import CloseVisit from './closeVisit';
import StepsContext from '../../../contexts/stepsContext';

const componentsSteps = [<SearchVisit />, <CloseVisit />];

const FormsRegister = () => {
  const steps = useContext(StepsContext);
  const { step } = steps;

  return <React.Fragment>{componentsSteps[step.page]}</React.Fragment>;
};

export default FormsRegister;
