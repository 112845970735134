import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {
    flexGrow: 1
  },

  linearColorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  linearBarColorPrimary: {
    backgroundColor: '#e2f4ff'
  }
});

function LoadingProgress(props) {
  const { classes } = props;
  return (
    <Paper className={classes.root}>
      <LinearProgress
        classes={{
          colorPrimary: classes.linearColorPrimary,
          barColorPrimary: classes.linearBarColorPrimary
        }}
      />
    </Paper>
  );
}

LoadingProgress.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingProgress);
