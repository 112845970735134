export const OFFLINE_ERROR = 'No hay conexión. Compruebe la conexión y vuelva a intentarlo.';
export const NOT_VALID_PASSWORD_ERR_MSG =
  'Debe tener al menos un número, una letra minúscula y mayúscula';
export const UNAUTHORIZED = 'Página no encontrada';
export const REGISTER_VISIT_TITLE = 'Registrar Visitas';
export const VERIFY_VISIT_ID = 'Verificar ID';
export const VERIFY_ID = 'Verificar ID del visitante';
export const SEARCH = 'Buscar';
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';
export const DATE_TO = 'Fecha Hasta';
export const DATE_FROM = 'Fecha Desde';
export const IDENTIFICATION_TYPE = 'Tipo de identificación *';
export const IDENTIFICATION_NUMBER = 'Ingresar Nº de identificación';
export const DATE_RANGE_VALIDATION_MSG =
  'La fecha y hora Hasta debe ser mayor o igual a la fecha y hora Desde';
export const IDENTIFICATION_NUMBER_SCAN = 'Escanear Nº de RUT';
export const REGISTER_TITLE_STEP_2 = 'Registrar Visitante';
export const SEARCH_GUEST = 'Buscar personal a visitar';
export const SEARCH_VISIT = 'Colaborador que ingresa';
export const VISITOR_NAMES = 'Nombres';
export const VISITOR_SURNAME = 'Apellido Paterno';
export const VISITOR_SECOND_SURNAME = 'Apellido Materno';
export const VISITOR_COUNTRY = 'Nacionalidad';
export const REGISTER_STEP_2_BTN = 'Registrar';
export const CONTINUE = 'Continuar';
export const BACK = 'Volver';
export const REQUIRED_FIELD = 'El campo es requerido';
export const REQUIRED_BRANCH_FIELD = 'Debes seleccionar una empresa';
export const INVALID_RUT = 'RUT inválido';
export const MAX_STRING_LENGTH = 'excede el nº de caracteres';
export const MIN_STRING_LENGTH_PHONE = 'Mínimo 9 caracteres';
export const MIN_STRING_LENGTH_CODE_COMPANY = 'mínimo 3 caracteres';
export const SELECT_LABEL = 'Selecciona una opción';
export const NO_OPTION_LABELS = 'No existen registros';
export const COUNTRY_PLACEHOLDER = 'Seleccione una nacionalidad';
export const GUEST_VISIT = 'Nombre de la persona a visitar';
export const TABLE_NAME = 'Nombre';
export const SELECT_GUEST = 'Seleccione un visitado';
export const SELECT_VISIT = 'Seleccione un colaborador';
export const REGISTER_VISIT = 'Registrar entrada visita';
export const START = 'Iniciar';
export const TABLE_ROLE = 'Rol';
export const SELECT_CREDENTIAL = 'Seleccione tarjeta';
export const SELECT_ACCESS = 'Seleccione acceso';
export const ACCESS = 'Acceso';
export const CREDENTIAL = 'Credencial de tarjeta';
export const ASSOCIATED_CREDENTIAL = 'Asociar Tarjeta';
export const LENT_CREDENTIAL = 'Tarjeta Entregada';
export const ADDRESS = 'Dirección';
export const START_DATE = 'Fecha y hora de entrada:';
export const GUEST_VISITED = 'Visitado:';
export const FORMAT_DATE = 'YYYY/MM/DD';
export const EMAIL_ERROR = 'Email con formato inválido';
export const FORMAT_HOUR = 'HH:mm:ss';
export const VISITOR_ORIGIN = 'Empresa procedente';
export const PARTICULAR = 'Particular';
export const COMPANION = 'Acompañante';
export const QUANTITY = 'Cantidad';
export const RECEPTION = 'Recepción';
export const MAX_OPTIONS_SELECTED = 'Ha seleccionado el máximo permitido';
export const REASON = 'Motivo';
export const OBSERVATION = 'Observación';
export const REGISTER_VISIT_SUCCESS = 'La visita ha sido iniciada';
export const REGISTER_VISIT_FAIL = 'No fue posible iniciar la visita, intente mas tarde.';
export const CLOSE_VISIT_SUCCESS = 'Visita cerrada';
export const CLOSE_VISIT_FAIL = 'No fue posible cerrar la visita, intente mas tarde.';
export const CLOSE_VISIT = 'Cerrar Visita';
export const ADD_TO_BLACKLIST = 'Añadir a Lista Negra';
export const BLACK_LIST = 'Personas en Lista Negra';
export const GUEST_PHONE_ANNEX = 'Anexo';
export const CLOSE_VISIT_DATE = 'Fecha y hora de salida:';
export const VISITOR = 'Visitante:';
export const FAIL_RUT_SCAN = 'Error en lectura, intente de nuevo';
export const SCAN_LABEL = 'Manual';
export const HAS_VISIT_ACTIVE_MSG = 'El visitante tiene visita iniciada';

export const VISIT_REPORT_PAGE = {
  SEARCH_TEXT: 'Buscar',
  TABLE_HEAD_ID: 'RUT / Pasaporte',
  TABLE_HEAD_VISITOR_NAME: 'Visitante',
  TABLE_HEAD_VISITOR_IN: 'Ingreso',
  TABLE_HEAD_GUEST_NAME: 'Visitado',
  TABLE_HEAD_DATE_TIME: 'Fecha/hora',
  TABLE_HEAD_TYPE_CLOSED: 'Tipo de cierre',
  TABLE_HEAD_CREDENTIAL: 'Tarjeta',
  TABLE_HEAD_PATENTE: 'Patente',
  TABLE_HEAD_FACTURA: 'Factura',
  TABLE_HEAD_GUIA: 'Guía',
  TABLE_HEAD_SELLO: 'Sello',
  TABLE_HEAD_MANUAL: 'Manual',
  TABLE_HEAD_USER: 'Usuario',
  TABLE_HEAD_COMPANY_NAME: 'Empresa',
  TABLE_HEAD_ACCESS: 'Acceso'
};

export const CLOSE_VISIT_PAGE = {
  TITLE_STEP_1: 'Visitas Iniciadas',
  TITLE_STEP_2: 'Cerrar Visita',
  SEARCH_TEXT: 'Buscar',
  TABLE_HEAD_ID: 'RUT / Pasaporte',
  TABLE_HEAD_VISITOR_NAME: 'Visitante',
  TABLE_HEAD_VISITOR_IN: 'Ingreso',
  TABLE_HEAD_GUEST_NAME: 'Visitado',
  TABLE_HEAD_CREDENTIAL: 'Tarjeta',
  TABLE_HEAD_PATENTE: 'Patente'
};
export const TABLE_HEAD_ID = 'RUT / Pasaporte';
export const TABLE_DESCRIPTION = 'Descripción';
export const TABLE_HEAD_ID_COMPANY = 'RUT';
export const TABLE_HEAD_NAME = 'Visitante';
export const TABLE_HEAD_COMPANY_NAME = 'Razón Social';
export const TABLE_HEAD_ADDRESS = 'Dirección';
export const TABLE_HEAD_NAME_BRANCH_OFFICES = 'Nombre';
export const LOCATION = 'Sucursal';
export const NO_DATA = 'No existen registros';
export const ORIGIN = 'Procedencia';
export const EMAIL = 'Email';
export const PHONE_NUMBER = 'Teléfono';
export const REPORTED_BY = 'Reportado por';
export const LOADING = 'Cargando...';
export const IMPORT = 'Importar';
export const EXPORT = 'Exportar';

export const SIZE_FORMAT_IMPORT = 'Formato CSV max 10MB';
export const UPDATED = 'Actualizada';
export const CREATED = 'Creada';

export const INTEGRATED_GEN = 'Integrada GenHoras';
export const COMPANY_CODE = 'Codigo empresa';
export const COMPANY_RUT = 'RUT empresa';
export const BUSINESS_NAME = 'Razón social';
export const BRANCH_OFFICES = 'Sucursales *';
export const ADMINISTRATOR_MAIL = 'Email administrador';
export const NAME = 'Nombre';
export const SAVE = 'Guardar';
export const CREATE = 'Crear';
export const FLOOR_OFFICE = 'Piso/Oficina';
export const COMPANIES = 'Empresas';
export const MASK_PHONE_NUMBER = '+(56)##########';

export const ERROR_COMPANY = {
  'Validation Error': 'Error de validación',
  DuplicateKeyError: 'Empresa ya existe'
};
export const ERROR_USER = {
  'Validation Error': 'Error de validación',
  DuplicateKeyError: 'Usuario ya existe'
};
export const ERROR_CREDENTIALS = {
  'Validation Error': 'Error de validación',
  DuplicateKeyError: 'Tarjeta ya existe'
};

export const ERROR_ACCESSES = {
  'Validation Error': 'Error de validación',
  DuplicateKeyError: 'Acceso ya existe'
};
export const ERROR_USERS = {
  'Validation Error': 'Error de validación',
  DuplicateKeyError: 'Usuario ya existe',
  withoutCompany: 'Debe configurar la empresa administradora'
};
export const ERROR_BRANCHES_OFFICES = {
  'Validation Error': 'Error de validación',
  DuplicateKeyError: 'Sucursal ya existe'
};
export const FILE_SIZE_IS_TOO_BIG = 'Excede el tamaño permitido';
export const INVALID_FILE_EXTENSION = 'Formato inválido';
export const REQUIRED_FIELDS_CSV = fields =>
  `Existen campos obligatorios vacíos. (${fields}, son obligatorios)`;
export const INVALID_CSV_DATA = fields => `Existe data inválida. Revisar: ${fields}`;

export const DUPLICATED_BLACK_LIST_USER = (numberId = '11.111.111-1') =>
  `Visitante en lista negra duplicado: Nº de Documento: ${numberId}`;
export const TITLE_IMPORT_ERROR = 'Resumen de importación';
export const ACCEPT = 'Aceptar';
export const DUPLICATED_BLACK_LIST_USER_ON_BD = (numberId = '11.111.111-1') =>
  `Visitante Nº de Documento ${numberId} ya está en lista negra.`;
export const INVALID_ADDRESS = 'Dirección con formato inválido';
export const COMPANY = 'Empresa';
export const POSITION = 'Cargo';
export const DUPLICATED_VISITED_USER = (visited = '11.111.111-1') =>
  `Visitado duplicado. Nº de Documento ${visited}`;
export const MISSING_COMPANY = companyId =>
  `El RUT de Empresa ${companyId} no existe dentro del sistema`;
export const TABLE_CREDENTIALS = 'Credencial';
export const TABLE_STATE = 'Estado';
export const NOT_MATCHING_BRANCH_OFFICE = branchOffice =>
  `La sucursal "${branchOffice}" no corresponde a la empresa ingresada o no existe en el sistema.`;
export const AVAILABLE = 'Disponible';
export const LOST = 'Extraviada';
export const ACTIVE = 'En uso';
export const USER = 'Usuario';
export const ADMIN = 'Administrador';
export const SUPERVISOR = 'Supervisor';
export const SUPER_ADMIN = 'Super administrador';
export const IS_FULLNAME = ' Debe ingresar Nombre y Apellido separado de un espacio';
export const ROLE_LIST = [
  {
    value: 'USER',
    label: USER
  },
  {
    value: 'ADMIN',
    label: ADMIN
  },

  {
    value: 'SUPERVISOR',
    label: SUPERVISOR
  }
];
export const ROLES = {
  USER,
  SUPER_ADMIN,
  SUPERVISOR,
  ADMIN
};
export const SUCCESSFUL_LOAD = 'Carga exitosa';
export const TYPE_CLOSE = 'Tipo Cierre:';
export const TYPES_OF_CLOSED = { MANUAL: 'Manual', TARJETA: 'Manual', AUTO: 'Automático' };
export const ADMIN_USER = 'Usuario Administrador';
export const REQUIRED_BRANCH_OFFICE = 'Debes seleccionar una sucursal';
