/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import { REQUIRED_FIELD } from '../../libraries/texts';

const useStyles = theme => ({
  formControl: {
    margin: 1,
    minWidth: 120,
    width: '100%'
  },

  selectEmpty: {
    marginTop: 2
  }
});

const SimpleSelect = props => {
  const classes = useStyles();
  const {
    value,
    required,
    label,
    isClearable,
    isSearchable,
    isDisabled,
    hasError,
    disabled,
    isMulti,
    options = [],
    className,
    onBlur,
    noOptionsMessage,
    ...rest
  } = props;

  return (
    <>
      <FormControl className={classes.formControl} disabled={disabled}>
        <InputLabel htmlFor="age-native-helper">{label}</InputLabel>
        <NativeSelect value={value} {...rest}>
          {options.map(item => (
            <option value={item.value}>{item.label}</option>
          ))}
        </NativeSelect>
      </FormControl>
      {hasError ? <div style={{ color: 'red' }}>{REQUIRED_FIELD}</div> : null}
    </>
  );
};

SimpleSelect.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  isMulti: PropTypes.bool,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

SimpleSelect.defaultProps = {
  value: undefined,
  placeholder: null,
  isClearable: true,
  isMulti: false,
  isSearchable: true,
  isDisabled: false,
  required: false,
  hasError: false,
  label: null,
  onBlur: () => {},
  onChange: () => {},
  noOptionsMessage: () => {}
};

export default SimpleSelect;
