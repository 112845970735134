import React, { useEffect, useState, useReducer } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  Typography,
  LinearProgress,
  Button,
  Card,
  CardContent,
  Paper,
  Tooltip
} from '@material-ui/core';

import useOnlineStatus from '@rehooks/online-status';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import GuestService from '../../services/guest';
import CompanyService from '../../services/companies';
import VirtualizedTable from '../../components/table';
import ActionTable from '../../components/actionTable';
import ImportButton from '../../components/import';
import ExportButton from '../../components/export';
import ImportErrors from './import-errors';
import { cleanDots, getFormatRut } from '../../libraries/utils';
import { validateData, validateFile, readFile, VALID_EXTENSIONS } from './csv-utils';
import { useToggleNavBar } from '../../libraries/contexts';
import { AddIcon } from '../../icons/customsIcons';
import persistenceService from '../../services/persistence-service';

import {
  TABLE_HEAD_ID,
  SEARCH,
  NO_DATA,
  LOADING,
  VISITOR_NAMES,
  COMPANY,
  POSITION,
  PHONE_NUMBER,
  MISSING_COMPANY,
  NOT_MATCHING_BRANCH_OFFICE,
  SUCCESSFUL_LOAD,
  CREATE
} from '../../libraries/texts';

import { generalClasses as styles } from './styles';

const columnsMobile = [
  {
    minWidth: 100,
    label: TABLE_HEAD_ID,
    dataKey: 'numberId',
    cellDataGetter: ({ dataKey, rowData }) =>
      rowData[dataKey] ? rowData[dataKey].toUpperCase() : ''
  },
  {
    minWidth: 180,
    label: VISITOR_NAMES,
    dataKey: 'names',
    cellDataGetter: ({ columnData, dataKey, rowData }) =>
      `${rowData.names} ${rowData.surname} ${rowData.secondSurname || ''} `
  },
  {
    minWidth: 100,
    label: COMPANY,
    dataKey: 'company',
    cellDataGetter: ({ dataKey, rowData }) => (rowData[dataKey] ? rowData[dataKey].name : '')
  }
];

const setRowsReducer = (prevRows, { newRows = [], type }) => {
  switch (type) {
    case 'add':
      return [...prevRows, ...newRows];
    case 'set':
      return newRows;
    default:
      return prevRows;
  }
};
const VisitedsList = props => {
  const fileUploadInput = React.createRef();
  const detectMobile = useMobileDetect();
  const { classes, formControlClass = classes.middleWidth } = props;
  const [, changeToggleNavBar] = useToggleNavBar();
  const onlineStatus = useOnlineStatus();
  const [rows, setRows] = useReducer(setRowsReducer, []);
  const [pageStatus, setPageStatus] = useState({ currentPage: 1, hasNext: true, total: 0 });
  const [searchText, setSearchText] = useState({ curr: '', last: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [fileError, setFileError] = useState([]);
  const [fileDataError, setFileDataError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [importFile, setImportFile] = useState([]);
  const { role } = persistenceService.getObject('user');

  const columns = [
    {
      minWidth: 100,
      label: TABLE_HEAD_ID,
      dataKey: 'numberId',
      cellDataGetter: ({ dataKey, rowData }) =>
        rowData[dataKey] ? rowData[dataKey].toUpperCase() : ''
    },
    {
      minWidth: 200,
      label: VISITOR_NAMES,
      dataKey: 'names',
      cellDataGetter: ({ columnData, dataKey, rowData }) =>
        `${rowData.names} ${rowData.surname} ${rowData.secondSurname || ''} `
    },
    {
      minWidth: 200,
      rowWidth: 250,
      label: COMPANY,
      dataKey: 'company',
      cellDataGetter: ({ dataKey, rowData }) => (rowData[dataKey] ? rowData[dataKey].name : '')
    },
    {
      minWidth: 150,
      label: POSITION,
      dataKey: 'position'
    },
    {
      minWidth: 100,
      label: PHONE_NUMBER,
      dataKey: 'phoneNumber',
      cellDataGetter: ({ dataKey, rowData }) => (rowData[dataKey] ? `+56${rowData[dataKey]}` : '')
    },
    {
      minWidth: 150,
      label: '',
      dataKey: '',
      cellDataGetter: ({ columnData, dataKey, rowData }) => (
        <ActionTable
          entity={rowData}
          to="/visiteds"
          service={GuestService}
          getRequests={getRequests}
        />
      )
    }
  ];

  const getRequests = async (filterData = '', page = 1) => {
    const type = page <= 1 ? 'set' : 'add';
    const guestList = await GuestService.getPaginated({
      filterData,
      page,
      order: 'names'
    });

    const { results, currentPage, hasNext, count: total } = guestList;
    const newRows = results.map(item => {
      const numberId = item.typeId === 'RUT' ? getFormatRut(item.numberId) : item.numberId;
      return { ...item, numberId, data: item };
    });
    setRows({ newRows, type });
    setPageStatus({ currentPage, hasNext, total });
    setIsLoading(false);
  };

  useEffect(() => {
    getRequests();
  }, [importFile]);

  const handleSearch = e => {
    const { curr, last } = searchText;
    if (curr !== last) {
      setIsLoading(true);
      setSearchText({ ...searchText, last: curr });
      getRequests(curr);
    }
  };

  const handleChange = e => {
    let text = e.target.value;
    text = cleanDots(text);
    setSearchText({ ...searchText, curr: text });
  };

  const isRowLoaded = hasItems => {
    if (!hasItems && pageStatus.hasNext) {
      setPageStatus({ ...pageStatus, hasNext: false });
      return false;
    }
    return true;
  };

  const loadMoreRows = e => {
    const pageToRequest = pageStatus.currentPage + 1;
    getRequests(searchText.curr, pageToRequest);
  };

  const renderTable = () => {
    return (
      <Paper className={classes.tablePaper}>
        <VirtualizedTable
          rowCount={rows.length}
          isRowLoaded={({ index }) => isRowLoaded(!!rows[index])}
          rowGetter={({ index }) => rows[index]}
          loadMoreRows={e => loadMoreRows(e)}
          remoteRowCount={pageStatus.total}
          columns={!detectMobile.isDesktop() ? columnsMobile : columns}
        />
      </Paper>
    );
  };

  const renderMessage = () => {
    return (
      <Typography variant="h5" component="h3" className={classes.tableNoData}>
        {isLoading ? LOADING : NO_DATA}
      </Typography>
    );
  };

  function handleUploadButton() {
    fileUploadInput.current.value = '';
    setFileError([]);
    setFileDataError([]);
    fileUploadInput.current.click();
  }
  async function handleFileUpload(file) {
    try {
      setLoading(true);
      if (validateFile(file, setFileError)) {
        let validCompanies = [];
        if (role === 'SUPERVISOR') {
          const companies = await CompanyService.getAll();
          validCompanies = companies.map(v => v.rut);
        }

        const data = await readFile(file);
        data.shift();
        const errorROWS = data
          .map((val, i) => validateData(data, i, role === 'SUPERVISOR', validCompanies))
          .flat();

        setFileDataError(errorROWS);

        if (errorROWS.length === 0) {
          const result = await GuestService.bulk({ data });
          setImportFile(result);
          changeToggleNavBar({ message: { message: SUCCESSFUL_LOAD, open: true } });
        }
      }
      setLoading(false);
    } catch (error) {
      const { errors = [] } = error;
      const errorsFromService = errors.length ? errors.flat() : [];
      const dupKeysErrors = errorsFromService.map(val => ({
        row: '-',
        message:
          val.errorType === 'missingCompany'
            ? MISSING_COMPANY(val.company)
            : NOT_MATCHING_BRANCH_OFFICE(val.branchOffice)
      }));
      setLoading(false);
      setFileError([]);
      setFileDataError(dupKeysErrors);
    }
  }

  const handleCSV = async () => {
    const { curr } = searchText;
    setLoading(true);
    await GuestService.downloadCSV({
      filter: curr
    });
    setLoading(false);
  };

  return fileDataError.length > 0 ? (
    <ImportErrors rows={fileDataError} handleAccept={setFileDataError} />
  ) : (
    <Card className={classes.formCard}>
      {loading ? <LinearProgress /> : null}
      <CardContent className={classes.cardContent}>
        {
          <React.Fragment>
            <div
              className={classNames(
                formControlClass,
                classes.tableMiddleWidth,
                classes.serialControl
              )}
            >
              <ValidatorForm
                className={classes.tableFormSearch}
                onSubmit={async e => {
                  handleSearch(e);
                }}
              >
                <TextValidator
                  id="names"
                  name="names"
                  value={searchText.curr}
                  label={SEARCH}
                  className={classes.textField}
                  margin="normal"
                  type="search"
                  disabled={!onlineStatus}
                  onChange={e => handleChange(e)}
                  autoComplete="off"
                />
                <div className={classes.secondFunctionalities}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonSearch}
                    disabled={!onlineStatus}
                    type="submit"
                  >
                    {SEARCH}
                  </Button>
                </div>
                <div className={classes.secondFunctionalities}>
                  <ImportButton
                    errorMessage={fileError}
                    onClick={handleUploadButton}
                    disabled={!onlineStatus}
                  />
                </div>
                <div className={classes.secondFunctionalities}>
                  <ExportButton errorMessage={[]} onClick={handleCSV} disabled={!onlineStatus} />
                </div>

                <div className={classes.secondFunctionalities}>
                  <Tooltip title={CREATE}>
                    <Button
                      component={NavLink}
                      to="/visiteds/create"
                      variant="contained"
                      disabled={!onlineStatus}
                      color="default"
                      className={classes.greenButton}
                    >
                      <AddIcon className={classes.rightIcon} />
                    </Button>
                  </Tooltip>
                </div>
              </ValidatorForm>
            </div>
            <div className={classes.tableContainer}>
              {rows.length ? renderTable() : renderMessage()}
            </div>
            <input
              ref={fileUploadInput}
              type="file"
              style={{ display: 'none' }}
              id="fileUpload"
              onChange={async v => v.target.files.length && handleFileUpload(v.target.files[0])}
              accept={VALID_EXTENSIONS.toString()}
            />
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(VisitedsList);
