import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { VisitFormRouters, VISITS_REGISTER_PATH } from './visits';
import { maintainerFormRouters } from './maintainer';
import { configurationFormRouters } from './configuration';
import { reportFormRouters } from './report';
import Login from '../pages/login/index';
import { isLogged } from '../services/authorization-service';
import User from '../services/user-service';

const modularizedRoutes = [
  ...VisitFormRouters,
  ...maintainerFormRouters,
  ...configurationFormRouters,
  ...reportFormRouters
];

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (isLogged() ? <Component {...props} /> : goToLogin())} />
);

const goToLogin = () => {
  return window.location.assign('/login');
};

const routes = modularizedRoutes.map((elem, index) => (
  <ProtectedRoute key={index.toString()} {...elem} />
));
routes.push(<Route exact path="/" render={() => <Redirect to={VISITS_REGISTER_PATH} />} />);

routes.push(
  <Route
    exact
    path="/login"
    render={props => {
      if (isLogged()) {
        User.logout();
        window.location.reload(true);
        return;
      }
      return <Login {...props} />;
    }}
  />
);

export default () => routes;
