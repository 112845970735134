import { NAVBAR_WIDTH } from '../../libraries/constants';

export const header = theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: '-13px',
    marginRight: '25px'
  },
  hide: {
    display: 'none'
  },
  appBar: {
    backgroundColor: 'white',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: `calc(100% - ${theme.spacing.unit * 9 + 1}px)`,
    color: '#211e1e'
  },
  appBarWithSecondary: {
    backgroundColor: 'white',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: `calc(100% - ${'145'}px)!important`,
    color: '#211e1e'
  },
  appBarShift: {
    marginLeft: NAVBAR_WIDTH,
    width: `calc(100% - ${NAVBAR_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        width: '100vw'
      }
    }
  },
  appBarShiftWithSecondary: {
    marginLeft: 320,
    width: `calc(100% - ${'320'}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        width: '100vw'
      }
    }
  },
  appSecondaryBarShift: {
    marginLeft: theme.spacing.unit * 9 + 1,
    width: `calc(100% - ${(theme.spacing.unit * 9 + 1) * 2}px)`
  },
  logoutBar: {
    marginLeft: '0px',
    width: '100%'
  },
  logoutBarWithSecondary: {
    marginLeft: '-100px',
    width: '100%'
  },
  appName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontSize: '1.7rem'
  },
  mainLogo: {
    height: '50px'
  },
  UserPopover: {
    width: '330px',
    '& ul': {
      padding: '0px',
      '& > div:last-child': {
        borderBottom: '0px',
        minWidth: '240px'
      }
    }
  },
  UserName: {
    '& > span': {
      color: 'rgba(142, 142, 142, 0.9)',
      fontWeight: 'bold',
      fontSize: '13px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    mainLogo: {
      height: '35px'
    },
    appName: {
      fontSize: '1.1rem'
    }
  }
});

export const sidebar = theme => ({
  drawer: {
    width: NAVBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '& button:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    },
    '& a:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    }
  },
  UserPopover: {
    width: '330px',
    '& ul': {
      padding: '0px',
      '& > div:last-child': {
        borderBottom: '0px',
        minWidth: '240px'
      }
    }
  },
  UserName: {
    '& > span': {
      color: 'rgba(142, 142, 142, 0.9)',
      fontWeight: 'bold',
      fontSize: '13px'
    }
  },
  drawerSidebar: {
    '& > div': {
      height: '100vh'
    }
  },
  drawerOpen: {
    width: NAVBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '& a': {
      paddingLeft: '15px',
      paddingRigth: '15px',
      '& div': {
        paddingLeft: '8px'
      }
    },
    '& a span, & svg': {
      color: 'white',
      marginRight: '0px'
    },
    '& hr': {
      backgroundColor: theme.palette.lighter.gray
    },
    '& a.active': {
      backgroundColor: theme.palette.lighter.gray
    }
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 9 + 1,
    backgroundColor: '#00375b',
    '& a': {
      paddingLeft: '24px'
    },
    '& a.active': {
      backgroundColor: theme.palette.lighter.gray
    },
    '& a div span': { display: 'none' },
    '& svg': {
      color: 'white'
    },

    '& > div > div': {
      justifyContent: 'center',
      alignItems: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing.unit * 9 + 1,
      '& a div::nth-child(1)': { marginLeft: '0px' },
      '& a div::nth-child(2), & a span': { display: 'none' },
      '& a': {
        paddingRight: '0px',
        paddingLeft: '30px',
        justifyContent: 'center'
      }
    }
  },
  secondaryNavbar: {
    left: theme.spacing.unit * 9 + 1,
    zIndex: '1202',
    backgroundColor: '#e2f4ff',
    '& a span, & svg': {
      color: '#6b6a6a'
    }
  },
  drawerCloseSecondary: {
    width: '0px',
    transition: theme.transitions.create(['width', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  listSecondary: {
    paddingTop: 0
  },
  drawerOpenSecondary: {
    width: theme.spacing.unit * 9 + 1,
    transition: theme.transitions.create(['width', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& a.active': {
      backgroundColor: '#925f5f26'
    }
  },
  enterBothDrawer: {
    left: NAVBAR_WIDTH,
    transition: theme.transitions.create(['width', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  leavingBothDrawer: {
    transition: theme.transitions.create(['width', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px 0 8px',
    ...theme.mixins.toolbar
  },
  userOption: {
    position: 'absolute',
    paddingBottom: '30px',
    paddingTop: '30px',
    bottom: '0px',
    '&  span': {
      display: 'block',
      fontSize: '1rem',
      width: '190px',
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    }
  },
  sideBarNavList: {
    height: '100%',
    minHeight: '350px'
  }
});

export const content = theme => ({
  content: {
    flexGrow: 1,
    width: `calc(100% - ${theme.spacing.unit * 3}px)`,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      width: '100wv',
      padding: '24px 10px 24px 10px'
    }
  },
  drawerOpen: {
    overflow: 'scroll',
    marginLeft: NAVBAR_WIDTH,
    width: `calc(100% - ${NAVBAR_WIDTH}px)`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: NAVBAR_WIDTH
    },
    transition: theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  drawerOpenAndSecondary: {
    overflow: 'scroll',
    marginLeft: 324,
    width: `calc(100% - ${'324'}px)`,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 324
    },
    transition: theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  drawerClose: {
    transition: theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(100% - ${theme.spacing.unit * 9 + 1}px)`,
    marginLeft: theme.spacing.unit * 9 + 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 9 + 1
    }
  },
  drawerCloseAndSecondary: {
    transition: theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(100% - 150px)`,
    marginLeft: 150,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 150
    }
  },
  drawerSecondaryOpen: {
    overflow: 'scroll',
    transition: theme.transitions.create(['width', 'margin-left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: (theme.spacing.unit * 9 + 1) * 2,
    width: `calc(100% - ${(theme.spacing.unit * 9 + 1) * 2}px)`,
    [theme.breakpoints.down('sm')]: {
      width: '100wv',
      marginLeft: (theme.spacing.unit * 9 + 1) * 2
    }
  },
  drawerBothOpen: {
    overflow: 'scroll',
    marginLeft: theme.spacing.unit * 9 + 1 + NAVBAR_WIDTH,
    width: `calc(100% - ${theme.spacing.unit * 9 + 1 + NAVBAR_WIDTH}px)`,
    [theme.breakpoints.down(1170)]: {
      '& form > div': {
        width: '100%'
      }
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px 0 8px',
    ...theme.mixins.toolbar
  },
  logoutBar: {
    marginLeft: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#00375b'
  }
});

export const snackBar = theme => ({});
