import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Exports = props => {
  return (
    <SvgIcon viewBox="0 0 612 612" {...props}>
      <g>
        <g>
          <path
            d="M494.7,255C476.85,168.3,400.35,102,306,102c-73.95,0-137.7,40.8-168.3,102C58.65,214.2,0,277.95,0,357
			c0,84.15,68.85,153,153,153h331.5c71.4,0,127.5-56.1,127.5-127.5C612,316.2,558.45,260.1,494.7,255z M433.5,331.5L306,459
			L178.5,331.5H255v-102h102v102H433.5z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  );
};

export default Exports;
