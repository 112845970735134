export const reducerSteps = (state, action) => {
  switch (action.type) {
    case 'next':
      return { page: state.page + 1, beforeStep: action.data };
    case 'back':
      return { page: state.page - 1, beforeStep: action.data };
    case 'first':
      return { page: 0, beforeStep: action.data };
    case 'last':
      return { page: state.page + 3, beforeStep: action.data };
    default:
      return 0;
  }
};
