import React from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { generalClasses as styles } from './styles';
import { ImportIcon } from '../../icons/customsIcons';
import { IMPORT, SIZE_FORMAT_IMPORT } from '../../libraries/texts';

const ImportButton = props => {
  const { classes, errorMessage, footer = SIZE_FORMAT_IMPORT, onClick, disabled } = props;
  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <Tooltip title={IMPORT} aria-label={IMPORT}>
          <Button
            variant="contained"
            size="small"
            className={classes.exportButton}
            onClick={onClick}
            disabled={disabled}
          >
            <ImportIcon />
          </Button>
        </Tooltip>
        <FormHelperText id="component-helper-text" error={errorMessage.length > 0}>
          {errorMessage.join(', ') || footer}
        </FormHelperText>
      </FormControl>
    </React.Fragment>
  );
};

export default withStyles(styles)(ImportButton);
