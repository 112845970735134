import React from 'react';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';

import {
  AddBox,
  Description,
  Ballot,
  Settings,
  LocationCity,
  RoomOutlined,
  Alarm,
  CardTravel
} from '@material-ui/icons';

import {
  TurnstilesIcon,
  CredentialsIcon,
  BlackListIcon,
  VisitedsIcon,
  UsersIcon
} from '../icons/customsIcons';

import {
  INSERT_VISITOR_PATH,
  CLOSE_VISITOR_PATH,
  maintainerFormRouters,
  COMPANY_PATHS,
  COMPANY_LIST_PATH,
  BRANCH_OFFICES_PATH,
  BRANCH_OFFICES_LIST_PATH,
  VISITEDS_LIST_PATH,
  VISITEDS_PATHS,
  CREDENTIALS_PATHS,
  CREDENTIALS_LIST_PATH,
  ACCESS_LIST_PATH,
  ACCESS_PATHS,
  BLACK_LIST_PATH,
  BLACK_LIST_PATHS,
  VISIT_REPORT_PATH,
  USER_LIST_PATH,
  USERS_PATHS
} from './maintainer';

import {
  DAILY_CLOSING_PATHS,
  DAILY_CLOSING_PATH,
  GUESTS_UPDATE_PATHS,
  GUESTS_UPDATE_PATH,
  SETTINGS_CREATE_PATHS,
  SETTINGS_CREATE,
  configurationFormRouters
} from './configuration';
import { VISIT_REPORT_PATHS, reportFormRouters } from './report';
import { getItemsMenu } from '../services/authorization-service';

const itemsMenu = [
  {
    icon: <AddBox />,
    text: 'Registrar Visita',
    permissions: ['INSERT_VISITOR'],
    menuPermission: 'INSERT_VISITOR',
    to: INSERT_VISITOR_PATH
  },
  {
    icon: <Description />,
    text: 'Cerrar Visita',
    permissions: ['CLOSE_VISITOR'],
    menuPermission: 'CLOSE_VISITOR',
    to: CLOSE_VISITOR_PATH
  },
  {
    icon: <Ballot />,
    text: 'Mantenedores',
    key: 'MT_K',
    permissions: ['MAINTAINER_LIST'],
    childrens: maintainerFormRouters,
    menuPermission: 'MAINTAINER_LIST'
  },
  {
    icon: <Settings />,
    text: 'Configuraciones',
    key: 'CG_K',
    permissions: ['CONFIG_LIST'],
    childrens: configurationFormRouters,
    menuPermission: 'CONFIG_LIST'
  },
  {
    icon: <Ballot />,
    text: 'Reportes',
    key: 'RP_K',
    permissions: ['REPORT_LIST'],
    childrens: reportFormRouters,
    menuPermission: 'REPORT_LIST'
  }
];

export const navBarButtons = getItemsMenu(itemsMenu);

export const isActiveLink = childrens => (match, location) => {
  let { pathname } = location;
  if (pathname.includes('edit')) {
    pathname = pathname.split('/');
    pathname[3] = ':id';
    pathname = pathname.join('/');
  }
  return childrens.filter(elem => elem.path === pathname).length;
};

export const SecondaryNavigationButtons = {
  MT_K: getItemsMenu([
    {
      icon: <LocationCity />,
      text: 'Empresas',
      to: COMPANY_LIST_PATH,
      permissions: ['COMPANIES'],
      childrens: COMPANY_PATHS,
      menuPermission: 'COMPANIES'
    },
    {
      icon: <RoomOutlined />,
      text: 'Sucursales',
      to: BRANCH_OFFICES_LIST_PATH,
      permissions: ['BRANCH_OFFICES'],
      childrens: BRANCH_OFFICES_PATH,
      menuPermission: 'BRANCH_OFFICES'
    },
    {
      icon: <VisitedsIcon />,
      text: 'Visitados',
      to: VISITEDS_LIST_PATH,
      permissions: ['VISITEDS'],
      childrens: VISITEDS_PATHS,
      disabled: false,
      menuPermission: 'VISITEDS'
    },
    {
      icon: <CredentialsIcon />,
      text: 'Tarjetas',
      to: CREDENTIALS_LIST_PATH,
      permissions: ['CREDENTIALS'],
      childrens: CREDENTIALS_PATHS,
      menuPermission: 'CREDENTIALS'
    },
    {
      icon: <TurnstilesIcon />,
      text: 'Accesos',
      to: ACCESS_LIST_PATH,
      permissions: ['ACCESSES'],
      childrens: ACCESS_PATHS,
      menuPermission: 'ACCESSES'
    },
    {
      icon: <BlackListIcon />,
      text: 'Lista negra',
      to: BLACK_LIST_PATH,
      permissions: ['BLACK_LIST'],
      childrens: BLACK_LIST_PATHS,
      menuPermission: 'BLACK_LIST'
    },
    {
      icon: <UsersIcon />,
      text: 'Usuarios',
      to: USER_LIST_PATH,
      permissions: ['USERS'],
      childrens: USERS_PATHS,
      menuPermission: 'USERS'
    }
  ]),
  CG_K: getItemsMenu([
    {
      icon: <Alarm />,
      text: 'Cierre automático',
      to: DAILY_CLOSING_PATH,
      permissions: ['DAILY_CLOSING'],
      childrens: DAILY_CLOSING_PATHS,
      menuPermission: 'DAILY_CLOSING'
    },
    {
      icon: <Alarm />,
      text: 'Actualización de Visitados',
      to: GUESTS_UPDATE_PATH,
      permissions: ['GUESTS_UPDATE'],
      childrens: GUESTS_UPDATE_PATHS,
      menuPermission: 'GUESTS_UPDATE'
    },
    {
      icon: <CardTravel />,
      text: 'Empresa Administradora',
      to: SETTINGS_CREATE,
      permissions: ['SETTINGS'],
      childrens: SETTINGS_CREATE_PATHS,
      menuPermission: 'SETTINGS'
    }
  ]),
  RP_K: getItemsMenu([
    {
      icon: <ListAltOutlinedIcon />,
      text: 'Historial Visitas',
      to: VISIT_REPORT_PATH,
      permissions: ['VISIT_REPORT'],
      childrens: VISIT_REPORT_PATHS,
      menuPermission: 'VISIT_REPORT'
    }
  ])
};
