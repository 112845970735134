import API_PUBLIC_SERVICE from './http';

const PREFIX = 'settings';

const path = PREFIX;

const InsuranceService = {
  ...API_PUBLIC_SERVICE,
  path
};

export default InsuranceService;
