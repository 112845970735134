import React, { useState, useEffect } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Loading from './loading';
import LoadingNetwork from './loadingNetwork';

import { useToggleNavBar, useLoadingBar } from '../../libraries/contexts';
import { GENERA_LOGO, GENERA_LOGO_ALT } from '../../libraries/images';
import { isLogged } from '../../services/authorization-service';
import { header as styles } from './css';
import settingService from '../../services/settings';

const Header = props => {
  const toggleManager = useToggleNavBar();
  const loader = useLoadingBar();
  const onlineStatus = useOnlineStatus();
  const [userLogo, setUserLogo] = useState(GENERA_LOGO);
  const loading = loader[0];
  const { main, secondary } = toggleManager.length > 0 ? toggleManager[0] : { main: false };
  const { classes } = props;
  const {
    classes: { appBarShiftWithSecondary, appBarWithSecondary }
  } = props;
  const isOut = !isLogged();
  async function getUserLogo() {
    const userSetting = isOut ? null : await settingService.getAll();
    let logo = GENERA_LOGO;
    if (userSetting) {
      const { company } = userSetting;
      logo = company && company.logo ? company.logo : GENERA_LOGO;
    }
    return setUserLogo(logo);
  }

  useEffect(() => {
    getUserLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogo]);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: main,
          [appBarShiftWithSecondary]: main && secondary,
          [appBarWithSecondary]: !main && secondary,
          [classes.logoutBar]: isOut
        })}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignContent="center"
            alignItems="center"
          >
            <img src={userLogo} alt={GENERA_LOGO_ALT} className={classes.mainLogo} />
          </Grid>

          <Grid container justify="flex-end" alignContent="center" alignItems="center">
            <span className={classes.appName}>Control Visitas By Genera</span>
          </Grid>
        </Toolbar>
        {onlineStatus ? '' : <LoadingNetwork />}
        {loading.principalLoading ? <Loading /> : ''}
      </AppBar>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
