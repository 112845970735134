import React, { useReducer, useState, useEffect, useRef } from 'react';
import onlineStatusonlineStatus from '@rehooks/online-status';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getValidations } from '../../libraries/validators';
import { useToggleNavBar } from '../../libraries/contexts';
import { validateSpecialCharacters, save } from '../../libraries/utils';
import {
  MAX_STRING_LENGTH,
  REQUIRED_FIELD,
  BACK,
  SAVE,
  ERROR_ACCESSES
} from '../../libraries/texts';
import AccessesService from '../../services/accesses';
import { generalClasses as styles } from './styles';

const formReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};
const initialState = {
  description: ''
};

const Form = props => {
  const { classes, access, history } = props;
  const formRef = useRef(null);
  const onlineStatus = onlineStatusonlineStatus();
  const [state, dispatch] = useReducer(formReducer, access || initialState);
  const [disabled, setDisabled] = useState(false);
  const [, changeToggleNavBar] = useToggleNavBar();
  const { description } = state;

  const submitForm = async () => {
    try {
      const { _id: hasId } = state;

      const result = await save(hasId, state, AccessesService);

      if (result) {
        changeToggleNavBar({
          message: { message: `Acceso ${hasId ? 'actualizado' : 'creado'}`, open: true }
        });
        return history.push('/accesses/list');
      }
      return changeToggleNavBar({ message: { message: 'Not found', open: true } });
    } catch (err) {
      return changeToggleNavBar({
        message: { message: ERROR_ACCESSES[err.message], open: true }
      });
    }
  };

  useEffect(() => {
    getValidations();
  }, []);

  const validatorListener = async () => {
    const result = await formRef.current.isFormValid();
    setDisabled(result);
  };

  return (
    <ValidatorForm
      novalidate
      className={classes.formVerify}
      ref={formRef}
      instantValidate
      onSubmit={submitForm}
    >
      <div className={classes.backForm}>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          size="small"
          disabled={!onlineStatus}
          className={classes.buttonSearch}
          to="/accesses/list"
        >
          <ArrowBack className={classes.rightIcon} />
          {BACK}
        </Button>
      </div>

      <TextValidator
        id="description"
        required
        name="description"
        label="Descripción"
        inputProps={{ maxLength: 50 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={description}
        validators={['requiredValue', 'maxStringLength:50']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateSpecialCharacters(e.target.value) });
        }}
      />

      <div className={classes.footerSaveButton}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={!onlineStatus || !disabled}
        >
          {SAVE}
        </Button>
      </div>
    </ValidatorForm>
  );
};

export default withStyles(styles)(Form);
