import React, { useReducer, useState, useEffect, useRef } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getValidations } from '../../libraries/validators';
import { useToggleNavBar } from '../../libraries/contexts';
import {
  validateBussinessName,
  getFormatNumber,
  getFormatRut,
  validateEmailCharacters,
  latinAlphaNumericCharactersAllow,
  save
} from '../../libraries/utils';

import {
  MAX_STRING_LENGTH,
  MIN_STRING_LENGTH_PHONE,
  REQUIRED_FIELD,
  INVALID_RUT,
  BACK,
  EMAIL_ERROR,
  ERROR_COMPANY,
  NO_OPTION_LABELS,
  REQUIRED_BRANCH_FIELD,
  SAVE,
  PHONE_NUMBER,
  COMPANY,
  LOCATION,
  REQUIRED_BRANCH_OFFICE
} from '../../libraries/texts';
import SelectValidatorElement from '../../components/fields/selectSearch';
import CompaniesService from '../../services/companies';
import GuestService from '../../services/guest';
import { generalClasses as styles } from './styles';

const formReducer = (prevState, updatedProperty) => {
  return {
    ...prevState,
    ...updatedProperty
  };
};
const initialState = {
  numberId: '',
  names: '',
  surname: '',
  secondSurname: '',
  phoneNumber: '',
  email: '',
  position: '',
  company: null,
  branchOffice: null
};

const getDataForEdit = data => {
  if (!data) {
    return null;
  }

  const { company, branchOffice, numberId } = data;

  return {
    ...data,
    numberId: getFormatRut(numberId),
    company: { value: company ? company._id : '', label: company ? company.name : '' },
    branchOffice: {
      value: branchOffice ? branchOffice._id : '',
      label: branchOffice ? branchOffice.name : ''
    }
  };
};

const Form = ({ classes, history, entity }) => {
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const onlineStatus = useOnlineStatus();
  const [state, dispatch] = useReducer(formReducer, getDataForEdit(entity) || initialState);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [, changeToggleNavBar] = useToggleNavBar();
  const {
    names,
    surname,
    secondSurname,
    numberId,
    phoneNumber,
    position,
    email,
    company,
    branchOffice
  } = state;

  const getRequests = async () => {
    const companiesList = await CompaniesService.getAll();
    const getCompanies = companiesList.map(item => ({
      label: item.name,
      value: item._id,
      branchOffices: item.branchOffices
    }));
    if (entity) {
      const branchForEdit = getCompanies.find(c => c.value === company.value);
      setBranches(branchForEdit ? branchForEdit.branchOffices : []);
    }
    setCompanies(getCompanies);
  };

  const submitForm = async () => {
    try {
      const { _id: hasId } = state;
      const { exists } = await GuestService.get(state.numberId.replace(/\./gi, ''));
      if (exists) {
        return changeToggleNavBar({
          message: {
            message: 'El visitado ya existe.',
            open: true
          }
        });
      }
      const result = await save(hasId, state, GuestService);

      if (result) {
        changeToggleNavBar({
          message: { message: `Visitado ${hasId ? 'actualizado' : 'creado'}`, open: true }
        });
        return history.push('/visiteds/list');
      }
      return changeToggleNavBar({ message: { message: 'Not found', open: true } });
    } catch (err) {
      console.error('visiteds', err);
      return changeToggleNavBar({
        message: {
          message: ERROR_COMPANY[err.message]
            ? ERROR_COMPANY[err.message]
            : 'Ocurrio un error inesperado, inténtelo más tarde',
          open: true
        }
      });
    }
  };

  useEffect(() => {
    getValidations();
    getRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validatorListener = async () => {
    const result = await formRef.current.isFormValid();
    setDisabled(result);
  };

  return (
    <ValidatorForm
      noValidate
      className={classes.formVerify}
      ref={formRef}
      instantValidate
      onSubmit={submitForm}
    >
      <div className={classes.backForm}>
        <Button
          variant="contained"
          color="primary"
          component={NavLink}
          size="small"
          className={classes.buttonSearch}
          to="/visiteds/list"
          disabled={!onlineStatus}
        >
          <ArrowBack className={classes.rightIcon} />
          {BACK}
        </Button>
      </div>

      <TextValidator
        id="numberId"
        name="numberId"
        required
        label="Nº de Documento"
        disabled={!!entity}
        inputProps={{ maxLength: 12 }}
        className={classes.textField}
        autoComplete="off"
        value={numberId}
        validatorListener={validatorListener}
        validators={['requiredValue', 'maxStringLength:12', 'rutValidator']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH, INVALID_RUT]}
        margin="normal"
        onChange={e => {
          dispatch({
            [e.target.name]: getFormatRut(e.target.value.toUpperCase(), { maxLength: 12 })
          });
        }}
        onBlur={e => {
          if (e.target.value)
            dispatch({
              [e.target.name]: getFormatRut(e.target.value.toUpperCase(), { maxLength: 12 })
            });
        }}
      />
      <TextValidator
        id="email"
        name="email"
        value={email}
        label="E-mail"
        validatorListener={validatorListener}
        inputProps={{ maxLength: 100 }}
        className={classes.textField}
        autoComplete="off"
        validators={email ? ['maxStringLength:100', 'isEmail', 'minStringLength:5'] : []}
        errorMessages={email ? [MAX_STRING_LENGTH, EMAIL_ERROR, 'Minimo 5 caracteres'] : []}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateEmailCharacters(e.target.value) });
        }}
      />
      <TextValidator
        id="names"
        required
        autoComplete="off"
        name="names"
        label="Nombres"
        inputProps={{ maxLength: 30 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={names}
        validators={['requiredValue', 'maxStringLength:30']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: latinAlphaNumericCharactersAllow(e.target.value) });
        }}
      />
      <TextValidator
        id="surname"
        required
        autoComplete="off"
        name="surname"
        label="Apellido Paterno"
        inputProps={{ maxLength: 30 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={surname}
        validators={['requiredValue', 'maxStringLength:30']}
        errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: latinAlphaNumericCharactersAllow(e.target.value) });
        }}
      />
      <TextValidator
        id="secondSurname"
        autoComplete="off"
        name="secondSurname"
        label="Apellido Materno"
        inputProps={{ maxLength: 30 }}
        className={classes.textField}
        validatorListener={validatorListener}
        value={secondSurname}
        validators={secondSurname ? ['maxStringLength:30'] : []}
        errorMessages={secondSurname ? [MAX_STRING_LENGTH] : []}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: latinAlphaNumericCharactersAllow(e.target.value) });
        }}
      />
      <TextValidator
        id="position"
        name="position"
        label="Cargo"
        inputProps={{ maxLength: 100 }}
        validatorListener={validatorListener}
        className={classes.textField}
        autoComplete="off"
        value={position}
        validators={position ? ['maxStringLength:100'] : []}
        errorMessages={position ? [MAX_STRING_LENGTH] : []}
        margin="normal"
        onChange={e => {
          dispatch({ [e.target.name]: validateBussinessName(e.target.value) });
        }}
        onBlur={e => {
          if (e.target.value) dispatch({ [e.target.name]: validateBussinessName(e.target.value) });
        }}
      />

      <TextValidator
        id="phoneNumber"
        name="phoneNumber"
        label={PHONE_NUMBER}
        inputProps={{ maxLength: 10 }}
        validatorListener={validatorListener}
        className={classes.textField}
        autoComplete="off"
        value={phoneNumber}
        validators={phoneNumber ? ['maxStringLength:10', 'minStringLength:9'] : []}
        errorMessages={phoneNumber ? [MAX_STRING_LENGTH, MIN_STRING_LENGTH_PHONE] : []}
        margin="normal"
        onChange={e => {
          dispatch({
            phoneNumber: getFormatNumber(e.target.value, { maxLength: 10 })
          });
        }}
        onBlur={e => {
          if (e.target.value)
            dispatch({
              phoneNumber: getFormatNumber(e.target.value, { maxLength: 10 })
            });
        }}
      />

      <SelectValidatorElement
        id="company"
        name="company"
        className={classes.selectField}
        required
        validatorListener={validatorListener}
        value={company}
        placeholder={`${COMPANY} *`}
        options={companies.length ? companies : []}
        noOptionsMessage={() => NO_OPTION_LABELS}
        sref={inputRef}
        validators={['requiredValue']}
        errorMessages={[REQUIRED_BRANCH_FIELD]}
        onChange={e => {
          setBranches(e && e.branchOffices ? e.branchOffices : []);
          dispatch({ company: e, branchOffice: '' });
        }}
      />
      <SelectValidatorElement
        id="banchOffice"
        name="banchOffice"
        disabled={!branches.length}
        validatorListener={validatorListener}
        value={branchOffice}
        required
        placeholder={`${LOCATION} *`}
        options={branches.length ? branches : []}
        noOptionsMessage={() => NO_OPTION_LABELS}
        sref={inputRef}
        validators={['requiredValue']}
        errorMessages={[REQUIRED_BRANCH_OFFICE]}
        onChange={e => {
          dispatch({ branchOffice: e });
        }}
      />
      <div className={classes.footerSaveButton}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          disabled={!onlineStatus || !disabled}
        >
          {SAVE}
        </Button>
      </div>
    </ValidatorForm>
  );
};

export default withStyles(styles)(Form);
