import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { BLACK_LIST } from '../../libraries/texts';
import SearchBlackList from './list';
import { generalClasses as styles } from './styles';

const List = props => {
  const { classes, history } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title={BLACK_LIST} />
      <CardContent className={classes.cardContent}>
        <SearchBlackList classes={classes} history={history} />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(List);
