import axios from 'axios';
import persistenceService from './persistence-service';
import { useMessageContext } from '../libraries/contexts';

const { REACT_APP_API_URL } = process.env;
const SERVER_API = REACT_APP_API_URL;

const PREFIX = 'mailer';

const headers = {
  'Content-Type': 'application/json; charset=utf-8'
};

const publicInstance = axios.create({
  baseURL: SERVER_API,
  timeout: 300000,
  crossdomain: true,
  headers
});

publicInstance.interceptors.response.use(
  response => response,
  error => {
    try {
      const [value, setValue] = useMessageContext();
      setValue(error);
    } catch (er) {}
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.toLowerCase().includes('token')
    ) {
      persistenceService.clearPersistentDataProps();
      window.location.reload();
    }
    throw error;
  }
);

publicInstance.interceptors.request.use(
  config => {
    const access_token = persistenceService.get('token');

    if (access_token !== null) {
      config.headers.Authorization = `${access_token}`;
    }
    if (!access_token && !config.url.includes('login')) {
      window.location.reload();
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

const MailerService = {
  async sendMail(id) {
    await publicInstance.get(
      `${SERVER_API}${PREFIX}/blacklist-visitor-not-allowed/?blackListId=${id}`
    );
  }
};

export default MailerService;
