import React from 'react';
import red from '@material-ui/core/colors/red';
import TextField from '@material-ui/core/TextField';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import NumberFormat from 'react-number-format';

const red300 = red['500'];

const style = {
  right: 0,
  fontSize: '12px',
  color: red300,
  marginTop: '0px'
};

class InputWithMaskValidatorElement extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      className,
      errorClassName,
      name,
      onChange: change,
      label,
      format,
      required,
      mask,
      onChange,
      containerClassName = '',
      TextMaskCustom,
      error,
      ...rest
    } = this.props;
    const { isValid } = this.state;

    return (
      <div className={containerClassName}>
        <NumberFormat
          customInput={TextField}
          className={className}
          required={required}
          format={format}
          error={!isValid || error}
          label={label}
          value={value}
          mask={mask}
          onValueChange={onChange}
          {...rest}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <div style={style}>{this.getErrorMessage()}</div>;
  }
}

export default InputWithMaskValidatorElement;
