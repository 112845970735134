import React, { useEffect, useState, useContext, useReducer } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import useOnlineStatus from '@rehooks/online-status';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import VirtualizedTable from '../../../components/table';
import VisitsService from '../../../services/visits';
import { cleanDots, cleanDash, getFormatRut } from '../../../libraries/utils';
import StepsContext from '../../../contexts/stepsContext';

import { CLOSE_VISIT_PAGE, SEARCH, NO_DATA, LOADING, RECEPTION } from '../../../libraries/texts';

import { generalClasses as styles } from './styles';

const TEXT = CLOSE_VISIT_PAGE;
const columns = [
  {
    minWidth: 150,
    label: TEXT.TABLE_HEAD_ID,
    dataKey: 'numberId',
    cellDataGetter: ({ columnData, dataKey, rowData }) =>
      rowData[dataKey] ? rowData[dataKey].toUpperCase() : ''
  },
  {
    minWidth: 350,
    label: TEXT.TABLE_HEAD_VISITOR_NAME,
    dataKey: 'visitor'
  },
  {
    minWidth: 150,
    label: TEXT.TABLE_HEAD_VISITOR_IN,
    dataKey: 'manual',
    cellDataGetter: ({ columnData, dataKey, rowData }) =>
      rowData[dataKey] == 'true' ? 'MANUAL' : 'SCANER'
  },
  {
    minWidth: 450,
    label: TEXT.TABLE_HEAD_GUEST_NAME,
    dataKey: 'guest'
  },
  {
    minWidth: 220,
    label: TEXT.TABLE_HEAD_CREDENTIAL,
    dataKey: 'credential',
    cellDataGetter: ({ columnData, dataKey, rowData }) =>
      rowData[dataKey] ? rowData[dataKey].code : 'Sin tarjeta'
  }
];

const setRowsReducer = (prevRows, { newRows = [], type }) => {
  switch (type) {
    case 'add':
      return [...prevRows, ...newRows];
    case 'set':
      return newRows;
    default:
      return prevRows;
  }
};

const cleanText = value => {
  let text = cleanDots(value);
  text = cleanDash(text);
  return text;
};

const getGuestName = data => {
  if (data.guest) {
    const { guestName } = data;
    const { companyName = '', branchOffice } = data.guest;
    const { name: branchOfficeName = '', office = '' } = branchOffice || {};
    return `${guestName} - ${companyName} - ${branchOfficeName} - ${office} `;
  }
  return RECEPTION;
};

const getFormatDocumentid = data => {
  const { visitorNumberId } = data;
  const documentId =
    (data.visitor && data.visitor.documentType === 'RUT') || data.employee
      ? getFormatRut(visitorNumberId)
      : visitorNumberId;
  return documentId;
};

const FormSearcher = props => {
  const { classes, formControlClass = classes.middleWidth } = props;

  const steps = useContext(StepsContext);
  const { dispatch: stepsDispatch } = steps;

  const onlineStatus = useOnlineStatus();
  const [rows, setRows] = useReducer(setRowsReducer, []);
  const [pageStatus, setPageStatus] = useState({ currentPage: 1, hasNext: true, total: 0 });
  const [searchText, setSearchText] = useState({ curr: '', last: '' });
  const [isLoading, setIsLoading] = useState(true);

  const getRequests = async (filterData = '', page = 1) => {
    const type = page <= 1 ? 'set' : 'add';
    const visitsList = await VisitsService.getPaginated({
      filterData,
      page,
      order: 'visitorName'
    });
    const { results, currentPage, hasNext, count: total } = visitsList;
    const newRows = results.map(item => {
      const { credential, manual, visitorName: visitor } = item;
      const numberId = getFormatDocumentid(item);
      return { numberId, visitor, credential, manual, guest: getGuestName(item), data: item };
    });
    setRows({ newRows, type });
    setPageStatus({ currentPage, hasNext, total });
    setIsLoading(false);
  };

  useEffect(() => {
    getRequests();
  }, []);

  const handleSearch = e => {
    const { curr, last } = searchText;

    if (curr !== last) {
      const text = cleanText(curr);
      setIsLoading(true);
      setSearchText({ ...searchText, last: curr });
      getRequests(text);
    }
  };

  const handleChange = e => {
    const text = cleanDots(e.target.value);
    setSearchText({ ...searchText, curr: text });
  };

  const isRowLoaded = hasItems => {
    if (!hasItems && pageStatus.hasNext) {
      setPageStatus({ ...pageStatus, hasNext: false });
      return false;
    }
    return true;
  };

  const loadMoreRows = e => {
    const pageToRequest = pageStatus.currentPage + 1;
    const text = cleanText(searchText.curr);
    getRequests(text, pageToRequest);
  };

  const handleOnClickRow = e => {
    if (!onlineStatus) return;
    const {
      rowData: { data }
    } = e;
    stepsDispatch({ type: 'next', data });
  };

  const renderTable = () => {
    return (
      <Paper className={classes.tablePaper}>
        <VirtualizedTable
          rowCount={rows.length}
          isRowLoaded={({ index }) => isRowLoaded(!!rows[index])}
          rowGetter={({ index }) => rows[index]}
          loadMoreRows={e => loadMoreRows(e)}
          remoteRowCount={pageStatus.total}
          columns={columns}
          onRowClick={handleOnClickRow}
        />
      </Paper>
    );
  };

  const renderMessage = () => {
    return (
      <Typography variant="h5" component="h3" className={classes.tableNoData}>
        {isLoading ? LOADING : NO_DATA}
      </Typography>
    );
  };

  return (
    <Card className={classes.formCard}>
      <CardContent className={classes.cardContent}>
        {
          <React.Fragment>
            <div
              className={classNames(
                formControlClass,
                classes.tableMiddleWidth,
                classes.serialControl
              )}
            >
              <ValidatorForm
                className={classes.tableFormSearch}
                onSubmit={async e => {
                  handleSearch(e);
                }}
              >
                <TextValidator
                  id="names"
                  name="names"
                  value={searchText.curr}
                  label={TEXT.SEARCH_TEXT}
                  className={classes.textField}
                  margin="normal"
                  disabled={!onlineStatus}
                  onChange={e => handleChange(e)}
                  autoComplete="off"
                  type="search"
                />
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.buttonSearch}
                  disabled={!onlineStatus}
                  type="submit"
                >
                  {SEARCH}
                </Button>
              </ValidatorForm>
            </div>
            <div className={classes.fullWidth}>{rows.length ? renderTable() : renderMessage()}</div>
          </React.Fragment>
        }
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(FormSearcher);
