import axios from 'axios';
import API_PUBLIC_SERVICE from './http';

import persistenceService from './persistence-service';
import { useMessageContext } from '../libraries/contexts';

const { REACT_APP_API_URL } = process.env;
const SERVER_API = REACT_APP_API_URL;
const PREFIX = 'blackList';
const path = PREFIX;
const headers = {
  'Content-Type': 'application/json; charset=utf-8'
};

const publicInstance = axios.create({
  baseURL: SERVER_API,
  timeout: 300000,
  crossdomain: true,
  headers
});

publicInstance.interceptors.response.use(
  response => response,
  error => {
    try {
      const [value, setValue] = useMessageContext();
      setValue(error);
    } catch (er) {}
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.toLowerCase().includes('token')
    ) {
      persistenceService.clearPersistentDataProps();
      window.location.reload();
    }
    throw error;
  }
);

publicInstance.interceptors.request.use(
  config => {
    const access_token = persistenceService.get('token');

    if (access_token !== null) {
      config.headers.Authorization = `${access_token}`;
    }
    if (!access_token && !config.url.includes('login')) {
      window.location.reload();
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
async function getByDocId(documentId, documentType) {
  const res = await publicInstance.get(
    `${SERVER_API}${PREFIX}/find-by-document-id/${documentId}/?type=${documentType}`
  );
  const { data } = res;
  return data;
}

async function deleteBlacklistRecord(blacklistId, reason) {
  const { data } = await publicInstance.post(
    `${SERVER_API}${this.path}/deleteBlacklistRecord`,
    JSON.stringify({ blacklistId, reason })
  );

  return data;
}

const InsuranceService = {
  ...API_PUBLIC_SERVICE,
  path,
  getByDocId,
  deleteBlacklistRecord
};

export default InsuranceService;
