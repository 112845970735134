import React, { createRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from './services/theme';
import Header from './components/partials/header';
import SideBar from './components/partials/sidebar';
import Content from './components/partials/content';
import ErrorBoundary from './components/partials/error-boundary';
import AppSnackBar from './components/partials/snackbar';
import User from './services/user-service';
import { isLogged } from './services/authorization-service';
import {
  ToggleNavBarProvider,
  LoadingBarProvider,
  MessageContextProvider
} from './libraries/contexts';

const TIME_INACTIVITY = 1800000;
const mainContent = props => {
  return (
    <div className="app">
      <ToggleNavBarProvider>
        <LoadingBarProvider>
          <Header />
          <SideBar />
          <Content />
          <AppSnackBar />
        </LoadingBarProvider>
      </ToggleNavBarProvider>
    </div>
  );
};

const App = props => {
  const idleTimer = createRef();

  const onActive = e => {
    if (!isLogged) {
      User.logout();
    }
  };

  return (
    <MessageContextProvider>
      <IdleTimer
        ref={idleTimer}
        element={document}
        onActive={onActive}
        debounce={250}
        timeout={TIME_INACTIVITY}
      />
      <div className="notranslate">
        <MuiThemeProvider theme={Theme}>
          <Router>
            <ErrorBoundary>
              <CssBaseline />
              {mainContent()}
            </ErrorBoundary>
          </Router>
        </MuiThemeProvider>
      </div>
    </MessageContextProvider>
  );
};

export default App;
