import React from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { generalClasses as styles } from './styles';
import { ExportIcon } from '../../icons/customsIcons';
import { EXPORT } from '../../libraries/texts';

const ExportButton = props => {
  const { classes, errorMessage, onClick, disabled } = props;
  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <Tooltip title={EXPORT} aria-label={EXPORT}>
          <Button
            variant="contained"
            size="small"
            className={classes.exportButton}
            onClick={onClick}
            disabled={disabled}
          >
            <ExportIcon />
          </Button>
        </Tooltip>
        <FormHelperText id="component-helper-text" error={errorMessage.length > 0}>
          {errorMessage.join(', ') || EXPORT}
        </FormHelperText>
      </FormControl>
    </React.Fragment>
  );
};

export default withStyles(styles)(ExportButton);
