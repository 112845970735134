import { generalClasses, FORM_WIDTH } from '../accesses/styles';

export const MaintainerList = theme => ({
  card: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    }
  },
  cardContent: {
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    }
  }
});

export const maintainerForm = theme => {
  const classes = {
    ...generalClasses(theme),
    formVerify: {
      width: '100%',
      padding: '30px',
      paddingTop: 0
    },
    formSubscriptor: {
      display: 'flex',
      flexDirection: 'column'
    },
    collapseContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    card: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    actions: {
      display: 'flex',
      flexDirection: 'row-reverse',
      padding: '20px 0px 20px 24px',
      alignSelf: 'center',
      width: FORM_WIDTH,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    message: {
      padding: '0px 5px'
    },
    textField: {
      marginLeft: '1px',
      marginRight: '1rem',
      width: '100%'
    },
    withoutContainer: {
      boxShadow: '0px 0px 0px 0px transparent',
      background: 'transparent',
      height: '500px',
      justifyContent: 'center'
    },
    loginActions: {
      width: '600px',
      position: 'absolute',
      top: '400px',
      justifyContent: 'center',
      padding: '20px 0px 20px 0px'
    },
    loginCard: {
      alignSelf: 'center',
      width: '600px',
      textAlign: 'center',
      height: '350px',
      overflow: 'hidden',
      margin: '15px 0px 15px 0px',
      '& > div:nth-child(2)': {
        paddingBottom: '0px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '350px'
      }
    },
    titleLogin: {
      marginTop: '2rem'
    },
    errorMessage: {
      color: 'red',
      marginTop: '1rem'
    },
    footerLoginButton: {
      display: 'flex',
      marginTop: '3rem',
      position: 'absolute',
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      justifyContent: 'center'
    },
    img: {
      width: 300,
      [theme.breakpoints.down('xs')]: {
        width: 150,
        height: 100
      }
    },
    imgLogin: {
      display: 'flex',
      marginTop: '6rem',
      justifyContent: 'center'
    },
    unauthorizedCard: {
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      overflow: 'inherit',
      display: 'flex',
      'flex-direction': 'column',
      'justify-content': 'center',
      'text-align': 'center',
      margin: '15px 0px 15px 0px',
      '& > div:nth-child(2)': {
        paddingBottom: '0px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    [theme.breakpoints.down('xs')]: {},
    errorSimilarMessages: {
      fontSize: '10px'
    }
  };
  classes.fullWidth['& label'] = {
    paddingLeft: '40px'
  };
  classes.fullWidth['& > div > div > label'] = {
    paddingLeft: '0px'
  };

  return classes;
};
