import React from 'react';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ValidatorComponent } from 'react-material-ui-form-validator';

const red300 = red['500'];

const style = {
  right: 0,
  fontSize: '12px',
  color: red300,
  position: 'absolute',
  marginTop: '-25px'
};

class CheckboxValidatorElement extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      className,
      label,
      containerClassName = '',

      ...rest
    } = this.props;

    return (
      <div className={containerClassName}>
        {' '}
        <FormControlLabel
          className={classNames('checkbox-label', className)}
          control={
            <Checkbox
              {...rest}
              ref={r => {
                this.input = r;
              }}
            />
          }
          label={label}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <div style={style}>{this.getErrorMessage()}</div>;
  }
}

export default CheckboxValidatorElement;
