import React, { useReducer } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { generalClasses as styles } from '../styles';
import StepsContext from '../../../contexts/stepsContext';
import { reducerSteps } from '../../../hooks/stepReducer';
import FormsRegister from './forms';

const CloseVisit = props => {
  const [step, dispatch] = useReducer(reducerSteps, { page: 0 });
  const { classes, history } = props;

  return (
    <Card className={classes.card}>
      <CardHeader title="Historial de Visitas" />
      <CardContent className={classes.cardContent}>
        <StepsContext.Provider
          value={{
            beforeStep: step.beforeStep,
            dispatch,
            step,
            history
          }}
        >
          <FormsRegister />
        </StepsContext.Provider>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(CloseVisit);
