import React from 'react';
import { Button, Card, CardContent, Paper, CardHeader } from '@material-ui/core';
import useMobileDetect from 'use-mobile-detect-hook';
import { withStyles } from '@material-ui/core/styles';
import { TITLE_IMPORT_ERROR, ACCEPT } from '../../libraries/texts';
import { generalClasses as styles } from './styles';
import VirtualizedTable from '../../components/table';

const columns = [
  {
    minWidth: 100,
    label: 'Fila',
    dataKey: 'row'
  },
  {
    minWidth: 800,
    label: 'Mensaje de error',
    dataKey: 'message'
  }
];

const columnsMobile = [
  {
    minWidth: 100,
    label: 'Fila',
    dataKey: 'row'
  },
  {
    minWidth: 180,
    label: 'Mensaje de error',
    dataKey: 'message'
  }
];
function CsvError(props) {
  const { rows, handleAccept, classes } = props;
  const detectMobile = useMobileDetect();

  return (
    <Card className={classes.formCard}>
      <CardHeader className={classes.cardSubTitle} title={TITLE_IMPORT_ERROR} />
      <CardContent className={classes.cardContent}>
        <div className={classes.tableContainer}>
          <Paper className={classes.tablePaper}>
            <VirtualizedTable
              rowCount={rows.length}
              isRowLoaded={({ index }) => !!rows[index]}
              rowGetter={({ index }) => rows[index]}
              loadMoreRows={() => true}
              remoteRowCount={rows.length}
              columns={!detectMobile.isDesktop() ? columnsMobile : columns}
            />
          </Paper>
        </div>
        <div className={classes.footerButton}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonSearch}
            type="button"
            onClick={() => handleAccept([])}
          >
            {ACCEPT}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(CsvError);
