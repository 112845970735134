import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { OFFLINE_ERROR } from '../../libraries/texts';

const styles = theme => ({
  root: {
    flexGrow: 1
  },

  linearColorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  linearBarColorPrimary: {
    backgroundColor: '#7d7d7d',
    'min-height': '44px',
    display: 'flex',
    'justify-content': 'center',
    'font-weight': '700',
    left: 0,
    right: 0,
    'text-align': 'center',
    'z-index': '1'
  },
  linearText: {
    'font-size': '1.1rem',
    'text-align': 'center',
    color: 'white'
  }
});

function LoadingNetwork(props) {
  const { classes } = props;
  return (
    <Toolbar className={classes.linearBarColorPrimary}>
      <Typography className={classes.linearText}>{OFFLINE_ERROR}</Typography>
    </Toolbar>
  );
}

LoadingNetwork.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingNetwork);
