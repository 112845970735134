import CloseVisit from '../pages/visits/close';
import Register from '../pages/visits/register';
import Report from '../pages/visits/report';

export const VISITS_REGISTER_PATH = '/visits/register';
export const VISITS_CLOSE_PATH = '/visits/close';
export const VISITS_REPORT_PATH = '/visits/report';

export const VisitFormRouters = [
  {
    path: VISITS_REGISTER_PATH,
    exact: true,
    component: Register
  },
  {
    path: VISITS_CLOSE_PATH,
    exact: true,
    component: CloseVisit
  },
  {
    path: VISITS_REPORT_PATH,
    exact: true,
    component: Report
  }
];
