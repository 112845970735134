import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { UNAUTHORIZED } from '../libraries/texts';
import { maintainerForm as styles } from './login/styles';
import { UnauthorazedIcon } from '../icons/customsIcons';

const unAuthorized = props => {
  const { classes } = props;
  return (
    <div className={classes.unauthorizedCard}>
      <div style={{ 'flex-direction': 'column' }}>
        <UnauthorazedIcon />
        <p>{UNAUTHORIZED}</p>
      </div>
    </div>
  );
};

export default withStyles(styles)(unAuthorized);
