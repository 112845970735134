import { FILE_SIZE_IS_TOO_BIG, INVALID_FILE_EXTENSION } from '../../../libraries/texts';

const VALID_EXTENSIONS = ['.jpg', '.png', '.jpeg'];
const MAX_FILE_SIZE = 10485760;

const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      return reject(new Error('Error al leer el archivo.'));
    };
    reader.readAsDataURL(file);
  });
};
const validateFile = ({ name, size }, fn) => {
  let isValid = true;
  const fileExtension = name
    .split('.')
    .pop()
    .toLowerCase();

  if (VALID_EXTENSIONS.indexOf(`.${fileExtension}`) === -1) {
    fn(prevError => [...prevError, INVALID_FILE_EXTENSION]);
    isValid = false;
  }

  if (size > MAX_FILE_SIZE) {
    fn(prevError => [...prevError, FILE_SIZE_IS_TOO_BIG]);
    isValid = false;
  }
  return isValid;
};

export { validateFile, toBase64, VALID_EXTENSIONS };
