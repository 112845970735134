import axios from 'axios';
import Swal from 'sweetalert2';
import API_PUBLIC_SERVICE from './http';
import persistenceService from './persistence-service';
import { useMessageContext } from '../libraries/contexts';

const { REACT_APP_API_URL } = process.env;

export const SERVER_API = REACT_APP_API_URL;

const PREFIX = 'visits';
const REMOVE_LINK_TIME = 100;
const path = PREFIX;

const headers = {
  'Content-Type': 'application/json; charset=utf-8'
};

export const publicInstance = axios.create({
  baseURL: SERVER_API,
  timeout: 300000,
  crossdomain: true,
  headers
});

publicInstance.interceptors.response.use(
  response => response,
  error => {
    try {
      const [value, setValue] = useMessageContext();
      setValue(error);
    } catch (er) {}
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.toLowerCase().includes('token')
    ) {
      persistenceService.clearPersistentDataProps();
      window.location.reload();
    }
    throw error;
  }
);

publicInstance.interceptors.request.use(
  config => {
    const access_token = persistenceService.get('token');

    if (access_token !== null) {
      config.headers.Authorization = `${access_token}`;
    }
    if (!access_token && !config.url.includes('login')) {
      window.location.reload();
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
const InsuranceService = {
  ...API_PUBLIC_SERVICE,
  path,
  async getAllVisitsPaginated({
    order = '',
    orderBy = '',
    page = 1,
    rowsPerPage = 10,
    filterData = '',
    from,
    to,
    dateFiltering = false,
    typeClosed
  } = {}) {
    const filtered = `?size=${rowsPerPage}&page=${page}&filter=${filterData}&sort=${order}${orderBy}${typeClosed &&
      `&typeClosed=${typeClosed}`}${dateFiltering && `&from=${from}`}&to=${to}`;
    const res = await publicInstance.get(`${SERVER_API}${this.path}/all/${filtered}`);
    const { data } = await res;
    return data;
  },
  async getTime() {
    const res = await publicInstance.get(`${SERVER_API}${this.path}/get-time`);
    const { data } = await res;
    return data;
  },

  async downloadCSV({ filter, from, to, typeClosed, select }) {
    try {
      const filtered = `?filter=${filter}${`&from=${from}`}&to=${to}&typeClosed=${typeClosed}&select=${select}`;
      const [date] = new Date(Date.now()).toJSON().split('T') || [''];
      const fileName = `Historial-Visitas-${date}.xlsx`;

      await publicInstance({
        url: `${SERVER_API}${this.path}/download-csv/${filtered}`,
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        const { data } = response;
        if (!data.size) {
          return null;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), REMOVE_LINK_TIME);
        return true;
      });
    } catch (err) {
      const {
        response: { data }
      } = await err;

      throw data;
    }
  },

  async cerrarVisita(activeVisit) {
    await publicInstance({
      url: `${SERVER_API}${this.path}/vclose`,
      method: 'POST',
      data: activeVisit
    })
      .then(response => {
        console.log(response);
        Swal.fire({
          icon: 'success',
          text: response.data.message || 'Visita Cerrada Exitosamente',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then(() => {
          window.location.href = '/visits/register';
        });
      })
      .catch(err => {
        console.log(err);
        Swal.fire({
          icon: 'warning',
          text: err.error || 'Error al cerrar visita',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then(() => {
          window.location.href = '/visits/register';
        });
      });
  }
};

export default InsuranceService;
