import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      lg11: 1170
    }
  },
  palette: {
    primary: {
      light: '#e8e8e8',
      main: '#00375b',
      dark: '#007aca',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000'
    },
    lighter: {
      gray: '#e8e8e826'
    }
  },
  props: {
    SideBar: {
      direction: 'ltr'
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16
    },

    input: {
      display: 'flex',
      padding: 0
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden'
    },

    singleValue: {
      fontSize: 16
    }
  },
  overrides: {
    MuiCard: {
      root: {
        overflowY: 'visible',
        overflow: 'visible'
      }
    },
    MuiCollapse: {
      entered: {
        height: 'auto',
        overflow: 'visible'
      }
    },
    MuiListItemIcon: {
      root: {
        marginRight: '0px'
      }
    },
    MuiTablePagination: {
      toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: '16px',
        paddingBottom: '5px',
        flexWrap: 'wrap'
      }
    },
    MuiSnackbar: {
      anchorOriginBottomLeft: {
        [defaultTheme.breakpoints.up('xs')]: {
          left: '80px'
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#00375b',
        minWidth: '400px'
      }
    },
    MuiCardHeader: {
      root: {
        backgroundColor: '#00375b'
      },
      title: {
        color: 'white'
      }
    },
    MuiTooltip: {
      tooltipPlacementTop: {
        margin: '7px 0px'
      }
    },
    MuiDivider: {
      root: {
        height: '2px'
      }
    }
  }
});

export default theme;
