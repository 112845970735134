import React from 'react';
import red from '@material-ui/core/colors/red';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { ValidatorComponent } from 'react-material-ui-form-validator';

const red300 = red['500'];

const style = {
  fontSize: '12px',
  color: red300
};

class RadioValidatorElement extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      data,
      disabled,
      legend,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        <RadioGroup
          value={value}
          {...rest}
          ref={r => {
            this.inputR = r;
          }}
        >
          {legend ? <FormLabel component="legend">{legend}</FormLabel> : ''}

          {data.map(item => (
            <FormControlLabel
              disabled={disabled}
              value={item.value}
              control={<Radio />}
              label={item.label}
            />
          ))}
          {this.errorText()}
        </RadioGroup>
      </React.Fragment>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <div style={style}>{this.getErrorMessage()}</div>;
  }
}

export default RadioValidatorElement;
