import { checkAuthorizationView } from '../services/authorization-service';

import CompaniesList from '../pages/companies';
import CompaniesCreate from '../pages/companies/create';
import CompaniesEdit from '../pages/companies/edit';
import VisitedsList from '../pages/visiteds';
import VisitedsCreate from '../pages/visiteds/create';
import VisitedsEdit from '../pages/visiteds/edit';
import BranchOfficesList from '../pages/branch-offices';
import BranchOfficesCreate from '../pages/branch-offices/create';
import BranchOfficesEdit from '../pages/branch-offices/edit';
import AccessList from '../pages/accesses';
import AccesesCreate from '../pages/accesses/create';
import AccesesEdit from '../pages/accesses/edit';
import CredentialList from '../pages/credentials';
import CredentialsCreate from '../pages/credentials/create';
import CredentialsEdit from '../pages/credentials/edit';
import BlackList from '../pages/black-list';
import BlackListDetail from '../pages/black-list/detail';
import UserList from '../pages/users';
import UsersCreate from '../pages/users/create';
import UsersEdit from '../pages/users/edit';

// export const REPORT_LIST_PATH = '/visitReports/list/';
export const LOG_LIST_PATH = '/fake2/list/';
export const CONFIG_LIST_PATH = '/fake3/list/';
export const MAINTAINER_LIST_PATH = '/fake4/list/';
export const CLOSE_VISITOR_PATH = '/visits/close';
export const INSERT_VISITOR_PATH = '/visits/register';
export const VISIT_REPORT_PATH = '/visits/report';
export const COMPANY_LIST_PATH = '/companies/list';
export const COMPANY_CREATE_PATH = '/companies/create';
export const COMPANY_EDIT_PATH = '/companies/edit';
export const VISITEDS_LIST_PATH = '/visiteds/list';
export const VISITEDS_CREATE_PATH = '/visiteds/create';
export const VISITEDS_EDIT_PATH = '/visiteds/edit';
export const BRANCH_OFFICES_LIST_PATH = '/branch-offices/list';
export const BRANCH_OFFICES_CREATE_PATH = '/branch-offices/create';
export const BRANCH_OFFICES_EDIT_PATH = '/branch-offices/edit';
export const CREDENTIALS_LIST_PATH = '/credentials/list';
export const BLACK_LIST_PATH = '/blackList/list';
export const BLACK_LIST_DETAIL_PATH = '/blackList/detail/:numberId/:typeId';
export const ACCESS_LIST_PATH = '/accesses/list';
export const ACCESSES_EDIT_PATH = '/accesses/edit';
export const ACCESSES_CREATE_PATH = '/accesses/create';
export const CREDENTIALS_EDIT_PATH = '/credentials/edit';
export const CREDENTIALS_CREATE_PATH = '/credentials/create';
export const USER_LIST_PATH = '/users/list';
export const USER_CREATE_PATH = '/users/create';
export const USER_EDIT_PATH = '/users/edit';

export const COMPANY_PATHS = [
  {
    path: COMPANY_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(CompaniesList, ['COMPANY_LIST'])
  },
  {
    path: '/companies',
    exact: true,
    component: checkAuthorizationView(CompaniesList, ['COMPANY_LIST'])
  },
  {
    path: COMPANY_CREATE_PATH,
    exact: true,
    component: checkAuthorizationView(CompaniesCreate, ['ADD_COMPANY'])
  },
  {
    path: COMPANY_EDIT_PATH,
    exact: true,
    component: checkAuthorizationView(CompaniesEdit, ['EDIT_COMPANY'])
  }
];

export const VISITEDS_PATHS = [
  {
    path: VISITEDS_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(VisitedsList, ['VISITEDS_LIST'])
  },
  {
    path: VISITEDS_CREATE_PATH,
    exact: true,
    component: checkAuthorizationView(VisitedsCreate, ['CREATE_VISITEDS'])
  },
  {
    path: VISITEDS_EDIT_PATH,
    exact: true,
    component: checkAuthorizationView(VisitedsEdit, ['EDIT_VISITEDS'])
  }
];

export const BRANCH_OFFICES_PATH = [
  {
    path: BRANCH_OFFICES_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(BranchOfficesList, ['BRANCH_OFFICE_LIST'])
  },
  {
    path: '/branch-offices',
    exact: true,
    component: checkAuthorizationView(BranchOfficesList, ['BRANCH_OFFICE_LIST'])
  },
  {
    path: BRANCH_OFFICES_CREATE_PATH,
    exact: true,
    component: checkAuthorizationView(BranchOfficesCreate, ['ADD_BRANCH_OFFICE'])
  },
  {
    path: BRANCH_OFFICES_EDIT_PATH,
    exact: true,
    component: checkAuthorizationView(BranchOfficesEdit, ['EDIT_BRANCH_OFFICE'])
  }
];

export const CREDENTIALS_PATHS = [
  {
    path: CREDENTIALS_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(CredentialList, ['CREDENTIAL_LIST'])
  },
  {
    path: '/credentials',
    exact: true,
    component: checkAuthorizationView(CredentialList, ['CREDENTIAL_LIST'])
  },
  {
    path: CREDENTIALS_CREATE_PATH,
    exact: true,
    component: checkAuthorizationView(CredentialsCreate, ['ADD_CREDENTIAL'])
  },
  {
    path: CREDENTIALS_EDIT_PATH,
    exact: true,
    component: checkAuthorizationView(CredentialsEdit, ['EDIT_CREDENTIAL'])
  }
];

export const ACCESS_PATHS = [
  {
    path: ACCESS_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(AccessList, ['ACCESS_LIST'])
  },
  {
    path: '/acceses',
    exact: true,
    component: checkAuthorizationView(AccessList, ['ACCESS_LIST'])
  },
  {
    path: ACCESSES_CREATE_PATH,
    exact: true,
    component: checkAuthorizationView(AccesesCreate, ['ADD_ACCESS'])
  },
  {
    path: ACCESSES_EDIT_PATH,
    exact: true,
    component: checkAuthorizationView(AccesesEdit, ['EDIT_ACCESS'])
  }
];

export const USERS_PATHS = [
  {
    path: USER_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(UserList, ['USER_LIST'])
  },
  {
    path: '/users',
    exact: true,
    component: checkAuthorizationView(UserList, ['USER_LIST'])
  },
  {
    path: USER_CREATE_PATH,
    exact: true,
    component: checkAuthorizationView(UsersCreate, ['ADD_USER'])
  },
  {
    path: USER_EDIT_PATH,
    exact: true,
    component: checkAuthorizationView(UsersEdit, ['EDIT_USER'])
  }
];

export const BLACK_LIST_PATHS = [
  {
    path: BLACK_LIST_PATH,
    exact: true,
    component: checkAuthorizationView(BlackList, ['BLACK_LIST'])
  },
  {
    path: BLACK_LIST_DETAIL_PATH,
    exact: true,
    component: checkAuthorizationView(BlackListDetail, ['BLACK_LIST'])
  }
];

export const maintainerFormRouters = [
  ...COMPANY_PATHS,
  ...ACCESS_PATHS,
  ...BLACK_LIST_PATHS,
  ...CREDENTIALS_PATHS,
  ...BRANCH_OFFICES_PATH,
  ...VISITEDS_PATHS,
  ...USERS_PATHS
];
