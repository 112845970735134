/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import useOnlineStatus from '@rehooks/online-status';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import moment from 'moment';
import { Button, Card, CardContent, CardHeader, InputLabel, withStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import classNames from 'classnames';
import { useToggleNavBar } from '../../libraries/contexts';

import BlackListService from '../../services/blackList';
import { generalClasses as styles } from './styles';
import CheckboxValidatorElement from '../../components/fields/checkBox';
import { getFormatRut, validateOnlyBeforeWhiteSpaces } from '../../libraries/utils';
import { requiredValue } from '../../libraries/validators';

import {
  BLACK_LIST,
  START_DATE,
  FORMAT_DATE,
  FORMAT_HOUR,
  VISITOR,
  BACK,
  REQUIRED_FIELD,
  MAX_STRING_LENGTH
} from '../../libraries/texts';

const getDateFormat = date =>
  ` ${moment(date).format(FORMAT_DATE)} ${moment(date).format(FORMAT_HOUR)}`;
const getVisitorData = data => {
  const { names, surname, numberId, origin } = data;
  const indentificationNumber = data.typeId === 'RUT' ? getFormatRut(numberId) : numberId;
  return `${names} ${surname} ${indentificationNumber} ${origin}`;
};

const canSend = (...props) => props.find(v => !!v === false) === undefined;

const getUserData = ({ user }) => (user ? `${user.name} ${user.surname}` : '');

const DetailBlackList = props => {
  const { classes, match, history } = props;
  const { params } = match;
  const onlineStatus = useOnlineStatus();
  const [, changeToggleNavBar] = useToggleNavBar();

  const [data, setData] = useState('');
  const [checkboxBlacklist, setCheckboxBlacklist] = useState(false);
  const [reason, setReason] = useState(null);

  async function removeFromBlacklist() {
    try {
      if (canSend(checkboxBlacklist, reason)) {
        await BlackListService.deleteBlacklistRecord(data._id, reason);

        changeToggleNavBar({
          message: { message: 'El visitante ha salido de Lista Negra', open: true }
        });
        history.push('/blackList/list');
      }
    } catch (error) {
      changeToggleNavBar({
        message: { message: 'Error al eliminar el usuario de la lista negra', open: true }
      });
    }
    return false;
  }

  const handleCheckbox = e => {
    setCheckboxBlacklist(e.target.checked);
    !e.target.checked && setReason(null);
  };

  useEffect(() => {
    async function fetch() {
      const result = await BlackListService.getByDocId(params.numberId, params.typeId);
      if (!result) {
        history.push('/blackList/list');
      }
      setData(result);
      ValidatorForm.addValidationRule('requiredValue', requiredValue);
    }

    fetch();
  }, [history, params.numberId, params.typeId]);

  return (
    <Card className={classes.card}>
      <CardHeader title={BLACK_LIST} />
      <CardContent className={classes.cardContent}>
        <Card className={classes.formCard}>
          <CardContent className={classes.cardContent}>
            <div className={classes.backForm}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => history.push('/blackList/list')}
                className={classes.buttonSearch}
                disabled={!onlineStatus}
              >
                <ArrowBack className={classes.rightIcon} />
                {BACK}
              </Button>
            </div>
            <div>
              <div className={classes.labelTitle}>
                <InputLabel>{VISITOR}</InputLabel>
              </div>
              <InputLabel className={classes.line}>{getVisitorData(data)}</InputLabel>
              <InputLabel>
                {START_DATE}
                {getDateFormat(data.createdAt)}
              </InputLabel>
            </div>
            <div>
              <div className={classes.labelTitle}>
                <InputLabel>Reportado por:</InputLabel>
              </div>
              <InputLabel className={classes.line}>{getUserData(data)}</InputLabel>
            </div>
            <div>
              <div className={classes.labelTitle}>
                <InputLabel>E-mail de notificación:</InputLabel>
              </div>
              <InputLabel className={classes.line}>{data.emailAdmin}</InputLabel>
            </div>
            <div>
              <div className={classes.labelTitle}>
                <InputLabel>Motivo de restricción:</InputLabel>
              </div>
              <InputLabel className={classes.line}>{data.reason}</InputLabel>
            </div>
            <ValidatorForm
              autoComplete="off"
              className={(classes.formStyle, classes.formCloseVisit)}
              onSubmit={removeFromBlacklist}
            >
              <CheckboxValidatorElement
                label="Sacar de Lista Negra"
                name="deleteFromBlackList"
                disabled={!onlineStatus}
                onChange={e => handleCheckbox(e)}
              />
              {checkboxBlacklist ? (
                <TextValidator
                  required
                  disabled={!checkboxBlacklist}
                  id="reason"
                  name="reason"
                  label="Motivo"
                  className={classNames(classes.textField, classes.reason)}
                  margin="normal"
                  validators={['requiredValue', 'maxStringLength:500']}
                  errorMessages={[REQUIRED_FIELD, MAX_STRING_LENGTH]}
                  inputProps={{ maxLength: 500 }}
                  multiline
                  autoComplete="off"
                  value={reason}
                  onChange={e => {
                    setReason(validateOnlyBeforeWhiteSpaces(e.target.value));
                  }}
                />
              ) : null}

              <div className={classes.footerButton}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!canSend(checkboxBlacklist, reason) || !onlineStatus}
                  type="submit"
                  className={classes.buttonSearch}
                >
                  Aceptar
                </Button>
              </div>
            </ValidatorForm>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(DetailBlackList);
